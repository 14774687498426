import React, { useState, useContext, useMemo, useEffect } from "react";
import {
  faCaretRight,
  faCaretDown,
  faPlus,
  faBatteryEmpty,
  faBatteryQuarter,
  faBatteryHalf,
  faBatteryThreeQuarters,
  faBatteryFull,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Spinner } from "reactstrap";
import SiteStatusCircle from "./SiteStatusCircle.js";
import { SiteContext } from "../Contexts/SiteContext";
import { SiteNavigationContext } from "../Contexts/SiteNavigationContext";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";

const Sites = (props) => {
  const {
    siteList,
    refreshList,
    selectSite,
    siteSelectHandler,
    siteGroupList,
    user,
    siteListLoading,
  } = props;

  const { siteMaintenanceAccessibility, handleSiteSubmit } =
    useContext(SiteContext);
  const {
    highlightedSite,
    setHighlightedSite,
    expandedGroups,
    setExpandedGroups,
  } = useContext(SiteNavigationContext);

  const [selectedSite, setSelectedSite] = useState(null);
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (params?.id) {
      setHighlightedSite(+params.id);
    }
  }, [params]);

  const openSiteInfo = (siteId) => {
    setHighlightedSite(siteId);
    siteSelectHandler(siteId);
  };

  const toggleGroup = (group) => {
    if (expandedGroups.includes(group)) {
      const selectedSiteInGroup = siteList.find(
        (site) =>
          site.group_name === group &&
          selectedSite &&
          selectedSite.id === site.id
      );

      if (selectedSiteInGroup) {
        selectSite(null);
        setSelectedSite(null);
        setExpandedGroups(expandedGroups.filter((g) => g !== group));
      } else {
        setExpandedGroups(expandedGroups.filter((g) => g !== group));
      }
    } else {
      setExpandedGroups([...expandedGroups, group]);
    }
  };

  const handleAddSiteClick = (group) => {
    const newSiteName = prompt("Enter the name for the new site:");
    if (newSiteName === null) {
      return;
    }

    if (newSiteName.trim() === "") {
      alert("Site name cannot be empty.");
      return;
    }

    const newSite = {
      name: newSiteName,
      group: groupNameToGroupMap.get(group),
    };
    handleSiteSubmit(newSite);
    refreshList();
  };

  const groupNameToGroupMap = useMemo(() => {
    const map = new Map();

    siteGroupList.forEach((siteGroup) => {
      map.set(siteGroup.name, siteGroup.id);
    });

    siteList.forEach((site) => {
      if (site.group_name && site.group) {
        map.set(site.group_name, site.group);
      }
    });

    return map;
  }, [siteList, siteGroupList]);

  let groups = [];

  const siteGroupsFromList = siteList.map((site) => site.group_name);
  const siteGroupsFromGroupList = siteGroupList.map(
    (siteGroup) => siteGroup.name
  );

  groups = [...new Set([...siteGroupsFromList, ...siteGroupsFromGroupList])];

  const formatBatteryPercentage = (site) => {
    if (
      site?.endpoint_status?.latest_endpoint_diagnostics?.battery_percentage ===
      undefined
    ) {
      return "NA";
    }
    const batteryPercentage = Math.floor(
      parseFloat(
        site.endpoint_status.latest_endpoint_diagnostics.battery_percentage
      )
    );

    let batteryColorToUse =
      batteryPercentage > 20 ? "text-green-500" : "text-red-500";

    let batteryIconToUse = faBatteryEmpty;
    if (batteryPercentage > 75) {
      batteryIconToUse = faBatteryFull;
    } else if (batteryPercentage > 50) {
      batteryIconToUse = faBatteryThreeQuarters;
    } else if (batteryPercentage > 25) {
      batteryIconToUse = faBatteryHalf;
    } else if (batteryPercentage > 10) {
      batteryIconToUse = faBatteryQuarter;
    }

    return (
      <span className={`flex items-center ${batteryColorToUse} font-bold`}>
        <FontAwesomeIcon
          icon={batteryIconToUse}
          className="w-3 h-3 rotate-270"
        />
        {batteryPercentage}%
      </span>
    );
  };

  return (
    <div>
      <table className="table">
        <thead className="sticky top-0 z-10 bg-blue-300">
          <tr>
            <th>Name</th>
            <th>Battery</th>
            <th>Photos</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {siteListLoading ? (
            <tr>
              <td colSpan="4">
                <div className="flex items-center justify-center mt-2">
                  <Spinner color="primary" />
                  &nbsp;Loading site groups
                </div>
              </td>
            </tr>
          ) : groups.length > 0 ? (
            groups.map((group, index) => {
              const groupSites = siteList?.filter(
                (site) => site.group_name === group
              );
              let isAdminUser = siteGroupList?.some(
                (siteGroup) => siteGroup.name === group
              );
              if (user?.is_superuser) {
                isAdminUser = true;
              }
              const isExpanded = expandedGroups.includes(group);
              const indicatorIcon = isExpanded ? faCaretDown : faCaretRight;

              return (
                <React.Fragment key={group + index}>
                  <tr
                    className="cursor-pointer w-full bg-zinc-200 p-2"
                    onClick={() => toggleGroup(group)}
                  >
                    <th className="relative w-full" colSpan="100%">
                      <FontAwesomeIcon icon={indicatorIcon} />{" "}
                      {group || "No group"}
                      {isAdminUser && (
                        <span
                          className="cursor-pointer text-gray-500 text-s absolute right-4 rounded-md hover:text-black px-3"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleAddSiteClick(group);
                          }}
                        >
                          <FontAwesomeIcon icon={faPlus} />
                          &nbsp; New Site
                        </span>
                      )}
                    </th>
                  </tr>
                  {!isExpanded &&
                    groupSites.map((site, i) => (
                      <React.Fragment key={site.id + "" + i}>
                        {!site.archived && (
                          <tr
                            className={`cursor-pointer ${
                              highlightedSite === site.id &&
                              "bg-gray-500 text-white"
                            }`}
                            onClick={() => {
                              openSiteInfo(site.id);
                            }}
                          >
                            <td>
                              <span className="pl-4 font-bold">
                                {site.name}
                              </span>
                            </td>
                            <td>{formatBatteryPercentage(site)}</td>
                            <td>{site.total_images || "0"}</td>
                            <td>
                              <SiteStatusCircle
                                site={site}
                                siteMaintenanceAccessibility={
                                  siteMaintenanceAccessibility
                                }
                              />
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    ))}
                  {groupSites.length === 0 && isExpanded && (
                    <tr>
                      <td colSpan="4" className="text-center">
                        No sites in this group
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              );
            })
          ) : null}
        </tbody>
      </table>
    </div>
  );
};

export default Sites;
