import React, { useEffect, useState, Fragment } from "react";
import { Modal, ModalHeader, ModalBody, Spinner } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faExpand,
} from "@fortawesome/free-solid-svg-icons";

const ImageViewer = ({ siteInfo, toggle, imageRotation, refreshSiteInfo }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [expandedDates, setExpandedDates] = useState({});
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [expandedImage, setExpandedImage] = useState(null);
  const [loading, setLoading] = useState(true);

  const imageRotationStyles = {
    transform: `rotate(${imageRotation}deg)`,
  };

  const refreshSiteCallback = (data) => {
    setLoading(false);
  };

  useEffect(() => {
    refreshSiteInfo(siteInfo.id, refreshSiteCallback, false, false);
  }, []);

  useEffect(() => {
    if (siteInfo?.image_set?.length > 0) {
      setActiveIndex(siteInfo.image_set.length - 1);
    }
  }, [siteInfo]);

  const preview_images = siteInfo?.image_set ? [...siteInfo.image_set] : [];

  // Function to format date as YYYY/MM/DD
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}/${month}/${day}`;
  };

  const formatTimestampHM = (timestamp) => {
    const date = new Date(timestamp);
    return `${date.getHours().toString().padStart(2, "0")}:${date
      .getMinutes()
      .toString()
      .padStart(2, "0")}`;
  };

  // Group images by creation date
  const groupedImages = preview_images.reduce((groups, image) => {
    const date = formatDate(image.created_on);
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(image);
    return groups;
  }, {});

  Object.keys(groupedImages).forEach((date) => {
    groupedImages[date].sort(
      (a, b) => new Date(b.created_on) - new Date(a.created_on)
    );
  });

  const toggleDate = (date) => {
    setExpandedDates((prev) => ({
      ...prev,
      [date]: !prev[date],
    }));
  };

// Function to filter groupedImages based on the selected date range
  const filteredGroupedImages = Object.keys(groupedImages).reduce(
    (filteredGroups, date) => {
      const dateObj = new Date(date);
      const startDateObj = startDate ? new Date(startDate) : null;
      const endDateObj = endDate ? new Date(endDate) : null;

      if (
        (!startDateObj || dateObj >= startDateObj) &&
        (!endDateObj || dateObj <= endDateObj)
      ) {
        filteredGroups[date] = groupedImages[date];
      }

      return filteredGroups;
    },
    {}
  );

  const totalImages = Object.values(filteredGroupedImages).reduce(
    (sum, images) => sum + images.length,
    0
  );

  useEffect(() => {
    // Expand first date section on first render
    const first_date = Object.keys(filteredGroupedImages)[0];
    if (first_date) {
      let date_section = {};
      date_section[first_date] = true;
      setExpandedDates(date_section);
    }
  }, []);

  return (
    <Modal
      isOpen={true}
      toggle={toggle}
      style={{ maxWidth: "90%", height: "90%" }}
    >
      <ModalHeader toggle={toggle} style={{ backgroundColor: "#d3d3d3" }}>
        ImageViewer - Images Count: {totalImages}
      </ModalHeader>
      <div className="modal-dialog-scrollable" style={{ height: "90vh" }}>
        <ModalBody style={{ maxHeight: "80vh", overflowY: "auto" }}>
          {loading ? (
            <div className="flex items-center justify-center h-full w-full">
              <Spinner color="primary"/>
            </div>
          ) : (
            <>
              <div className="mb-3">
                <label htmlFor="startDate" className="mr-2">
                  Start Date:
                </label>
                <input
                  type="date"
                  id="startDate"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
                <label htmlFor="endDate" className="ml-4 mr-2">
                  End Date:
                </label>
                <input
                  type="date"
                  id="endDate"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>
              {Object.keys(filteredGroupedImages).length > 0 && (
                <div className="py-1">
                  {Object.keys(filteredGroupedImages).map((date) => (
                    <Fragment key={date}>
                      <div style={{ marginBottom: "1rem" }}>
                        <h4
                          onClick={() => toggleDate(date)}
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {date}
                          <FontAwesomeIcon
                            icon={
                              expandedDates[date] ? faChevronUp : faChevronDown
                            }
                            className="ml-2"
                          />
                        </h4>
                        {expandedDates[date] && (
                          <div
                            className={
                              "flex flex-row items-start flex-wrap pr-[15px] " +
                              (filteredGroupedImages[date].length > 4
                                ? "justify-content-start"
                                : "justify-start")
                            }
                          >
                            {filteredGroupedImages[date].map((item, index) => (
                              <Fragment key={index}>
                                <div className="relative w-60 mb-[1rem] cursor-pointer overflow-hidden">
                                  <img
                                    key={item.id}
                                    src={item.thumbnail_url}
                                    alt={item.image_name}
                                    onClick={() => setExpandedImage(item)}
                                    className={"h-auto w-full inline-block"}
                                    style={{
                                      ...imageRotationStyles,
                                    }}
                                  />
                                  <div className="absolute top-0 left-0 right-0 w-full bg-black flex items-center justify-center px-1 py-0">
                                    <span className="text-white text-sm text-center">
                                      {formatTimestampHM(item.created_on)}
                                    </span>
                                  </div>
                                </div>
                                {index <
                                  filteredGroupedImages[date].length - 1 && (
                                  <FontAwesomeIcon
                                    className="h-auto w-[15px] px-3 opacity-65"
                                    icon={faChevronRight}
                                  />
                                )}
                              </Fragment>
                            ))}
                          </div>
                        )}
                      </div>
                    </Fragment>
                  ))}
                </div>
              )}
            </>
          )}
        </ModalBody>
      </div>
      {expandedImage && (
        <Modal
          isOpen={true}
          toggle={() => setExpandedImage(null)}
          style={{ maxWidth: "90%", height: "90vh" }}
        >
          <ModalHeader toggle={() => setExpandedImage(null)}>
            {expandedImage.image_name}
          </ModalHeader>
          <ModalBody
            style={{
              height: "90vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={expandedImage.image_url}
              alt={expandedImage.image_name}
              className="w-full h-auto max-w-[800px]"
              style={{
                ...imageRotationStyles,
              }}
            />
          </ModalBody>
        </Modal>
      )}
    </Modal>
  );
};

export default ImageViewer;
