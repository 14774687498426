import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

// import reportWebVitals from './reportWebVitals';
import { ToastContextProvider } from "./components/Contexts/ToastContext";
import $ from 'jquery';
import { getCookie } from "./components/Utilities/CookieUtils";

// Get the CSRF token
const csrftoken = getCookie('redeyeCSRFcookie');

// setting up jQuery to include the CSRF token in AJAX requests
$.ajaxSetup({
    beforeSend: function(xhr, settings) {
      xhr.setRequestHeader("X-CSRFToken", csrftoken);
    }
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  
    <ToastContextProvider>
      <App />
    </ToastContextProvider>
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
