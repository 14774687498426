import React from "react";

const ListXButton = (props) => {
  const { onClick } = props;

  if (onClick) {
    return (
      <button
        style={{
          backgroundColor: "#3f51b5",
          color: "#ffffff",
          borderRadius: "5px",
          padding: "5px 10px",
          textTransform: "uppercase",
          letterSpacing: "2px",
          fontWeight: "bold",
          border: "none",
          boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.1)",
          transition: "all 0.3s ease-in-out",
          fontSize: "12px",
        }}
        onMouseEnter={(e) => {
          e.currentTarget.style.backgroundColor = "#303f9f";
          e.currentTarget.style.cursor = "pointer";
          e.currentTarget.style.transform = "translateY(-2px)";
          e.currentTarget.style.boxShadow = "0px 8px 15px rgba(0, 0, 0, 0.1)";
        }}
        onMouseLeave={(e) => {
          e.currentTarget.style.backgroundColor = "#3f51b5";
          e.currentTarget.style.cursor = "default";
          e.currentTarget.style.transform = "translateY(0)";
          e.currentTarget.style.boxShadow = "0px 5px 10px rgba(0, 0, 0, 0.1)";
        }}
        onClick={onClick}
      >
        X
      </button>
    );
  }
  return null;
};

export default ListXButton;
