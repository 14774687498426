import React, { useState, createContext, useCallback, useEffect, useContext } from "react";
import $ from "jquery";

import { getCookie } from "../Utilities/CookieUtils.js";
import { ManagementContext } from "./ManagementContext.js";
import { CompanyContext } from "./CompanyContext.js";

export const UserListContext = createContext();

export const UserListProvider = ({ children }) => {
  const [userList, setUserList] = useState(null);
  const [csrfToken] = useState(getCookie("redeyeCSRFcookie"));

  const [currentUserCompany, setCurrentUserCompany] = useState(null);
  const { user } = useContext(ManagementContext);
  const { userCompany: userCompanyData } = useContext(CompanyContext);

  useEffect(() => {
    if (user && !user.is_superuser && userCompanyData) {
      const userCompany = userCompanyData.find(
        (item) => item.user === user.id && item.is_companyadmin === true
      );
      setCurrentUserCompany(userCompany?.company);
    }
  }, []);

  // Simplify refreshUserList without unnecessary dependencies
  const refreshUserList = useCallback(() => {
    $.get("/redeye/api/user/").done(function (users) {
      setUserList(users);
    });
  }, []); // No dependencies needed here

  const handleUpdateUserGroupMembership = (user_id, group_id, in_group) => {
    $.ajax({
      type: "PUT",
      url: `/redeye/api/update_user_group_membership/${user_id}/`,
      data: {
        user_group: group_id,
        in_group: in_group,
      },
      headers: { "X-CSRFToken": csrfToken },
      xhrFields: { withCredentials: true },
      dataType: "json",
      success: function () {
        refreshUserList(); // Refresh user list on success
      },
      error: function (xhr, status, error) {
        // Handle error response here
      },
    });
  };

  return (
    <UserListContext.Provider
      value={{
        userList,
        setUserList,
        refreshUserList,
        handleUpdateUserGroupMembership,
      }}
    >
      {children}
    </UserListContext.Provider>
  );
};
