import React, { useState, useContext } from "react";
import { faRotate, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import $ from "jquery";

import { getCookie } from "../Utilities/CookieUtils";
import { SiteContext } from "../Contexts/SiteContext";

const RotateImage = ({ siteInfo }) => {
  const { latest_endpoint } = siteInfo;
  const [csrfToken] = useState(getCookie("redeyeCSRFcookie"));
  const { setImageRotation } = useContext(SiteContext);
  const [loading, setLoading] = useState(false);

  const orderedEndpoints = [...siteInfo.endpoint_set].sort(
    (a, b) => new Date(b.created_on) - new Date(a.created_on)
  );

  const rotateImageHandler = async () => {
    setLoading(true);
    $.ajax({
      type: "POST",
      url: `/redeye/api/rotate_images/${latest_endpoint.id}`,
      headers: { "X-CSRFToken": csrfToken },
      contentType: "application/json",
      success: function (response) {
        setLoading(false);
        setImageRotation(response.rotation);
      },
      error: function () {
        setLoading(false);
      },
    });
  };

  return (
    <>
      {orderedEndpoints && orderedEndpoints.length > 0 ? (
        <>
          <button
            onClick={rotateImageHandler}
            type="button"
            className="btn btn-primary"
            disabled={loading}
          >
            {loading ? (
              <>
                <FontAwesomeIcon icon={faSpinner} />
                &nbsp; Rotating images
              </>
            ) : (
              <>
                <FontAwesomeIcon icon={faRotate} />
                &nbsp; Rotate Images 90&deg;
              </>
            )}
          </button>
        </>
      ) : null}
    </>
  );
};

export default RotateImage;
