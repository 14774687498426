import React, { useState, createContext, useCallback, useEffect, useContext } from "react";
import $ from "jquery";

import { ManagementContext } from "./ManagementContext";
import { CompanyContext } from "./CompanyContext";

export const SiteGroupContext = createContext();

export const SiteGroupProvider = (props) => {
  const [siteGroupList, setSiteGroupList] = useState([]);
  const [currentUserCompany, setCurrentUserCompany] = useState(null);

  const { user: currentUser } = useContext(ManagementContext);
  const { userCompany } = useContext(CompanyContext);

  useEffect(() => {
    if (currentUser && !currentUser.is_superuser && userCompany) {
      const userCompanyWhereAdmin = userCompany.find(
        (item) => item.user === currentUser.id && item.is_companyadmin === true
      );
      setCurrentUserCompany(userCompanyWhereAdmin?.company);
    }
  }, []);

  const refreshGroupList = useCallback(() => {
    return new Promise((resolve, reject) => {
      if (!currentUser) {
        resolve();
        return;
      }
  
      $.get("/redeye/api/site_group/", {
        xhrFields: { withCredentials: true },
      }).done((siteGroupData) => {
        if (currentUser.is_superuser) {
          setSiteGroupList(siteGroupData);
          resolve();
        } else {
          // Fetch UserCompany data
          $.get("/redeye/api/user_company/", {
            xhrFields: { withCredentials: true },
            user: currentUser.id,
          }).done((userCompanyData) => {
            const userCompanies = userCompanyData
              .filter((uc) => uc.user === currentUser.id && uc.is_companyadmin)
              .map((usg) => usg.company);
              
              // Fetch UserSiteGroup data
            $.get("/redeye/api/user_site_group/", {
              xhrFields: { withCredentials: true },
              user: currentUser.id,
            }).done((userSiteGroupData) => {
              const userSiteGroups = userSiteGroupData
                .filter(
                  (uc) => uc.user === currentUser.id && uc.admin_user === true
                )
                .map((usg) => usg.sitegroup);
  
              const filteredGroups = siteGroupData.filter(
                (group) =>
                  userCompanies.includes(group.company) ||
                  userSiteGroups.includes(group.id)
              );
  
              setSiteGroupList(filteredGroups);
              resolve();
            })
            .fail(() => reject());
          })
          .fail(() => reject());
        }
      })
      .fail(() => reject());
    });
  }, [currentUser, currentUserCompany]);

  return (
    <SiteGroupContext.Provider
      value={{ siteGroupList, setSiteGroupList, refreshGroupList }}
    >
      {props.children}
    </SiteGroupContext.Provider>
  );
};
