import React from "react";
import { useState, useContext } from "react";
import { Form, FormGroup, Input, Label } from "reactstrap";
import UserDetails from "./UserDetails.js";
import { Mobile, Default } from "../../MediaQueries.js";
import { ModalContext } from "../Contexts/ModalContext";
import { PreUserContext } from "../Contexts/PreUserContext.js";

import StyledTableHeader from "../Utilities/StyledTableHeader.js";
import ScrollbarStyles from "../Utilities/ScrollbarStyles.js";

const Users = (props) => {
  const { userList } = props;
  const [selectedSearchField, setSelectedSearchField] = useState("User_Name");

  const {
    selectedUserID,
    setSelectedUserID,
    sort,
    setSort,
    filterValue,
    setFilterValue,
  } = useContext(PreUserContext);

  const getSelectedUser = () => {
    return userList.find((user) => {
      return user.id === selectedUserID;
    });
  };

  const { toggleModal } = useContext(ModalContext);

  let userListStyle = {
    maxHeight: "65vh",
    overflow: "auto",
    display: "inline-block",
  };

  const userInfo = getSelectedUser();

  const sortData = userList.sort((a, b) => {
    let valueA, valueB;

    if (sort.property === "first_name") {
      valueA =
        a.first_name && a.last_name
          ? `${a.first_name} ${a.last_name}`
          : a.username;
      valueB =
        b.first_name && b.last_name
          ? `${b.first_name} ${b.last_name}`
          : b.username;
    } else {
      valueA = a[sort.property];
      valueB = b[sort.property];
    }

    if (sort.direction === "asc") {
      return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
    }
    return valueA > valueB ? -1 : valueA < valueB ? 1 : 0;
  });

  const filteredUsers = sortData.filter((item) => {
    const firstName = item.first_name ? item.first_name.toLowerCase() : "";
    const lastName = item.last_name ? item.last_name.toLowerCase() : "";
    const username = item.username.toLowerCase();
    const filterValueLower = filterValue.toLowerCase();

    if (selectedSearchField === "User_Name") {
      return (
        username.includes(filterValueLower) ||
        (firstName + " " + lastName).includes(filterValueLower)
      );
    }
    return false; // No filtering for user groups
  });

  let rightHandStyle = {
    backgroundColor: "rgb(242, 242, 242)",
    borderLeft: "2px solid #000",
    marginTop: "0.3rem",
    height: "calc(100vh - 200px)",
    left: "0.5%",
    overflow: "auto",
  };

  const searchFields = [{ label: "User Name", value: "User_Name" }];

  if (userList) {
    return (
      <>
        <ScrollbarStyles />
        <div className="row">
          <div className="col-4">
            <Form
              style={{
                marginBottom: "1rem",
                width: "102%",
                marginTop: "0.3rem",
              }}
            >
              <FormGroup style={{ display: "flex", alignItems: "center" }}>
                <Label
                  for="searchTerm"
                  style={{
                    marginRight: "0.5rem",
                    fontFamily: "'Orbitron', sans-serif",
                    fontWeight: "bold",
                    fontSize: "1rem",
                    letterSpacing: "0.1rem",
                    color: "#1a3c71",
                  }}
                >
                  Search:
                </Label>
                <Input
                  type="text"
                  name="searchTerm"
                  id="searchTerm"
                  placeholder="Search..."
                  value={filterValue}
                  onChange={(e) => setFilterValue(e.target.value)}
                  style={{ backgroundColor: "#f2f2f2" }}
                />
                <Input
                  type="select"
                  name="searchField"
                  id="searchField"
                  value={selectedSearchField}
                  onChange={(e) => setSelectedSearchField(e.target.value)}
                  style={{ marginLeft: "1rem", backgroundColor: "#f2f2f2" }}
                >
                  {searchFields.map((field) => (
                    <option key={field.value} value={field.value}>
                      {field.label}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Form>
            <div style={{ display: "flex", flexDirection: "row", gap: "14%" }}>
              <div style={userListStyle}>
                <table className="table scrollable-table">
                  <StyledTableHeader
                    columns={[
                      { header: "User Name", key: "first_name", width: "100%" },
                    ]}
                    sort={sort}
                    setSort={setSort}
                  />
                  <tbody className="overflow-auto">
                    {filteredUsers.length ? (
                      <>
                        {filteredUsers.map((item) => {
                          const displayName =
                            item.first_name && item.last_name
                              ? `${item.first_name} ${item.last_name}`
                              : item.username;
                          return (
                            <tr
                              key={item.id}
                              onClick={() => setSelectedUserID(item.id)}
                              className="box"
                              style={{
                                backgroundColor:
                                  selectedUserID === item.id
                                    ? "#6c757d"
                                    : "inherit",
                                color:
                                  selectedUserID === item.id
                                    ? "white"
                                    : "inherit",
                              }}
                            >
                              <td style={{ width: "100%" }}>{displayName}</td>
                            </tr>
                          );
                        })}
                      </>
                    ) : (
                      <p>User not found!</p>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div
            className="col-8 position-relative scrollable-table"
            style={rightHandStyle}
          >
            {userInfo && (
              <div>
                <Default>
                  <div className="row">
                    <div className="col-30">
                      <UserDetails
                        userInfo={userInfo}
                        selectedUserID={selectedUserID}
                        toggle={toggleModal}
                      />
                    </div>
                  </div>
                </Default>
                <Mobile>
                  <UserDetails />
                </Mobile>
              </div>
            )}
            {!userInfo && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                {" "}
                <span>Select a user to see the details!</span>{" "}
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
};

export default Users;
