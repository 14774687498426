import React, { useContext, useEffect } from "react";
import $ from "jquery";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { CompanyContext } from "../Contexts/CompanyContext";
import { useToastContext } from "../Contexts/ToastContext.js";

const UserModal = (props) => {
  const {
    toggle,
    onCancel,
    refreshUserList,
    company: Ucompany,
    onUserAdded,
  } = props;
  const { companyList } = useContext(CompanyContext);

  const modalItem = {
    first_name: "",
    last_name: "",
    username: "",
    email: "",
    password: "",
    is_superuser: false,
    is_staff: false,
    company: Ucompany?.id || "",
    is_companyadmin: false,
  };

  const { register, control, handleSubmit, formState, setValue } = useForm({
    defaultValues: modalItem,
  });

  const { showToast } = useToastContext();

  useEffect(() => {
    if (!Ucompany && companyList.length === 1) {
      setValue("company", companyList[0].id);
    }
  }, [Ucompany, companyList, setValue]);

  const handleSave = (data) => {
    if (!data) {
      console.error("No user data available");
      return;
    }

    const companyId = Ucompany?.id || data.company;
    try {
      $.ajax({
        url: "/redeye/api/user/",
        method: "POST",
        data: JSON.stringify({ ...data, company: companyId }),
        contentType: "application/json",
        dataType: "json",
        xhrFields: { withCredentials: true },
        success: (userResponse) => {
          const userId = userResponse.id;
          const userCompanyData = {
            user: userId,
            company: companyId,
            is_companyadmin: data.is_companyadmin,
          };

          $.ajax({
            url: "/redeye/api/user_company/",
            method: "POST",
            data: JSON.stringify(userCompanyData),
            contentType: "application/json",
            dataType: "json",
            xhrFields: { withCredentials: true },
            success: () => {
              showToast(
                `Success. Created ${
                  data.is_companyadmin ? "Company Admin" : "Normal User"
                }`
              );
              toggle(); // Close the modal after successful save
              refreshUserList(); // Refresh the user list after creation
              if (Ucompany !== null) {
                onUserAdded();
              }
            },
            error: (error) => {
              console.error("Error saving user-company relationship", error);
            },
          });
        },
        error: (error) => {
          console.error("Error saving user", error);
        },
      });
    } catch (error) {
      console.error("Error handling save", error);
    }
  };

  return (
    <Modal isOpen={true} toggle={toggle}>
      <ModalHeader toggle={toggle} style={{ backgroundColor: "#d3d3d3" }}>
        {modalItem && modalItem.id ? modalItem.name : "New User"}
      </ModalHeader>
      <form onSubmit={handleSubmit((data) => handleSave(data))}>
        <input type="hidden" {...register("id")} />
        <ModalBody>
          <FormGroup>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Label for="is_companyadmin" style={{ width: "40%" }}>
                <b>CompanyAdmin:</b>
              </Label>
              <Controller
                name="is_companyadmin"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <Input
                    {...field}
                    type="checkbox"
                    style={{ width: "15%" }}
                    id="is_companyadmin"
                    checked={field.value}
                    onChange={(e) => field.onChange(e.target.checked)}
                  />
                )}
              />
            </div>
          </FormGroup>
          <FormGroup>
            <Label for="first_name">
              <b>First Name</b>
            </Label>
            <Controller
              name="first_name"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  invalid={Boolean(formState.errors.first_name)}
                  type="text"
                  placeholder="First Name"
                />
              )}
            />
            {formState.errors.first_name && <span>This field is required</span>}
          </FormGroup>

          <FormGroup>
            <Label for="last_name">
              <b>Last Name</b>
            </Label>
            <Controller
              name="last_name"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  invalid={Boolean(formState.errors.last_name)}
                  type="text"
                  placeholder="Last Name"
                />
              )}
            />
            {formState.errors.last_name && <span>This field is required</span>}
          </FormGroup>

          <FormGroup>
            <Label for="username">
              <b>Username</b>
            </Label>
            <Controller
              name="username"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  invalid={Boolean(formState.errors.username)}
                  type="text"
                  placeholder="Username"
                />
              )}
            />
            {formState.errors.username && <span>This field is required</span>}
          </FormGroup>

          <FormGroup>
            <Label for="email">
              <b>Email</b>
            </Label>
            <Controller
              name="email"
              control={control}
              rules={{
                required: "This field is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "Invalid email address",
                },
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  invalid={Boolean(formState.errors.email)}
                  type="text"
                  placeholder="Email"
                />
              )}
            />
            {formState.errors.email && (
              <span>{formState.errors.email.message}</span>
            )}
          </FormGroup>

          <FormGroup>
            <Label for="password">
              <b>Password</b>
            </Label>
            <Controller
              name="password"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  invalid={Boolean(formState.errors.password)}
                  type="password"
                  placeholder="Password"
                />
              )}
            />
            {formState.errors.password && <span>This field is required</span>}
          </FormGroup>

          {/* Conditionally render the company field */}
          {!Ucompany && (
            <FormGroup>
              <Label for="company">
                <b>Company</b>
              </Label>
              <Controller
                name="company"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    {...field}
                    invalid={Boolean(formState.errors.company)}
                    type="select"
                  >
                    <option value="">Select a company</option>
                    {companyList.map((company) => (
                      <option key={company.id} value={company.id}>
                        {company.name}
                      </option>
                    ))}
                  </Input>
                )}
              />
              {formState.errors.company && <span>This field is required</span>}
            </FormGroup>
          )}
        </ModalBody>
        <ModalFooter className="d-flex flex-row justify-content-between">
          <Button color="danger" onClick={onCancel}>
            Cancel
          </Button>
          <Button color="success" type="submit">
            Save
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default UserModal;
