import React, { useState, createContext, useCallback, useEffect, useContext, useRef } from "react";
import $ from "jquery";
import { getCookie } from "../Utilities/CookieUtils.js";
import { useToastContext } from "../Contexts/ToastContext.js";
import { useParams } from "react-router";
import { ModalContext } from "./ModalContext.js";

export const SiteContext = createContext();

export const SiteProvider = (props) => {
  const [siteLoading, setSiteLoading] = useState(false);
  const [siteList, setSiteList] = useState([]);
  const [siteInfo, setSiteInfo] = useState(null);
  const [siteMaintenanceAccessibility, setSiteMaintenanceAccessibility] = 
  useState({});

  // state for maintaimg rotation degree of the image
  // for each endpoint this varies
  const [imageRotation, setImageRotation] = useState(0);

  const [csrfToken] = useState(getCookie("redeyeCSRFcookie"));
  const { showToast } = useToastContext();

  const [isTabVisible, setIsTabVisible] = useState(true);
  
  const params = useParams();
  const { modal, modalComponent } = useContext(ModalContext);

  // refs to hold the latest values of modal and modalComponent
  const modalRef = useRef(modal);
  const modalComponentRef = useRef(modalComponent);

  // update refs whenever modal or modalComponent changes
  useEffect(() => {
    modalRef.current = modal;
    modalComponentRef.current = modalComponent;
  }, [modal, modalComponent]);

  // reload data on sites pane when user leaves page and comes back
  let focusChangeDebounce = useRef(null);
  const handleVisibilityChange = useCallback((e) => {
    clearTimeout(focusChangeDebounce.current);
    focusChangeDebounce.current = setTimeout(() => {
      setIsTabVisible(document.visibilityState === "visible");

      const currentSiteId = localStorage.getItem("selectedSiteId");
      if (currentSiteId > 0) {
        refreshSiteInfo(currentSiteId, null, true); // reload in background
      }
    }, 1000); // only reload every 1 seconds.
  }, []);

  // watch for changes on both visibity and focus
  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);
    document.addEventListener("focus", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      document.removeEventListener("focus", handleVisibilityChange);
      clearTimeout(focusChangeDebounce.current);
    };
  }, [handleVisibilityChange]);

  let siteListRequest = null;
  let requestSiteListDebounce = null;
  
  const refreshList = useCallback(
    (lazy = false) => {
      return new Promise((resolve, reject) => {
        clearTimeout(requestSiteListDebounce);
        requestSiteListDebounce = setTimeout(() => {
          // Kill site requests if already running
          siteListRequest?.abort();
          if (!lazy || !siteList || siteList.length === 0) {
            siteListRequest = $.get("/redeye/api/sites/", {
              xhrFields: { withCredentials: true },
            })
            .done((data) => {
              if (JSON.stringify(data) !== JSON.stringify(siteList)) {
                setSiteList(data);
              }
              resolve();
            })
            .fail(() => reject());
          } else {
            resolve();
          }
        }, 1500);
      });
    },
    [siteList]
  );

  let updatingMaintenanceMode = false;

  const refreshMaintenanceAccessibility = useCallback(
    (lazy = false) => {
      return new Promise((resolve, reject) => {
        //  @TODO determine if check is necessary
        resolve();
        return true; // Maintenance mode was removed, this may not be used any more.
  
        if (updatingMaintenanceMode) {
          resolve();
          return;
        }
  
        updatingMaintenanceMode = true;
        $.get("/redeye/api/sites_maintenance_mode/", {
          xhrFields: { withCredentials: true },
        }).done((data) => {
            updatingMaintenanceMode = false;
            setSiteMaintenanceAccessibility(data);
            resolve(data);
          }).fail(() => reject());
      });
    },
    [siteMaintenanceAccessibility, setSiteMaintenanceAccessibility]
  );

  let siteInfoRequest = null;
  let lastLoadedSiteId = null;
  const refreshSiteInfo = (site_id, callback = null, background = false, limit_images = true) => {
    if (!site_id) {
      return;
    }
    // dont run duplicate calls, dont cancel duplicate calls
    if (lastLoadedSiteId === site_id) {
      return;
    }

    // dont reload site if we are not actually looking at a site
    if (!window.location.href.includes(site_id)) {
      return;
    }

     // kill previous requests
    siteInfoRequest?.abort();

    // Show loading indicator
    if (!background) {
      setSiteLoading(true);
    }

    // Cache the site id in local storage on request
    localStorage.setItem("selectedSiteId", site_id);

    if (modalRef.current && modalComponentRef.current === "ImageViewer") {
      limit_images = false;
    }

    siteInfoRequest = $.get(`/redeye/api/sites/${site_id}/`, {
      xhrFields: { withCredentials: true },
      limit_images: limit_images,
    }).done(function (data) {
      lastLoadedSiteId = null; // clear out last request history for reloading of same data
      // if url has site ID, only load that site ID, this blocks async duplciate calls
      if (
        window.location.href.includes("/id") &&
        !window.location.href.includes(site_id)
      ) {
        console.log("Missing ID, doing nothing");
        console.log(window.location);
        console.log([
          window.location.href.includes("/id"),
          site_id,
          window.location.href.includes(site_id),
        ]);
        return;
      }
      data.loadedOnDate = new Date().toLocaleString();
      setSiteInfo(data);

      // set the image rotation from the latest endpoint
      setImageRotation(data?.latest_endpoint?.image_rotation_degree ?? 0);
      refreshList();
      setTimeout(() => {
        setSiteLoading(false);
      }, 5000);
      // Run callback if its a function
      typeof callback === "function" && callback(data);
    });
  };

  const handleSiteSubmit = (item, refresh = true, onSuccess) => {
    let url = "/redeye/api/sites/";
    let type = "POST";
    if (item.id) {
      url = `/redeye/api/sites/${item.id}/`;
      type = "PUT";
    }

    $.ajax({
      type: type,
      url: url,
      data: item,
      headers: { "X-CSRFToken": csrfToken },
      xhrFields: { withCredentials: true },
      dataType: "json",
      success: function (data) {
        if (refresh) {
          refreshList();
        }
        if (onSuccess) {
          onSuccess(data);
        }
      },
      error: showToast,
    });
  };

  const handleSiteDelete = (item, refresh = true, onError) => {
    console.log(item);
    let url = `/redeye/api/sites/${item.id}/`;
    let type = "DELETE";
    let data = {};

    // If the site has any endpoints or serial numbers, it can't be safely deleted
    // and should just be archived
    if (
      item?.image_set?.length > 0 ||
      item?.last_call_home ||
      item?.last_get_config
    ) {
      type = "PUT";
      data = {
        archived: true,
        name: item.name,
      };
    }

    $.ajax({
      type: type,
      url: url,
      data: data,
      headers: { "X-CSRFToken": csrfToken },
      xhrFields: { withCredentials: true },
      dataType: "json",
      success: function () {
        if (refresh) {
          refreshList();
        }
        if (item.id === siteInfo?.id) {
          setSiteInfo(null);
        }
      },
      error: function (error) {
        if (onError) {
          showToast(error);
          onError(error);
        }
      },
    });
  };

  const handleSiteEdit = (
    item,
    newName,
    refresh = true,
    onSuccess,
    onError
  ) => {
    $.ajax({
      type: "PUT",
      url: `/redeye/api/sites/${item.id}/`,
      data: { name: newName },
      headers: { "X-CSRFToken": csrfToken },
      xhrFields: { withCredentials: true },
      dataType: "json",
      success: function (data) {
        if (refresh) {
          refreshList();
        }
        if (onSuccess) {
          onSuccess(data);
        }
      },
      error: function (error) {
        if (onError) {
          showToast(error);
          onError(error);
        }
      },
    });
  };

  return (
    <SiteContext.Provider
      value={{
        siteList,
        setSiteList,
        refreshList,
        siteMaintenanceAccessibility,
        setSiteMaintenanceAccessibility,
        refreshMaintenanceAccessibility,
        siteInfo,
        setSiteInfo,
        refreshSiteInfo,
        handleSiteSubmit,
        csrfToken,
        handleSiteDelete,
        siteLoading,
        setSiteLoading,
        handleSiteEdit,
        isTabVisible,
        imageRotation,
        setImageRotation,
      }}
    >
      {props.children}
    </SiteContext.Provider>
  );
};
