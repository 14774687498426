import React, { useEffect, useContext, useState } from "react";
import { Button, Spinner, Alert } from "reactstrap";
import AddUserToCompany from "./AddUserToCompany.js";
import AddSitegroupToCompany from "./AddSitegroupToCompany.js";
import ListXButton from "../Utilities/ListXButton.js";
import { ModalContext } from "../Contexts/ModalContext";
import { UserListContext } from "../Contexts/UserListContext";
import { CompanyContext } from "../Contexts/CompanyContext";
import { SiteGroupContext } from "../Contexts/SiteGroupContext";
import UserModal from "../Users/UserModal.js";
import { getCookie } from "../Utilities/CookieUtils.js";
import $ from "jquery";

const CompanyDetails = (props) => {
  const { selectedCompany, onCompanyDeleted } = props;
  const csrfToken = getCookie("redeyeCSRFcookie");
  const { modal, toggleModal, openModal, modalComponent, handleCancel } =
    useContext(ModalContext);
  const { userList, refreshUserList } = useContext(UserListContext);
  const { companyList, refreshCompanyList } = useContext(CompanyContext);
  const { siteGroupList, refreshGroupList } = useContext(SiteGroupContext);

  const [refreshKey, setRefreshKey] = useState(0);
  const [companyUsers, setCompanyUsers] = useState([]);
  const [adminUsers, setAdminUsers] = useState([]);
  const [normalUsers, setNormalUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    document.title = "Company Details";
    const fetchData = async () => {
      try {
        await Promise.all([
          refreshUserList(true),
          refreshCompanyList(),
          refreshGroupList(),
          fetchCompanyUsers(),
        ]);
      } catch (err) {
        setError(err.message || "Failed to fetch data");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [selectedCompany]);

  const fetchCompanyUsers = () => {
    return new Promise((resolve, reject) => {
      $.ajax({
        type: "GET",
        url: `/redeye/api/user_company/`,
        headers: { "X-CSRFToken": csrfToken },
        xhrFields: { withCredentials: true },
        dataType: "json",
        success: function (data) {
          const company = companyList.find(
            (item) => item.name === selectedCompany
          );
          const filteredUsers = data.filter(
            (item) => item.company === company?.id
          );
          const adminUsers = filteredUsers.filter(
            (item) => item.is_companyadmin
          );
          const normalUsers = filteredUsers.filter(
            (item) => !item.is_companyadmin
          );

          // Map user IDs to user objects
          const userIds = filteredUsers.map((item) => item.user);
          $.ajax({
            type: "GET",
            url: `/redeye/api/user/`,
            headers: { "X-CSRFToken": csrfToken },
            xhrFields: { withCredentials: true },
            dataType: "json",
            success: function (userData) {
              const users = userData.filter((user) =>
                userIds.includes(user.id)
              );

              // Filter out superusers
              const nonSuperUsers = users.filter((user) => !user.is_superuser);

              setAdminUsers(
                adminUsers
                  .map((item) =>
                    nonSuperUsers.find((user) => user.id === item.user)
                  )
                  .filter((user) => user) // Remove undefined entries
              );
              setNormalUsers(
                normalUsers
                  .map((item) =>
                    nonSuperUsers.find((user) => user.id === item.user)
                  )
                  .filter((user) => user) // Remove undefined entries
              );
              setCompanyUsers(nonSuperUsers);
              resolve();
            },
            error: function (xhr) {
              reject(xhr.responseText);
            },
          });
        },
        error: function (xhr) {
          reject(xhr.responseText);
        },
      });
    });
  };

  const handleDeleteUser = (userId) => {
    const company = companyList.find((item) => item.name === selectedCompany);
    if (!company) return;

    // Find the UserCompany record's ID
    $.ajax({
      type: "GET",
      url: `/redeye/api/user_company/`,
      headers: { "X-CSRFToken": csrfToken },
      xhrFields: { withCredentials: true },
      dataType: "json",
      success: function (data) {
        const record = data.find(
          (item) => item.user === userId && item.company === company.id
        );
        if (record) {
          // Proceed to delete
          $.ajax({
            type: "DELETE",
            url: `/redeye/api/user_company/${record.id}/`,
            headers: { "X-CSRFToken": csrfToken },
            xhrFields: { withCredentials: true },
            success: function () {
              fetchCompanyUsers();
              refreshCompanyList();
              refreshUserList();
            },
            error: function (xhr) {
              console.error("Error:", xhr.responseText);
            },
          });
        }
      },
      error: function (xhr) {
        console.error("Error:", xhr.responseText);
      },
    });
  };

  const handleDeleteSiteGroup = (group) => {
    group.company = null;

    $.ajax({
      type: "PUT",
      url: `/redeye/api/site_group/${group.id}/`,
      data: group,
      headers: { "X-CSRFToken": csrfToken },
      xhrFields: { withCredentials: true },
      dataType: "json",
      success: function (data) {
        refreshGroupList();
        refreshCompanyList();
      },
      error: function (xhr) {
        setError("Failed to delete site group");
      },
    });
  };

  const handleDeleteCompany = () => {
    if (window.confirm("Are you sure you want to delete this company?")) {
      const company = companyList.find((item) => item.name === selectedCompany);
      if (!company) return;

      $.ajax({
        type: "DELETE",
        url: `/redeye/api/company/${company.id}/`,
        headers: { "X-CSRFToken": csrfToken },
        xhrFields: { withCredentials: true },
        success: function () {
          refreshCompanyList();
          onCompanyDeleted(); // Notify parent component to handle the deletion
        },
        error: function (xhr) {
          setError("Failed to delete company");
        },
      });
    }
  };

  const onUserAdded = () => {
    fetchCompanyUsers();
    refreshCompanyList();
    refreshUserList();
  };

  if (loading) {
    return <Spinner color="primary" />;
  }

  if (error) {
    return <Alert color="danger">{error}</Alert>;
  }

  if (!companyList || !siteGroupList) {
    return null;
  }

  const company = companyList.find((item) => item.name === selectedCompany);
  const filteredSiteGroups = siteGroupList.filter(
    (group) => group.company === company?.id
  );

  return (
    <>
      <h2
        style={{
          backgroundColor: "lightblue",
          padding: "6px",
          borderRadius: "6px",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {selectedCompany}
      </h2>
      <div>
        <h5
          style={{
            backgroundColor: "lightblue",
            padding: "4px",
            borderRadius: "4px",
            marginBottom: "10px",
          }}
        >
          {selectedCompany} Site Groups
        </h5>
        <div>
          {filteredSiteGroups.length > 0 ? (
            filteredSiteGroups.map((group) => (
              <tr key={group.id}>
                <th>{group.name}</th>
                &nbsp; &nbsp; &nbsp;
                <td>
                  <ListXButton onClick={() => handleDeleteSiteGroup(group)} />
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <th>No site groups associated</th>
            </tr>
          )}
        </div>
        <Button
          color="primary"
          onClick={() => openModal("AddSitegroupToCompany")}
        >
          Add Existing Site Group
        </Button>
      </div>
      <div>
        <h5
          style={{
            backgroundColor: "lightblue",
            padding: "4px",
            borderRadius: "4px",
            marginBottom: "10px",
          }}
        >
          {selectedCompany} Admin Users
        </h5>
        <div>
          {adminUsers.length > 0 ? (
            adminUsers.map((user) => (
              <tr key={user.id}>
                <th>{user.username}</th>
                &nbsp; &nbsp; &nbsp;
                <td>
                  <ListXButton onClick={() => handleDeleteUser(user.id)} />
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <th>No admin users associated</th>
            </tr>
          )}
        </div>
      </div>
      <div>
        <h5
          style={{
            backgroundColor: "lightblue",
            padding: "4px",
            borderRadius: "4px",
            marginBottom: "10px",
          }}
        >
          {selectedCompany} Normal Users
        </h5>
        <div>
          {normalUsers.length > 0 ? (
            normalUsers.map((user) => (
              <tr key={user.id}>
                <th>{user.username}</th>
                &nbsp; &nbsp; &nbsp;
                <td>
                  <ListXButton onClick={() => handleDeleteUser(user.id)} />
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <th>No normal users associated</th>
            </tr>
          )}
        </div>
        <Button color="primary" onClick={() => openModal("AddUserToCompany")}>
          Add Existing Company User or Company Admin User
        </Button>
        &nbsp;
        <Button
          color="primary"
          onClick={() => openModal("UserModal", { company: company })}
        >
          Create Company User
        </Button>
      </div>
      &nbsp;
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          color="danger"
          onClick={handleDeleteCompany}
          style={{ marginBottom: "10px" }}
        >
          Delete Company
        </Button>
      </div>
      {modal && modalComponent === "AddSitegroupToCompany" && (
        <AddSitegroupToCompany
          companyList={companyList}
          siteGroupList={siteGroupList}
          onCancel={handleCancel}
          selectedCompany={selectedCompany}
          refreshGroupList={refreshGroupList}
          refreshCompanyList={refreshCompanyList}
          toggleModal={toggleModal}
        />
      )}
      {modal && modalComponent === "AddUserToCompany" && (
        <AddUserToCompany
          userList={userList}
          adminUsers={adminUsers}
          normalUsers={normalUsers}
          companyList={companyList}
          onCancel={handleCancel}
          selectedCompany={selectedCompany}
          refreshUserList={refreshUserList}
          toggleModal={toggleModal}
          csrfToken={csrfToken}
          refreshCompanyList={refreshCompanyList}
          onUserAdded={onUserAdded} // Pass the callback here
        />
      )}
      {modal && modalComponent === "UserModal" ? (
        <UserModal
          toggle={toggleModal}
          onCancel={handleCancel}
          refreshUserList={refreshUserList} // Pass refreshUserList
          company={company}
          onUserAdded={onUserAdded}
        />
      ) : null}
    </>
  );
};

export default CompanyDetails;
