import React, { useState } from "react";

export const PreSiteGroupContext = React.createContext();

export const PreSiteGroupProvider = ({ children }) => {
  const [sort, setSort] = useState({ property: "", direction: "asc" });
  const [filterValue, setFilterValue] = useState("");
  const [siteGroupInfo, setsiteGroupInfo] = useState();
  const [selectedGroup, setSelectedGroup] = useState("");

  return (
    <PreSiteGroupContext.Provider
      value={{
        sort,
        setSort,
        filterValue,
        setFilterValue,
        siteGroupInfo,
        setsiteGroupInfo,
        selectedGroup,
        setSelectedGroup,
      }}
    >
      {children}
    </PreSiteGroupContext.Provider>
  );
};
