import React, { useState } from "react";
import SiteOnMapModal from "../Sites/SiteOnMapModal";
import CamQrReader from "../Sites/CamQrReader";

export const ModalContext = React.createContext();

export const ModalProvider = ({ children }) => {
  const [modal, setModal] = useState(false);
  const [modalComponent, setModalComponent] = useState(null);
  const [modalItem, setModalItem] = useState("");
  const [toggleFunc, setToggleFunc] = useState(null);

  const toggleModal = () => {
    setModal(!modal);
  };

  const openModal = (modal, item = null, toggleFunc = null) => {
    console.log("Current modal state: ", modal);
    setModalComponent(modal);
    setModalItem(item);
    setToggleFunc(toggleFunc);
    toggleModal();
  };

  const handleCancel = (item) => {
    toggleModal();
    item.preventDefault();
  };

  return (
    <ModalContext.Provider
      value={{
        modal,
        toggleModal,
        openModal,
        modalComponent,
        modalItem,
        handleCancel,
        toggleFunc,
      }}
    >
      {children}
      {modalComponent === "SiteOnMapModal" && (
        <SiteOnMapModal siteInfo={modalItem} toggleModal={toggleModal} />
      )}
      {modalComponent === "CamQrReader" && (
        <CamQrReader
          siteInfo={modalItem}
          toggleModal={toggleModal}
          openModal={openModal}
        />
      )}
    </ModalContext.Provider>
  );
};
