import React from "react";

import { ManagementProvider } from "./Contexts/ManagementContext.js";
import { ModalProvider } from "./Contexts/ModalContext.js";
import { SiteProvider } from "./Contexts/SiteContext.js";
import { UserGroupProvider } from "./Contexts/UserGroupContext.js";
import { SiteGroupProvider } from "./Contexts/SiteGroupContext.js";
import { UserListProvider } from "./Contexts/UserListContext.js";
import { PreUserProvider } from "./Contexts/PreUserContext.js";
import { PreUserGroupProvider } from "./Contexts/PreUserGroupContext.js";
import { PreSiteGroupProvider } from "./Contexts/PreSiteGroupContext.js";
import { SiteNavigationProvider } from "./Contexts/SiteNavigationContext.js";
import { CompanyProvider } from "./Contexts/CompanyContext.js";
import { PreCompanyProvider } from "./Contexts/PreCompanyContext.js";

const ContextProvider = (props) => {
  return (
    <ModalProvider>
      <ManagementProvider>
        <CompanyProvider>
          <SiteProvider>
            <SiteGroupProvider>
              <UserListProvider>
                <UserGroupProvider>
                  <PreUserProvider>
                    <PreUserGroupProvider>
                      <PreSiteGroupProvider>
                        <SiteNavigationProvider>
                          <PreCompanyProvider>
                            {props.children}
                          </PreCompanyProvider>
                        </SiteNavigationProvider>
                      </PreSiteGroupProvider>
                    </PreUserGroupProvider>
                  </PreUserProvider>
                </UserGroupProvider>
              </UserListProvider>
            </SiteGroupProvider>
          </SiteProvider>
        </CompanyProvider>
      </ManagementProvider>
    </ModalProvider>
  );
};

export default ContextProvider;
