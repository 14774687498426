import React, { useState } from "react";

export const PreUserContext = React.createContext();

export const PreUserProvider = ({ children }) => {
  const [selectedUserID, setSelectedUserID] = useState();
  const [sort, setSort] = useState({ property: "", direction: "asc" });
  const [sortIndicator, setSortIndicator] = useState("");
  const [filterValue, setFilterValue] = useState("");

  return (
    <PreUserContext.Provider
      value={{
        selectedUserID,
        setSelectedUserID,
        sort,
        setSort,
        sortIndicator,
        setSortIndicator,
        filterValue,
        setFilterValue,
      }}
    >
      {children}
    </PreUserContext.Provider>
  );
};
