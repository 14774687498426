import React, { useState, useContext } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label,
  Table,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { UserGroupContext } from "../Contexts/UserGroupContext";
import { UserListContext } from "../Contexts/UserListContext";

const AddUserToGroup = (props) => {
  const { selectedUser, selectedGroup, toggle, onCancel } = props;

  const [searchTerm, setSearchTerm] = useState("");
  const [searchGroupTerm, setSearchGroupTerm] = useState("");
  const [selectedUserInTable, setSelectedUserInTable] = useState(null);
  const [selectedGroupInTable, setSelectedGroupInTable] = useState(null);
  const [sortConfig, setSortConfig] = useState(null);
  const [groupSortConfig, setGroupSortConfig] = useState(null);

  const { register, handleSubmit, formState, setValue } = useForm({});

  const { userList, handleUpdateUserGroupMembership } =
    useContext(UserListContext);
  const { userGroupList, refreshUserGroupList } = useContext(UserGroupContext);

  const handleUpdateUserGroupMembershipSubmit = (data) => {
    toggle();
    handleUpdateUserGroupMembership(data.id, data.group_id, true);
    refreshUserGroupList();
  };

  const handleRowClick = (user) => {
    if (selectedUserInTable && selectedUserInTable.id === user.id) {
      setSelectedUserInTable(null);
      setValue("id", "");
      setSearchTerm("");
    } else {
      setSelectedUserInTable(user);
      setValue("id", user.id);
      setSearchTerm(`${user.first_name} ${user.last_name}`);
    }
  };

  const handleGroupRowClick = (group) => {
    if (selectedGroupInTable && selectedGroupInTable.id === group.id) {
      setSelectedGroupInTable(null);
      setValue("group_id", "");
      setSearchGroupTerm("");
    } else {
      setSelectedGroupInTable(group);
      setValue("group_id", group.id);
      setSearchGroupTerm(group.name);
    }
  };

  const requestSort = (key) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const requestGroupSort = (key) => {
    let direction = "ascending";
    if (
      groupSortConfig &&
      groupSortConfig.key === key &&
      groupSortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setGroupSortConfig({ key, direction });
  };

  let sortedGroupList = [...userGroupList];

  let sortedUserList = [...userList];
  if (sortConfig !== null) {
    sortedUserList.sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? 1 : -1;
      }
      return 0;
    });
  }

  if (groupSortConfig !== null) {
    sortedGroupList.sort((a, b) => {
      if (a[groupSortConfig.key] < b[groupSortConfig.key]) {
        return groupSortConfig.direction === "ascending" ? -1 : 1;
      }
      if (a[groupSortConfig.key] > b[groupSortConfig.key]) {
        return groupSortConfig.direction === "ascending" ? 1 : -1;
      }
      return 0;
    });
  }

  let userGroupIds = selectedUser ? selectedUser.groups : [];
  const filteredGroupList = sortedGroupList
    .filter(
      (group) =>
        (group.name?.toLowerCase() || "").includes(
          searchGroupTerm.toLowerCase(),
        ) ||
        (group.description?.toLowerCase() || "").includes(
          searchGroupTerm.toLowerCase(),
        ),
    )
    .filter((group) => !userGroupIds.includes(group.id));

  const selectedGroupId = selectedGroup ? selectedGroup.id : null;
  const filteredUserList = sortedUserList.filter(
    (user) =>
      ((user.email?.toLowerCase() || "").includes(searchTerm.toLowerCase()) ||
        `${user.first_name || ""} ${user.last_name || ""}`
          .toLowerCase()
          .includes(searchTerm.toLowerCase())) &&
      (selectedGroupId === null || !user.groups.includes(selectedGroupId)),
  );

  return (
    <Modal isOpen={true} toggle={toggle}>
      <ModalHeader toggle={toggle} style={{ backgroundColor: "#d3d3d3" }}>
        Add {selectedUser ? selectedUser.username : "User"} to{" "}
        {selectedGroup ? selectedGroup.name : "User Group"}
      </ModalHeader>
      <form
        onSubmit={handleSubmit((data) =>
          handleUpdateUserGroupMembershipSubmit(data),
        )}
      >
        <ModalBody>
          {selectedUser ? (
            <input type="hidden" value={selectedUser.id} {...register("id")} />
          ) : (
            <>
              <FormGroup>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Label for="search" style={{ width: "20%" }}>
                    <b>Search : </b>
                  </Label>
                  <Input
                    style={{ width: "150%" }}
                    type="text"
                    name="search"
                    id="search"
                    placeholder="Search User"
                    value={searchTerm}
                    onChange={(e) => {
                      setSearchTerm(e.target.value);
                      if (e.target.value === "") setSelectedUserInTable(null);
                    }}
                  />
                </div>
              </FormGroup>

              <div style={{ maxHeight: "300px", overflowY: "scroll" }}>
                <Table hover>
                  <thead
                    style={{
                      position: "sticky",
                      top: 0,
                      backgroundColor: "#fff",
                      borderTop: "2px solid #000",
                      borderBottom: "3px solid #000",
                      zIndex: 1,
                    }}
                  >
                    <tr>
                      <th onClick={() => requestSort("email")}>
                        Email{" "}
                        {sortConfig?.key === "email"
                          ? sortConfig.direction === "ascending"
                            ? "↑"
                            : "↓"
                          : ""}
                      </th>
                      <th onClick={() => requestSort("first_name")}>
                        Full Name{" "}
                        {sortConfig?.key === "first_name"
                          ? sortConfig.direction === "ascending"
                            ? "↑"
                            : "↓"
                          : ""}
                      </th>
                      <th
                        onClick={() =>
                          requestSort("extended_user.field_office")
                        }
                      >
                        Field Office{" "}
                        {sortConfig?.key === "extended_user.field_office"
                          ? sortConfig.direction === "ascending"
                            ? "↑"
                            : "↓"
                          : ""}
                      </th>
                      <th>Select</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredUserList.map((user) => (
                      <tr
                        key={user.id}
                        style={
                          selectedUserInTable?.id === user.id
                            ? { backgroundColor: "lightblue" }
                            : {}
                        }
                      >
                        <td>{user.email}</td>
                        <td>{`${user.first_name} ${user.last_name}`}</td>
                        <td>
                          {user.extended_user?.field_office
                            ? user.extended_user.field_office
                            : "No field office entered"}
                        </td>
                        <td>
                          <Button
                            onMouseOver={(e) =>
                              (e.target.style.backgroundColor = "#00008B")
                            }
                            onMouseOut={(e) =>
                              (e.target.style.backgroundColor =
                                selectedUserInTable?.id === user.id
                                  ? "#00008B"
                                  : "")
                            }
                            onClick={() => handleRowClick(user)}
                          >
                            {selectedUserInTable?.id === user.id
                              ? "Selected"
                              : "Select"}
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
              {formState.errors.id && <span>This field is required</span>}
            </>
          )}

          {selectedGroup ? (
            <input
              type="hidden"
              value={selectedGroup.id}
              {...register("group_id")}
            />
          ) : (
            <>
              <FormGroup>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Label for="groupSearch" style={{ width: "20%" }}>
                    <b>Search : </b>
                  </Label>
                  <Input
                    style={{ width: "150%" }}
                    type="text"
                    name="groupSearch"
                    id="groupSearch"
                    placeholder="Search User Group"
                    value={searchGroupTerm}
                    onChange={(e) => {
                      setSearchGroupTerm(e.target.value);
                      if (e.target.value === "") setSelectedGroupInTable(null);
                    }}
                  />
                </div>
              </FormGroup>

              <div style={{ maxHeight: "300px", overflowY: "scroll" }}>
                <Table hover>
                  <thead
                    style={{
                      position: "sticky",
                      top: 0,
                      backgroundColor: "#fff",
                      borderTop: "2px solid #000",
                      borderBottom: "3px solid #000",
                      zIndex: 1,
                    }}
                  >
                    <tr>
                      <th onClick={() => requestGroupSort("name")}>
                        Name{" "}
                        {groupSortConfig?.key === "name"
                          ? groupSortConfig.direction === "ascending"
                            ? "↑"
                            : "↓"
                          : ""}
                      </th>
                      <th>Description</th>
                      <th>Select</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredGroupList.map((group) => (
                      <tr
                        key={group.id}
                        style={
                          selectedGroupInTable?.id === group.id
                            ? { backgroundColor: "lightblue" }
                            : {}
                        }
                      >
                        <td>{group.name}</td>
                        <td>{group.extended_group?.description}</td>
                        <td>
                          <Button
                            onMouseOver={(e) =>
                              (e.target.style.backgroundColor = "#00008B")
                            }
                            onMouseOut={(e) =>
                              (e.target.style.backgroundColor =
                                selectedGroupInTable?.id === group.id
                                  ? "#00008B"
                                  : "")
                            }
                            onClick={() => handleGroupRowClick(group)}
                          >
                            {selectedGroupInTable?.id === group.id
                              ? "Selected"
                              : "Select"}
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
              {formState.errors.group_id && <span>This field is required</span>}
            </>
          )}
        </ModalBody>

        <ModalFooter className="d-flex flex-row justify-content-between">
          <Button color="danger" onClick={onCancel}>
            Cancel
          </Button>
          <Button color="success" type="submit">
            Save
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default AddUserToGroup;
