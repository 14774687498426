import React, { useState, useEffect, useContext } from "react";
import { useIsSmallPortrait } from "../../MediaQueries.js";
import Map from "../Sites/Map.js";
import SiteDetailsManagement from "../Sites/SiteDetailsManagement.js";
import Sites from "../Sites/Sites.js";
import SiteImages from "../Sites/SiteImages.js";
import SiteLog from "../Sites/SiteLog.js";
import { ModalContext } from "../Contexts/ModalContext";
import { SiteContext } from "../Contexts/SiteContext";
import { ManagementContext } from "../Contexts/ManagementContext.js";
import { SiteGroupContext } from "../Contexts/SiteGroupContext";
import { SiteNavigationContext } from "../Contexts/SiteNavigationContext";
import CallbackLog from "../Sites/CallbackLog.js";
import SiteGroupModal from "../SiteGroups/SiteGroupModal.js";
import { getCookie } from "../Utilities/CookieUtils.js";
import $ from "jquery";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";

const Landing = (props) => {
  const { isCompanyAdmin } = props;
  const { modal, toggleModal, openModal, modalComponent, modalItem } =
    useContext(ModalContext);
  const { user } = useContext(ManagementContext);
  const { siteGroupList, refreshGroupList } = useContext(SiteGroupContext);
  const [adminUser, setAdminUser] = useState(false); // State for admin user
  const {
    siteList,
    refreshList,
    siteInfo,
    refreshMaintenanceAccessibility,
    siteMaintenanceAccessibility,
    refreshSiteInfo,
    handleSiteDelete,
    handleSiteSubmit,
    handleSiteEdit,
    imageRotation,
    siteLoading,
    siteListLoading,
  } = useContext(SiteContext);
  const {
    highlightedSite,
    setHighlightedSite,
    expandedGroups,
    setExpandedGroups,
  } = useContext(SiteNavigationContext);
  const navigate = useNavigate();
  const [showMap, setShowMap] = useState(null);
  const params = useParams();

  // state that shows a loading spinner
  const [isLoading, setIsLoading] = useState(false);
  // state that keep track of whether the spinner is already shown or not
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  useEffect(() => {
    async function refreshSites() {
      try {
        await Promise.all([
          refreshList(true),
          refreshMaintenanceAccessibility(true),
          refreshGroupList()
        ]);
      } catch (error) {
        console.error("Error refreshing sites:", error);
      } finally {
        setIsLoading(false); // Stop loading when all calls complete
      }
    }
  
    if (isFirstLoad) {
      setIsFirstLoad(false);
      setIsLoading(true);
    }
    refreshSites();
  }, [refreshList]);

  const [csrfToken] = useState(getCookie("redeyeCSRFcookie"));

  const handleSiteGroupSubmit = (item) => {
    toggleModal();
    let url = "/redeye/api/site_group/";
    let type = "POST";

    $.ajax({
      type: type,
      url: url,
      data: item,
      headers: { "X-CSRFToken": csrfToken },
      xhrFields: { withCredentials: true },
      dataType: "json",
      success: function (data) {
        refreshList(true);
        refreshGroupList();
      },
    });
  };

  const handleDelete = (item) => {
    toggleModal();
    $.ajax({
      type: "DELETE",
      url: `/redeye/api/site_group/${item.id}/`,
      data: item,
      headers: { "X-CSRFToken": csrfToken },
      xhrFields: { withCredentials: true },
      dataType: "json",
      success: function (data) {
        refreshList();
      },
    });
  };

  useEffect(() => {
    // Use current URL to open selected site
    const siteId = params?.id;
    refreshSiteInfo(siteId);
    if (siteId?.length > 0) {
      setShowMap(siteId);
      const matchedSite = siteList?.find((site) => site.id === siteId);
      if (matchedSite?.length == 0) setShowMap(null);
    }
  }, []);

  const selectSite = (item) => {
    if (item != null) {
      const selectSiteCallback = () => {
        setHighlightedSite(item.id);
        setExpandedGroups([...expandedGroups, item.group_name]);
      };
      refreshSiteInfo(item.id, selectSiteCallback, true);
    }
  };
  const siteSelectHandler = (item) => {
    window.history.replaceState(window.history.state, "", `/id/${item}`);
    setShowMap(item);
    refreshSiteInfo(item);
    const matchedSite = siteList?.find((site) => site.id === item);
    let isAdminUser = siteGroupList?.some(
      (siteGroup) => siteGroup.id === matchedSite?.group
    );
    if (user?.is_superuser) {
      isAdminUser = true;
    }
    setAdminUser(isAdminUser);
  };
  const sitedeleteHandler = () => {
    setShowMap(null);
  };
  const buttonStyle = {
    fontSize: "1.2rem",
    fontWeight: "bold",
    textTransform: "uppercase",
    letterSpacing: "1px",
    padding: "0.3rem 0.8rem",
    border: "none",
    backgroundColor: "transparent",
    outline: "none",
    color: "#5683af",
  };

  return (
    <main>
      <div className="w-full pt-2 px-2">
        <div className="grid grid-cols-2">
          <div className="">
            <Sites
              siteList={siteList}
              refreshList={refreshList}
              selectSite={selectSite}
              siteSelectHandler={siteSelectHandler}
              siteGroupList={siteGroupList}
              user={user}
              siteListLoading={isLoading}
            />
            {((siteGroupList && siteGroupList.length > 0) ||
              isCompanyAdmin) && (
              <div className="fixed bottom-3 flex justify-start gap-3">
                <button
                  className="btn btn-primary drop-shadow-md"
                  onClick={() => {
                    openModal("SiteGroupModal");
                  }}
                >
                  Create Site Group
                </button>
                <button
                  className="btn btn-secondary drop-shadow-md"
                  onClick={() => {
                    navigate("/sites?tab=siteGroups");
                  }}
                >
                  Manage All Site Groups
                </button>
              </div>
            )}
          </div>

          <div className="h-auto">
            {showMap === null ? (
              <Map
                siteList={siteList}
                selectSite={selectSite}
                openModal={openModal}
                siteInfo={siteInfo}
              />
            ) : (
              <SiteDetailsManagement
                adminUser={adminUser}
                landing={true}
                sitedeleteHandler={sitedeleteHandler}
                siteInfo={siteInfo}
                siteList={siteList}
                siteMaintenanceAccessibility={siteMaintenanceAccessibility}
                refreshSiteInfo={refreshSiteInfo}
                handleSiteDelete={handleSiteDelete}
                handleSiteSubmit={handleSiteSubmit}
                handleSiteEdit={handleSiteEdit}
                imageRotation={imageRotation}
                siteLoading={siteLoading}
                user={user}
                siteListLoading={siteListLoading}
              />
            )}
          </div>
        </div>
        {/* Modals */}
        {modal && modalComponent === "SiteGroupModal" ? (
          <SiteGroupModal
            toggle={toggleModal}
            onSave={handleSiteGroupSubmit}
            onDelete={handleDelete}
          />
        ) : null}
        {modal && modalComponent === "SiteLog" ? (
          <SiteLog siteInfo={modalItem} toggle={toggleModal} />
        ) : null}
        {modal && modalComponent === "SiteImages" ? (
          <SiteImages siteInfo={modalItem} toggle={toggleModal} />
        ) : null}
        {modal && modalComponent === "CallbackLog" ? (
          <CallbackLog siteInfo={modalItem} toggle={toggleModal} />
        ) : null}
      </div>
    </main>
  );
};

export default Landing;
