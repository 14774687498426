import React from "react";

import cameraImage from '../../images/camera.png';
import mapImage from '../../images/map.png';

const LoginPageContent = () => {
  return (
    <section className="flex-grow w-full">
      <div className="flex flex-col items-start mb-5">
        <div className="mr-8">
          <h2 className="text-2xl font-bold mb-4">
            Welcome to HydroCams Manager
          </h2>
          <p className="mb-4">
            <strong>HydroCams Manager</strong> is the administrative interface
            to the camera network management tool developed for a project funded
            by USGS to develop and commercialize the smart camera-based water
            level monitoring and data collection system pioneered under the
            FloodAware project. HydroCams allows easy automated monitoring of
            water levels across thousands of locations in an unlimited
            geographic area.
          </p>
          <p className="mb-4">
            <strong>HydroCams Manager</strong> allows users to quickly install,
            configure, manage, and maintain large networks of smart camera
            endpoints. Key features include:
          </p>
          <ul className="list-disc pl-5 mb-4">
            <li className="mb-2">
              <u>Plug and play:</u> New physical endpoints can be quickly
              installed and registered with the server in minutes.
            </li>
            <li className="mb-2">
              <u>Self-Diagnosing:</u> Endpoints monitor their own performance
              and report warnings and errors. A malfunctioning endpoint can be
              replaced with a new one in minutes.
            </li>
            <li className="mb-2">
              <u>Self-updating:</u> Endpoints automatically update their
              software over-the-air.
            </li>
            <li className="mb-2">
              <u>Smart Power management:</u> Endpoints reason about how much
              power they have left and adapt their behavior accordingly.
            </li>
            <li className="mb-2">
              <u>Self-Improvement:</u> Endpoints learn the unique
              characteristics of their installed location (solar availability,
              typical workloads) and can change their behavior or request
              modifications to optimize performance.
            </li>
          </ul>
        </div>
        <div className="flex flex-row space-x-10">
          <div className="mb-6">
            <img
              src={mapImage}
              alt="Map"
              className="w-64 h-auto mx-auto md:ml-0"
            />
          </div>
          <div className="mb-6">
            <img
              src={cameraImage}
              alt="Camera"
              className="h-auto mx-auto md:ml-0"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default LoginPageContent;
