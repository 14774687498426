import React, { useEffect, useContext, useState } from "react";
import { Button, Input } from "reactstrap";
import AddPermToUser from "../UserGroups/AddPermToUser.js";
import AddUserToSiteGroup from "./AddUserToSiteGroup.js";
import DeleteUser from "../UserGroups/DeleteUser.js";
import AddUserToGroup from "./AddUserToGroup.js";
import ListXButton from "../Utilities/ListXButton.js";
import $ from "jquery";
import { ModalContext } from "../Contexts/ModalContext";
import { UserGroupContext } from "../Contexts/UserGroupContext";
import { UserListContext } from "../Contexts/UserListContext";

import { CompanyContext } from "../Contexts/CompanyContext";
import { useToastContext } from "../Contexts/ToastContext.js";
import { getCookie } from "../Utilities/CookieUtils.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

import { Table } from "reactstrap";

import { faFilter, faRedoAlt } from "@fortawesome/free-solid-svg-icons";

import ScrollbarStyles from "../Utilities/ScrollbarStyles.js";

const UserDetails = (props) => {
  const { userInfo, selectedUserID, toggle } = props;
  const { modal, openModal, modalComponent, handleCancel } =
    useContext(ModalContext);
  const { userGroupList, refreshUserGroupList } = useContext(UserGroupContext);
  const { currentUser, refreshUserList, handleUpdateUserGroupMembership } =
    useContext(UserListContext);
  const { userCompany } = useContext(CompanyContext);
  const [adminUser, setAdminUser] = useState(false);
  const { showToast } = useToastContext();

  const [userGroupSearch, setUserGroupSearch] = React.useState("");
  const [permissionSearch, setPermissionSearch] = React.useState("");

  const [csrfToken] = useState(getCookie("redeyeCSRFcookie"));
  const [userSiteGroups, setUserSiteGroups] = useState([]);
  const [siteGroupsMap, setSiteGroupsMap] = useState({});

  useEffect(() => {
    if (userInfo && userCompany) {
      // Check if there's an entry in userCompany where .user equals userInfo.id and .is_companyadmin is true
      const isAdmin = userCompany.some(
        (entry) => entry.user === userInfo.id && entry.is_companyadmin
      );
      setAdminUser(isAdmin);
    }
  }, [userInfo, userCompany]);

  // Function to fetch user site groups
  const fetchUserSiteGroups = () => {
    $.ajax({
      type: "GET",
      url: "/redeye/api/user_site_group/",
      headers: { "X-CSRFToken": csrfToken },
      xhrFields: { withCredentials: true },
      dataType: "json",
      success: function (data) {
        const filteredData = data.filter(
          (entry) => entry.user === selectedUserID
        );
        setUserSiteGroups(filteredData);
        fetchSiteGroups(); // Fetch site groups after fetching user site groups
      },
      error: function (xhr, textStatus, errorThrown) {
        console.error("Error fetching user site groups:", errorThrown);
      },
    });
  };

  // Function to fetch site groups
  const fetchSiteGroups = () => {
    $.ajax({
      type: "GET",
      url: "/redeye/api/site_group/", // Adjust the URL as needed
      headers: { "X-CSRFToken": csrfToken },
      xhrFields: { withCredentials: true },
      dataType: "json",
      success: function (data) {
        const siteGroupsMap = data.reduce((acc, group) => {
          acc[group.id] = group.name;
          return acc;
        }, {});
        setSiteGroupsMap(siteGroupsMap);
      },
      error: function (xhr, textStatus, errorThrown) {
        console.error("Error fetching site groups:", errorThrown);
      },
    });
  };

  // Combine user site groups with site group names
  useEffect(() => {
    if (userInfo) {
      fetchUserSiteGroups();
    }
  }, [userInfo]);

  const removeUserSiteGroup = (id) => {
    if (window.confirm("Are you sure you want to remove this site group?")) {
      $.ajax({
        type: "DELETE",
        url: `/redeye/api/user_site_group/${id}/`,
        headers: { "X-CSRFToken": csrfToken },
        xhrFields: { withCredentials: true },
        dataType: "json",
        success: function (data) {
          showToast("Site group removed successfully.");
          fetchUserSiteGroups(); // Refresh the list
        },
        error: function (xhr, textStatus, errorThrown) {
          showToast("Error removing site group:", errorThrown);
        },
      });
    }
  };

  useEffect(() => {
    document.title = "Logical Endpoints";
    refreshUserList();
    refreshUserGroupList();
  }, [refreshUserGroupList]);

  if (!userGroupList) {
    return null;
  }

  const removeUserFromGroup = (user_id, group_id) => {
    if (window.confirm("Are you sure you want to delete this user group?")) {
      handleUpdateUserGroupMembership(user_id, group_id, false);
    }
  };

  const updateUserPermission = (user, data) => {
    if (
      window.confirm("Are you sure you want to update this user's permissions?")
    ) {
      $.ajax({
        type: "PUT",
        url: `/redeye/api/update_user_site_group_permission/${user.id}/`,
        data: data,
        headers: { "X-CSRFToken": csrfToken },
        xhrFields: { withCredentials: true },
        dataType: "json",
        success: function (data) {
          showToast("Permission updated successfully.");
          refreshUserList();
        },
        error: function (xhr, textStatus, errorThrown) {
          showToast("Error updating permission:", errorThrown);
        },
      });
    }
  };

  const DeleteUserPost = (user, data) => {
    $.ajax({
      type: "DELETE",
      url: `/redeye/api/user/${user.id}/`,
      data: data,
      headers: { "X-CSRFToken": csrfToken },
      xhrFields: { withCredentials: true },
      dataType: "json",
      success: function (data) {
        showToast("Success:  User Deleted");
        refreshUserList();
      },
      error: function (xhr, textStatus, errorThrown) {
        showToast("Error deleting user:", errorThrown);
      },
    });
  };

  const onPermChange = (userInfo, perm_id, perm) => {
    if (perm === "can_view") {
      updateUserPermission(userInfo, {
        site_group: perm_id,
        can_view: true,
        can_edit: false,
      });
    } else if (perm === "can_edit") {
      updateUserPermission(userInfo, { site_group: perm_id, can_edit: true });
    }
  };

  const scrollableTableStyle = {
    maxHeight: "calc(3 * 3.5rem + 3 * 3px)",
    overflowY: "auto",
    // border: '1px solid #ccc'
  };

  const stickyHeaderStyle = {
    position: "sticky",
    top: "0",

    backgroundColor: "#f2f2f2",
    zIndex: "10",
  };

  const onUserAdded = () => {
    fetchUserSiteGroups();
  };
  return (
    <>
      <ScrollbarStyles />
      <div
        style={{
          marginRight: "10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ width: "100%" }}>
          <h2
            style={{
              backgroundColor: "lightblue",
              padding: "6px",
              borderRadius: "6px",
            }}
          >
            {userInfo.first_name && userInfo.last_name
              ? `${userInfo.first_name} ${userInfo.last_name}`
              : userInfo.username}
            {userInfo.is_superuser
              ? " (superuser)"
              : adminUser
              ? " (company admin)"
              : " (normal user)"}{" "}
            &nbsp;
          </h2>
          <span>
            <b>Joined: </b>
            {userInfo.date_joined}
          </span>
          <br />
          <span>
            <b>Last login: </b>
            {userInfo.last_login ? userInfo.last_login : "Never logged in"}
          </span>
        </div>
        <div
          style={{
            padding: "6px",
            border: "2px solid #000",
            marginLeft: "5px",
            marginTop: "5px",
          }}
        >
          <FontAwesomeIcon icon={faUser} size="7x" />
        </div>
      </div>
      <h5>
        <u>Personal:</u>
      </h5>
      <span className="pl-5">
        <b>Name:</b> {userInfo.first_name} {userInfo.last_name}
      </span>
      <span className="pl-5">
        <b>Email:</b> {userInfo.email}
      </span>
      <span className="pl-5">
        <b>Phone:</b>{" "}
        {userInfo.extended_user?.phone_number
          ? userInfo.extended_user.phone_number
          : "No phone number entered"}
      </span>
      <h5>
        <u>Professional:</u>
      </h5>
      <span className="pl-5">
        <b>Job:</b>{" "}
        {userInfo.extended_user?.job_title
          ? userInfo.extended_user.job_title
          : "No job entered"}
      </span>
      <span className="pl-5">
        <b>Division:</b>{" "}
        {userInfo.extended_user?.division
          ? userInfo.extended_user.division
          : "No division entered"}
      </span>
      <span className="pl-5">
        <b>Field Office:</b>{" "}
        {userInfo.extended_user?.field_office
          ? userInfo.extended_user.field_office
          : "No field office entered"}
      </span>
      <hr />
      {/*
      <h4>Access Management</h4>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          backgroundColor: "darkgray",
          padding: "4px",
          borderRadius: "6px",
          marginTop: "5px",
        }}
      >
        <h5
          style={{
            fontSize: "17px",
            margin: "0 10px 0 0",
          }}
        >
          <u>Group Membership</u>
        </h5>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            width: "82.7%",
          }}
        >
          <FontAwesomeIcon
            icon={faFilter}
            size="lg"
            style={{ marginRight: "10px" }}
          />
          <Input
            type="text"
            placeholder="Search..."
            value={userGroupSearch}
            onChange={(e) => setUserGroupSearch(e.target.value)}
            style={{
              height: "30px",
              fontSize: "14px",
              width: "20%",
              backgroundColor: "lightgrey",
            }}
          />
        </div>
      </div>

      <p>
        A user can be assigned to a user group and will inherit access to view
        and update any site objects that the group can view or edit.
      </p>
      <div className="scrollable-table" style={scrollableTableStyle}>
        <Table>
          <thead style={stickyHeaderStyle}>
            <tr>
              <th>User Group</th>
              <th>Read Only Site Groups</th>
              <th>Editable Site Groups</th>
              <th>Description</th>
              <th>Remove</th>
            </tr>
          </thead>
          <tbody>
            {userInfo &&
              userInfo.groups
                .map((groupId) =>
                  userGroupList.find((group) => group.id === groupId)
                )
                .filter((userGroup) => {
                  if (!userGroup) return false;
                  return userGroup.name
                    .toLowerCase()
                    .includes(userGroupSearch.toLowerCase());
                })
                .map((userGroup) => {
                  const readOnlyGroups = userGroup.group_permissions.filter(
                    (perm) => perm.can_view && !perm.can_edit
                  );
                  const editableGroups = userGroup.group_permissions.filter(
                    (perm) => perm.can_edit
                  );
                  const descriptionGroups =
                    userGroup.extended_group?.description;

                  return (
                    <tr key={userGroup.id}>
                      <th>{userGroup.name || "Unknown User Group"}</th>
                      <td>{readOnlyGroups ? readOnlyGroups.length : "N/A"}</td>
                      <td>{editableGroups ? editableGroups.length : "N/A"}</td>
                      <td>{descriptionGroups || "No Description"}</td>
                      <td>
                        <ListXButton
                          onClick={() =>
                            removeUserFromGroup(userInfo.id, userGroup.id)
                          }
                        />
                      </td>
                    </tr>
                  );
                })}
          </tbody>
        </Table>
      </div>
      <Button color="primary" onClick={() => openModal("AddUserToGroup")}>
        Add User to Group
      </Button>
      */}
      {!userInfo.is_superuser && (
        <>
          <h4>User Site Groups</h4>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "darkgray",
              padding: "4px",
              borderRadius: "6px",
              marginTop: "5px",
            }}
          >
            <h5
              style={{
                fontSize: "17px",
                margin: "0 10px 0 0",
              }}
            >
              <u>Site Groups</u>
            </h5>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                width: "73.5%",
              }}
            >
              <FontAwesomeIcon
                icon={faFilter}
                size="lg"
                style={{ marginRight: "10px" }}
              />
              <Input
                type="text"
                placeholder="Search..."
                value={userGroupSearch}
                onChange={(e) => setUserGroupSearch(e.target.value)}
                style={{
                  height: "30px",
                  fontSize: "14px",
                  width: "20%",
                  backgroundColor: "lightgrey",
                }}
              />
            </div>
          </div>

          <div className="scrollable-table" style={scrollableTableStyle}>
            <Table>
              <thead style={stickyHeaderStyle}>
                <tr>
                  <th>Site Group</th>
                  <th>Remove</th>
                </tr>
              </thead>
              <tbody>
                {userSiteGroups
                  .filter((entry) => {
                    const groupName = siteGroupsMap[entry.sitegroup];
                    return (
                      groupName &&
                      groupName
                        .toLowerCase()
                        .includes(userGroupSearch?.toLowerCase())
                    );
                  })

                  .map((entry) => (
                    <tr key={entry.id}>
                      <td>
                        {siteGroupsMap[entry.sitegroup] || "Unknown Site Group"}
                      </td>
                      <td>
                        <ListXButton
                          onClick={() => removeUserSiteGroup(entry.id)}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>

          <Button
            color="primary"
            onClick={() => openModal("AddUserToSiteGroup")}
          >
            Add Existing User to Site Group
          </Button>
        </>
      )}
      {/*
      <div
        style={{
          display: "flex",
          alignItems: "center",
          backgroundColor: "darkgray",
          padding: "4px",
          borderRadius: "6px",
          marginTop: "5px",
        }}
      >
        <h5
          style={{
            fontSize: "17px",
            margin: "0 10px 0 0",
          }}
        >
          <u>Additional specific permissions</u>
        </h5>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            width: "73.5%",
          }}
        >
          <FontAwesomeIcon
            icon={faFilter}
            size="lg"
            style={{ marginRight: "10px" }}
          />
          <Input
            type="text"
            placeholder="Search..."
            value={permissionSearch}
            onChange={(e) => setPermissionSearch(e.target.value)}
            style={{
              height: "30px",
              fontSize: "14px",
              width: "20%",
              backgroundColor: "lightgrey",
            }}
          />
        </div>
      </div>

      <p>
        A user's access to view and update site objects is generally managed by
        their membership in user groups. If a user requires additional access,
        they may be given specific permission to view or update specific site
        groups.
      </p>
      {userInfo &&
      userInfo.user_permissions &&
      userInfo.user_permissions.length > 0 ? (
        <div className="scrollable-table" style={scrollableTableStyle}>
          <Table>
            <thead style={stickyHeaderStyle}>
              <tr>
                <th>Site Group</th>
                <th>Permission</th>
                <th>Description</th>
                <th>Remove</th>
              </tr>
            </thead>
            <tbody>
              {userInfo &&
                userInfo.user_permissions
                  .filter((perm) =>
                    perm.name
                      .toLowerCase()
                      .includes(permissionSearch.toLowerCase())
                  )
                  .map((perm) => (
                    <tr key={perm.id}>
                      <td>{perm.name}</td>
                      {perm.can_view || perm.can_edit ? (
                        <>
                          <td>
                            <Input
                              type="select"
                              defaultValue={
                                perm.can_edit ? "can_edit" : "can_view"
                              }
                              onChange={(e) =>
                                onPermChange(userInfo, perm.id, e.target.value)
                              }
                            >
                              <option key={"can_view"} value={"can_view"}>
                                Can view
                              </option>
                              <option key={"can_edit"} value={"can_edit"}>
                                Can view and edit
                              </option>
                            </Input>
                          </td>
                          <td>
                            {perm.description
                              ? perm.description
                              : "No Description"}
                          </td>
                          <td>
                            {
                              <ListXButton
                                onClick={() =>
                                  updateUserPermission(userInfo, {
                                    site_group: perm.id,
                                    can_view: false,
                                    can_edit: false,
                                  })
                                }
                              />
                            }
                          </td>
                        </>
                      ) : null}
                    </tr>
                  ))}
            </tbody>
          </Table>
        </div>
      ) : (
        <p>User has no additional permissions</p>
      )}

      <Button color="primary" onClick={() => openModal("AddPermToUser")}>
        Add Perm To User
      </Button> 
      */}

      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button color="danger" onClick={() => openModal("DeleteUser")}>
          Delete User
        </Button>
      </div>
      {modal && modalComponent === "AddUserToGroup" ? (
        <AddUserToGroup
          selectedUser={userInfo}
          selectedGroup={null}
          toggle={toggle}
          onCancel={handleCancel}
        />
      ) : null}
      {modal && modalComponent === "AddUserToSiteGroup" ? (
        <AddUserToSiteGroup
          selectedUser={userInfo}
          toggle={toggle}
          onCancel={handleCancel}
          onUserAdded={onUserAdded}
        />
      ) : null}
      {modal && modalComponent === "AddPermToUser" ? (
        <AddPermToUser
          userInfo={userInfo}
          updateUserPermission={updateUserPermission}
          toggle={toggle}
          onCancel={handleCancel}
        />
      ) : null}
      {modal && modalComponent === "DeleteUser" ? (
        <DeleteUser
          userInfo={userInfo}
          toggle={toggle}
          DeleteUserPost={DeleteUserPost}
          onCancel={handleCancel}
        />
      ) : null}
    </>
  );
};
export default UserDetails;
