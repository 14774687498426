import React, { useState, useEffect, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Button,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import $ from "jquery";
import { getCookie } from "../Utilities/CookieUtils.js";
import { SiteContext } from "../Contexts/SiteContext";
const EndpointConfigModal = (props) => {
  const [csrfToken] = useState(getCookie("redeyeCSRFcookie"));

  const { refreshList, refreshSiteInfo } = useContext(SiteContext);

  const { siteInfo, toggle, onCancel } = props;

  const { register, control, handleSubmit, setValue } = useForm();

  useEffect(() => {
    // console.log("SITE INFO",siteInfo); // log siteinfo
    if (siteInfo) {
      // console.log("LATEST ENDPOINT",siteInfo?.latest_endpoint); // log latest_endpoint
      if (siteInfo?.latest_endpoint) {
        // console.log("LATEST ENDPOINT ID",siteInfo?.latest_endpoint?.id); // log latest_endpoint id
        setValue("id", siteInfo?.latest_endpoint?.id);
        // setValue("siteInfo_id", siteInfo?.id);
        setValue("callback_time", siteInfo?.latest_endpoint?.callback_time);
        setValue("picture_time", siteInfo?.latest_endpoint?.picture_time);
        setValue("status_time", siteInfo?.latest_endpoint?.status_time);
        setValue(
          "maintenance_mode",
          siteInfo?.latest_endpoint?.maintenance_mode === true
        );
        setValue(
          "minimum_voltage",
          parseFloat(siteInfo?.latest_endpoint?.minimum_voltage)
        );
        setValue(
          "recharged_voltage",
          parseFloat(siteInfo?.latest_endpoint?.recharged_voltage)
        );
      }
    }
  }, [siteInfo, setValue]);

  const handleEndpointSubmit = (data) => {
    toggle();

    if (typeof data.maintenance_mode === "string") {
      data.maintenance_mode =
        data.maintenance_mode === "on" || data.maintenance_mode === "true";
    } else if (typeof data.maintenance_mode === "boolean") {
      data.maintenance_mode = data.maintenance_mode;
    }

    {
      /*converting data into seconds to store it inside the database*/
    }
    data.callback_time_minutes = parseInt(data.callback_time_minutes, 10);
    data.callback_time_hours = parseInt(data.callback_time_hours, 10);
    if (data.callback_time_minutes + data.callback_time_hours * 60 === 0) {
      alert(
        "Callback time can't be 0, enter a valid callback time in HHH:MM format"
      );
    } else {
      data.callback_time =
        data.callback_time_minutes + data.callback_time_hours * 60;
    }

    data.picture_time_minutes = parseInt(data.picture_time_minutes, 10);
    data.picture_time_hours = parseInt(data.picture_time_hours, 10);
    if (data.picture_time_minutes + data.picture_time_hours * 60 === 0) {
      alert(
        "Picture time can't be 0, enter a valid Picture time in HHH:MM format"
      );
    } else {
      data.picture_time =
        data.picture_time_minutes + data.picture_time_hours * 60;
    }

    data.status_time_minutes = parseInt(data.status_time_minutes, 10);
    data.status_time_hours = parseInt(data.status_time_hours, 10);
    if (data.status_time_minutes + data.status_time_hours * 60 === 0) {
      alert(
        "Status time can't be 0, enter a valid Status time in HHH:MM format"
      );
    } else {
      data.status_time = data.status_time_minutes + data.status_time_hours * 60;
    }

    let url = `/redeye/api/endpoints/${data.id}/`;
    let type = "PUT";

    $.ajax({
      type: type,
      url: url,
      data: data,
      headers: { "X-CSRFToken": csrfToken },
      xhrFields: { withCredentials: true },
      dataType: "json",
    })
      .then((data, textStatus, jqXHR) => {
        refreshList();
        refreshSiteInfo(siteInfo?.id);
      })
      .fail((jqXHR, textStatus, errorThrown) => {
        console.error(`AJAX error: ${textStatus}`);
      });
  };

  return (
    <Modal isOpen={true} toggle={toggle}>
      <ModalHeader toggle={toggle} style={{ backgroundColor: "#d3d3d3" }}>
        Endpoint Configuration
      </ModalHeader>
      <form
        onSubmit={handleSubmit((data) => {
          console.log("Form submit triggered");
          handleEndpointSubmit(data);
        })}
      >
        <input type="hidden" {...register("id")} />
        <ModalBody>
          <FormGroup>
            <div className="flex items-center">
              <Label for="callback_time" className="w-1/2 mr-4">
                <b>Callback Time (HHH : MM)</b>
              </Label>
              <div className="flex items-center w-1/2">
                <Controller
                  name="callback_time_hours"
                  control={control}
                  defaultValue={(
                    Math.floor(siteInfo?.latest_endpoint?.callback_time / 60) ||
                    0
                  ).toString()}
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="number"
                      placeholder="HHH"
                      min="000"
                      max="999"
                      className="w-1/3 mx-2"
                    />
                  )}
                />
                <span className="w-1/3 ml-2">:</span>
                <Controller
                  name="callback_time_minutes"
                  control={control}
                  defaultValue={
                    Math.floor(
                      siteInfo?.latest_endpoint?.callback_time -
                        Math.floor(
                          siteInfo?.latest_endpoint?.callback_time / 60
                        ) *
                          60
                    ) || 0
                  }
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="number"
                      placeholder="MM"
                      min="00"
                      max="59"
                      className="w-1/3 ml-1 mr-2"
                    />
                  )}
                />
              </div>
            </div>
          </FormGroup>

          <FormGroup>
            <div className="flex items-center">
              <Label for="picture_time" className="w-1/2 mr-4">
                <b>Picture Time (HHH : MM)</b>
              </Label>
              <div className="flex items-center w-1/2">
                <Controller
                  name="picture_time_hours"
                  control={control}
                  defaultValue={(
                    Math.floor(siteInfo?.latest_endpoint?.picture_time / 60) ||
                    0
                  ).toString()}
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="number"
                      placeholder="HHH"
                      min="000"
                      max="999"
                      className="w-1/3 mx-2"
                    />
                  )}
                />
                <span className="w-1/3 ml-2">:</span>
                <Controller
                  name="picture_time_minutes"
                  control={control}
                  defaultValue={
                    Math.floor(
                      siteInfo?.latest_endpoint?.picture_time -
                        Math.floor(
                          siteInfo?.latest_endpoint?.picture_time / 60
                        ) *
                          60
                    ) || 1
                  }
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="number"
                      placeholder="MM"
                      min="00"
                      max="59"
                      className="w-1/3 ml-1 mr-2"
                    />
                  )}
                />
              </div>
            </div>
          </FormGroup>

          <FormGroup className="hidden">
            <div className="flex items-center">
              <Label for="status_time" className="w-1/2 mr-4">
                <b>Status Time (HHH : MM)</b>
              </Label>
              <div className="flex items-center w-1/2">
                <Controller
                  name="status_time_hours"
                  control={control}
                  defaultValue={(
                    Math.floor(siteInfo?.latest_endpoint?.status_time / 60) || 0
                  ).toString()}
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="number"
                      placeholder="HHH"
                      min="000"
                      max="999"
                      className="w-1/3 mx-2"
                    />
                  )}
                />
                <span className="w-1/3 ml-2">:</span>
                <Controller
                  name="status_time_minutes"
                  control={control}
                  defaultValue={
                    Math.floor(
                      siteInfo?.latest_endpoint?.status_time -
                        Math.floor(
                          siteInfo?.latest_endpoint?.status_time / 60
                        ) *
                          60
                    ) || 1
                  }
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="number"
                      placeholder="MM"
                      min="00"
                      max="59"
                      className="w-1/3 ml-1 mr-2"
                    />
                  )}
                />
              </div>
            </div>
          </FormGroup>

          <FormGroup>
            <div className="flex items-center">
              <Label for="maintenance_mode" className="w-1/2 mr-4">
                <b>Maintenance Mode</b>
              </Label>
              <div className="flex items-center w-1/2">
                <Controller
                  name="maintenance_mode"
                  control={control}
                  defaultValue={
                    siteInfo?.latest_endpoint?.maintenance_mode || false
                  }
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="checkbox"
                      defaultChecked={field.value}
                      placeholder="Maintenance Mode"
                      className=" mx-2"
                    />
                  )}
                />
              </div>
            </div>
          </FormGroup>

          <FormGroup className="hidden">
            <div className="flex items-center">
              <Label for="minimum_voltage" className="w-1/2 mr-4">
                <b>Minimum Voltage (V)</b>
              </Label>
              <div className="flex items-center w-1/2">
                <Controller
                  name="minimum_voltage"
                  control={control}
                  defaultValue={
                    siteInfo?.latest_endpoint?.minimum_voltage || 11.5
                  }
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="number"
                      placeholder="Minimum Voltage"
                      className="w-full mx-2"
                    />
                  )}
                />
              </div>
            </div>
          </FormGroup>

          <FormGroup className="hidden">
            <div className="flex items-center">
              <Label for="recharged_voltage" className="w-1/2 mr-4">
                <b>Recharged Voltage (V)</b>
              </Label>
              <div className="flex items-center w-1/2">
                <Controller
                  name="recharged_voltage"
                  control={control}
                  defaultValue={
                    siteInfo?.latest_endpoint?.recharged_voltage || 11.9
                  }
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="number"
                      placeholder="Recharged Voltage"
                      className="w-full mx-2"
                    />
                  )}
                />
              </div>
            </div>
          </FormGroup>
        </ModalBody>

        <ModalFooter>
          <Button color="danger" onClick={onCancel}>
            Cancel
          </Button>
          <Button color="primary" type="submit">
            Save
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default EndpointConfigModal;
