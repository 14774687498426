import React, { useState } from "react";

export const PreUserGroupContext = React.createContext();

export const PreUserGroupProvider = ({ children }) => {
  const [selectedUserGroupID, setSelectedUserGroupID] = useState();
  const [sort, setSort] = useState({ property: "", direction: "asc" });
  const [sortIndicator, setSortIndicator] = useState("");
  const [filterValue, setFilterValue] = useState("");

  return (
    <PreUserGroupContext.Provider
      value={{
        selectedUserGroupID,
        setSelectedUserGroupID,
        sort,
        setSort,
        sortIndicator,
        setSortIndicator,
        filterValue,
        setFilterValue,
      }}
    >
      {children}
    </PreUserGroupContext.Provider>
  );
};
