import React, { useContext } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { ModalContext } from "../Contexts/ModalContext";

const ImageModal = (props) => {
  const { imageUrl, imageName } = props;

  const { modal, toggleModal } = useContext(ModalContext);

  const style = {
    myModal: {
      maxWidth: "800px",
      width: "80%",
      height: "80%",
    },
  };

  return (
    <Modal isOpen={modal} toggle={toggleModal} style={style.myModal}>
      <ModalHeader toggle={toggleModal}>{imageName}</ModalHeader>
      <ModalBody>
        <img src={imageUrl} alt="" style={{ width: "100%" }} />
      </ModalBody>
    </Modal>
  );
};

export default ImageModal;
