import React, { useState, useEffect } from "react";

const SiteStatusCircle = (props) => {
  const { site, siteMaintenanceAccessibility } = props;

  let color = [
    "bg-white",
    "bg-red-500",
    "bg-amber-300",
    "bg-green-500",
    "bg-zinc-300",
  ];
  if (site.endpoint_status?.color) {
    color = site.endpoint_status?.color;
  }
  let status = site.endpoint_status.status;
  let message = site.endpoint_status.message;

  // maintenance mode ready status
  if (
    status != "running" &&
    status != "No Endpoint" &&
    String(siteMaintenanceAccessibility[site.id]).length > 10
  ) {
    color = "bg-violet-500";
    status = "Ready!";
    message = "Maintenance mode: Accessable";
  }

  return (
    <div data-tooltip={message} data-position="left center">
      <span className={`circle-icon ${color}`}></span>
      <span>&nbsp;</span>
      <span>{status}</span>
    </div>
  );
};

export default SiteStatusCircle;