import React, { useState, useContext, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { SiteGroupContext } from "../Contexts/SiteGroupContext";

const SiteModal = (props) => {
  const { initialItem, toggle, onSave, onDelete, onCancel } = props;
  const { siteGroupList, refreshGroupList } = useContext(SiteGroupContext);

  const { register, control, handleSubmit, formState, reset } = useForm({
    defaultValues: initialItem,
  });

  const [confirm, setConfirm] = useState(false);

  useEffect(() => {
    refreshGroupList();
  }, [refreshGroupList]);

  useEffect(() => {
    reset(initialItem);
  }, [initialItem, reset]);

  const handleConfirm = () => {
    onDelete(initialItem);
    setConfirm(false);
  };

  return (
    <Modal isOpen={true} toggle={toggle}>
      <ModalHeader toggle={toggle} style={{ backgroundColor: "#d3d3d3" }}>
        {initialItem && initialItem.id ? initialItem.name : "New Site"}
      </ModalHeader>
      <form onSubmit={handleSubmit((data) => onSave(data))}>
        <input type="hidden" {...register("id")} />
        <ModalBody>
          <FormGroup>
            <Label for="name">
              <b>Name</b>
            </Label>
            <Controller
              name="name"
              control={control}
              rules={{ required: "Name is required" }}
              render={({ field }) => (
                <Input
                  {...field}
                  invalid={Boolean(formState.errors.name)}
                  type="text"
                  placeholder="Name"
                />
              )}
            />
            {formState.errors.name && (
              <span>{formState.errors.name.message}</span>
            )}
          </FormGroup>
          <FormGroup>
            <Label for="group">
              <b>Site Group</b>
            </Label>
            <Controller
              name="group"
              control={control}
              rules={{ required: "Site Group is required" }} // Added required rule
              render={({ field }) => (
                <Input
                  {...field}
                  type="select"
                  placeholder="Select a site group"
                  invalid={Boolean(formState.errors.group)} // Show validation error
                >
                  <option value="">Select a site group</option>
                  {siteGroupList &&
                    siteGroupList.map((group) => (
                      <option key={group.id} value={group.id}>
                        {group.name}
                      </option>
                    ))}
                </Input>
              )}
            />
            {formState.errors.group && (
              <span>{formState.errors.group.message}</span>
            )}{" "}
            {/* Error message */}
          </FormGroup>
        </ModalBody>
        <ModalFooter className="d-flex flex-row justify-content-between">
          <Button color="danger" onClick={onCancel}>
            Cancel
          </Button>
          <Button color="success" type="submit">
            Save
          </Button>
          {initialItem && initialItem.id ? (
            <Button color="danger" onClick={() => setConfirm(true)}>
              Delete
            </Button>
          ) : null}
        </ModalFooter>
      </form>

      {confirm ? (
        <ConfirmationModal
          handleConfirm={handleConfirm}
          handleClose={() => setConfirm(false)}
        />
      ) : null}
    </Modal>
  );
};

const ConfirmationModal = (props) => {
  const { handleConfirm, handleClose } = props;
  return (
    <Modal isOpen={true} toggle={handleClose}>
      <ModalHeader toggle={handleClose}>Really delete site?</ModalHeader>
      <ModalBody className="d-flex flex-row justify-content-between">
        <Button color="success" onClick={handleClose}>
          Cancel
        </Button>
        <Button color="danger" onClick={handleConfirm}>
          Delete
        </Button>
      </ModalBody>
    </Modal>
  );
};

export default SiteModal;
