import React, { useEffect, useState, useContext } from "react";
import Users from "./Users.js";
import UserModal from "./UserModal.js";
import { Default } from "../../MediaQueries.js";
import { ModalContext } from "../Contexts/ModalContext";
import { UserListContext } from "../Contexts/UserListContext";
import { CompanyContext } from "../Contexts/CompanyContext";
import { getCookie } from "../Utilities/CookieUtils.js";
import { useToastContext } from "../Contexts/ToastContext.js";

const UserManagement = (props) => {
  const { user } = props;
  const { modal, toggleModal, openModal, modalComponent, handleCancel } =
    useContext(ModalContext);
  const { userList, refreshUserList } = useContext(UserListContext);
  const { userCompany, refreshCompanyList } = useContext(CompanyContext);
  const [csrfToken] = useState(getCookie("redeyeCSRFcookie"));
  const [isCompanyAdmin, setIsCompanyAdmin] = useState(false);
  const { showToast } = useToastContext();
  useEffect(() => {
    document.title = "Logical Endpoints";
    refreshUserList();
    refreshCompanyList();
  }, [refreshUserList]);

  useEffect(() => {
    const fetchUserData = () => {
      fetchUserCompanyData(user?.id);
    };

    const fetchUserCompanyData = (userId) => {
      const userCompanytemp = userCompany?.filter(
        (item) => item.user === userId
      );
      setIsCompanyAdmin(userCompanytemp?.some((item) => item.is_companyadmin));
    };

    fetchUserData();
  }, [userCompany]);

  return (
    <main>
      <Default>
        <div className="container-fluid">
          <div className="row gap-3">
            <div>
              {userList ? (
                <Users userList={userList} />
              ) : (
                <p>Loading user data...</p>
              )}
            </div>
            <div
              className="d-flex gap-1"
              style={{ transform: "translateY(-2rem)" }}
            >
              {(user?.is_superuser || isCompanyAdmin) && (
                <button
                  className="btn btn-primary text-xs py-0.5 px-1"
                  onClick={() => openModal("UserModal")}
                >
                  Create User
                </button>
              )}
            </div>
          </div>
        </div>
      </Default>
      {modal && modalComponent === "UserModal" ? (
        <UserModal
          toggle={toggleModal}
          onCancel={handleCancel}
          refreshUserList={refreshUserList} // Pass refreshUserList
        />
      ) : null}
    </main>
  );
};

export default UserManagement;
