import React, { createContext, useState } from "react";

export const SiteNavigationContext = createContext();

export const SiteNavigationProvider = ({ children }) => {
  const [highlightedSite, setHighlightedSite] = useState(0);
  const [expandedGroups, setExpandedGroups] = useState([]);

  return (
    <SiteNavigationContext.Provider
      value={{
        highlightedSite,
        setHighlightedSite,
        expandedGroups,
        setExpandedGroups,
      }}
    >
      {children}
    </SiteNavigationContext.Provider>
  );
};
