import React, { useContext, useState } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import $ from "jquery";

import { ModalContext } from "../Contexts/ModalContext";
import { getCookie } from "../Utilities/CookieUtils";
import { useToastContext } from "../Contexts/ToastContext";

const SiteImageModal = ({
  currentImagePreview,
  site,
}) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(currentImagePreview);

  // state which maintains the upload button's disabled status
  const [uploadDisabled, setUploadDisabled] = useState(true);
  const [csrfToken] = useState(getCookie("redeyeCSRFcookie"));

  const { modal, toggleModal, modalItem } = useContext(ModalContext);
  const { showToast } = useToastContext();

  // handler function which will run when the user selects the image from file system
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
      setImagePreview(URL.createObjectURL(file));
      setUploadDisabled(false);
    }
  };

  // function to handle the form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!selectedImage) {
      alert("Please select an image to upload.");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedImage);
    formData.append("site_id", site.id);

    $.ajax({
      type: "POST",
      url: "/redeye/api/accept_site_image/",
      data: formData,
      headers: { "X-CSRFToken": csrfToken },
      processData: false,
      contentType: false,
      xhrFields: { withCredentials: true },
      success: function () {
        showToast("Image uploaded successfully.");
        modalItem.setCurrentImage(URL.createObjectURL(selectedImage));
        toggleModal();
      },
      error: function (xhr, textStatus, errorThrown) {
        showToast("Error uploading the image:", errorThrown);
      },
    });
  };

  

  return (
    <Modal isOpen={modal} toggle={toggleModal} >
      <ModalHeader toggle={toggleModal}>
        {imagePreview ? "Change Site Image" : "Upload Site Image"}
      </ModalHeader>
      <ModalBody>
        <div className="p-8 bg-white rounded-lg shadow-lg">
          {imagePreview && (
            <div className="mb-4">
              <img
                src={imagePreview}
                alt="Image Preview"
                className="block w-auto h-4/5 rounded-lg shadow"
              />
            </div>
          )}
          <form onSubmit={handleSubmit} className="flex flex-col space-y-4">
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              className="block w-full text-sm text-gray-500"
            />
            <button
              type="submit"
              className={`py-2 px-4 rounded transition duration-200 ease-in-out ${
                uploadDisabled
                  ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                  : "bg-blue-500 text-white hover:bg-blue-600"
              }`}
              disabled={uploadDisabled}
            >
              Upload Image
            </button>
          </form>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default SiteImageModal;
