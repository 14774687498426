import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label,
  Table,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import $ from "jquery";

const AddSitegroupToCompany = (props) => {
  const {
    siteGroupList,
    companyList,
    toggle,
    onCancel,
    selectedCompany,
    refreshGroupList,
    toggleModal,
    csrfToken,
    refreshCompanyList,
  } = props;

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedSiteGroupInTable, setSelectedSiteGroupInTable] =
    useState(null);
  const [sortConfig, setSortConfig] = useState(null);

  const { control, handleSubmit, formState, setValue } = useForm();

  const handleAddSiteGroupToCompanySubmit = (item) => {
    toggleModal();

    const selectedCompanyObject = companyList.find(
      (company) => company.name === selectedCompany
    );

    const siteGroup = siteGroupList.find(
      (siteGroupList) => siteGroupList.id === parseInt(item.sitegroup, 10)
    );

    if (siteGroup && selectedCompanyObject) {
      // Check if the site group is already linked to a company
      if (siteGroup.company && siteGroup.company !== selectedCompanyObject.id) {
        const proceed = window.confirm(
          `Site group is already linked to a different company. Do you want to change this SiteGroup from company ${
            companyList.find((c) => c.id === siteGroup.company)?.name
          } to ${selectedCompany}?`
        );
        if (!proceed) {
          return;
        }
      }

      $.ajax({
        type: "PUT",
        url: `/redeye/api/site_group/${siteGroup.id}/`,
        data: {
          id: siteGroup.id,
          name: siteGroup.name,
          description: siteGroup.description,
          company: selectedCompanyObject.id,
        },
        headers: { "X-CSRFToken": csrfToken },
        xhrFields: { withCredentials: true },
        dataType: "json",
        success: function (data) {
          refreshGroupList();
          refreshCompanyList();
        },
      });
    }
  };

  const handleRowClick = (siteGroup) => {
    if (
      selectedSiteGroupInTable &&
      selectedSiteGroupInTable.id === siteGroup.id
    ) {
      setSelectedSiteGroupInTable(null);
      setValue("sitegroup", "");
      setSearchTerm("");
    } else {
      setSelectedSiteGroupInTable(siteGroup);
      setValue("sitegroup", siteGroup.id);
      setSearchTerm(siteGroup.name);
    }
  };

  const requestSort = (key) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  let sortedSiteGroupList = siteGroupList ? [...siteGroupList] : [];
  if (sortConfig !== null) {
    sortedSiteGroupList.sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? 1 : -1;
      }
      return 0;
    });
  }

  const selectedCompanyObject = companyList.find(
    (company) => company.name === selectedCompany
  );

  const selectedCompanySiteGroupSet =
    siteGroupList
      ?.filter((sg) => sg.company == selectedCompanyObject.id)
      .map((siteGroup) => siteGroup.id) ?? [];

  const filteredSiteGroupList =
    sortedSiteGroupList.length > 0
      ? sortedSiteGroupList.filter(
          (siteGroup) =>
            siteGroup.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
            !selectedCompanySiteGroupSet.includes(siteGroup.id)
        )
      : [];

  const style = {
    myModal: {
      maxWidth: "600px",
      width: "80%",
      height: "80%",
    },
  };

  return (
    <Modal isOpen={true} toggle={toggle} style={style.myModal}>
      <ModalHeader toggle={toggle} style={{ backgroundColor: "#d3d3d3" }}>
        Add Site Group to the Company
      </ModalHeader>
      <form
        onSubmit={handleSubmit((data) =>
          handleAddSiteGroupToCompanySubmit(data)
        )}
      >
        <ModalBody>
          <FormGroup>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Label for="company" style={{ width: "17%" }}>
                <b>Company : </b>
              </Label>
              <Controller
                name="company"
                control={control}
                defaultValue={selectedCompany}
                style={{ width: "200%" }}
                render={({ field }) => (
                  <Input
                    {...field}
                    type="input"
                    placeholder="Company"
                    disabled={true}
                    value={selectedCompany}
                  />
                )}
              />
            </div>
          </FormGroup>

          <FormGroup>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Label for="search" style={{ width: "20%" }}>
                <b>Search : </b>
              </Label>
              <Input
                type="text"
                style={{ width: "180%" }}
                name="search"
                id="search"
                placeholder="Search Site Group"
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  if (e.target.value === "") setSelectedSiteGroupInTable(null);
                }}
              />
            </div>
          </FormGroup>

          <div style={{ maxHeight: "300px", overflowY: "scroll" }}>
            <Table hover>
              <thead
                style={{
                  position: "sticky",
                  top: 0,
                  backgroundColor: "#fff",
                  borderTop: "2px solid #000",
                  borderBottom: "3px solid #000",
                  zIndex: 1,
                }}
              >
                <tr>
                  <th onClick={() => requestSort("name")}>
                    Site Group Name{" "}
                    {sortConfig?.key === "name"
                      ? sortConfig.direction === "ascending"
                        ? "↑"
                        : "↓"
                      : ""}
                  </th>
                  <th onClick={() => requestSort("description")}>
                    Description{" "}
                    {sortConfig?.key === "description"
                      ? sortConfig.direction === "ascending"
                        ? "↑"
                        : "↓"
                      : ""}
                  </th>
                  <th>Select</th>
                </tr>
              </thead>
              <tbody>
                {filteredSiteGroupList &&
                  filteredSiteGroupList.map((siteGroup) => (
                    <tr
                      key={siteGroup.id}
                      style={
                        selectedSiteGroupInTable?.id === siteGroup.id
                          ? { backgroundColor: "lightblue" }
                          : {}
                      }
                      onClick={() => handleRowClick(siteGroup)}
                    >
                      <td>{siteGroup.name}</td>
                      <td>{siteGroup.description}</td>
                      <td>
                        <Button
                          onMouseOver={(e) =>
                            (e.target.style.backgroundColor = "#00008B")
                          }
                          onMouseOut={(e) =>
                            (e.target.style.backgroundColor =
                              selectedSiteGroupInTable?.id === siteGroup.id
                                ? "#00008B"
                                : "")
                          }
                          onClick={() => handleRowClick(siteGroup)}
                        >
                          {selectedSiteGroupInTable?.id === siteGroup.id
                            ? "Selected"
                            : "Select"}
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
          {formState.errors.sitegroup && <span>This field is required</span>}
        </ModalBody>

        <ModalFooter className="d-flex flex-row justify-content-between">
          <Button color="danger" onClick={onCancel}>
            Cancel
          </Button>
          <Button color="success" type="submit">
            Save
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default AddSitegroupToCompany;
