import L from "leaflet";

const SiteIcon = (siteInfo, highlightedSite) => {
  let color = "white";
  let strokeWidth = "1";
  let halo = "black";
  let iconSize = [24, 56];
  let viewBox = "-10 0 22 12";

  if (siteInfo.missed_callbacks === 0) {
    color = "lime";
    halo = "black";
    if (siteInfo.id === highlightedSite) {
      color = "green";
      strokeWidth = "3";
      halo = "#02cef7";
      iconSize = [72, 168];
      viewBox = "-8 -1 17 22";
    }
  } else if (siteInfo.missed_callbacks === 1) {
    color = "orange";
    halo = "black";
    if (siteInfo.id === highlightedSite) {
      color = "brown";
      strokeWidth = "3";
      halo = "#02cef7";
      iconSize = [72, 168];
      viewBox = "-8 -1 17 22";
    }
  } else if (siteInfo.missed_callbacks > 1) {
    color = "red";
    halo = "black";
    if (siteInfo.id === highlightedSite) {
      color = "red";
      strokeWidth = "3";
      halo = "#02cef7";
      iconSize = [72, 168];
      viewBox = "-8 -1 17 22";
    }
  }

  return new L.divIcon({
    className: "site-pin",
    iconSize: iconSize,
    iconAnchor: [10, 60],
    labelAnchor: [0, 0],
    popupAnchor: [0, -55],
    html: `
		<svg xmlns="http://www.w3.org/2000/svg"
		     width="24" height="56"
		     viewBox="${viewBox}">
		  <path id="Selection"
		        fill="${color}" stroke="${halo}" stroke-width="${strokeWidth}"
		        d="M -7.45,6.45
		           C -6.14,12.70 -3.39,25.33 -1.95,31.07
		             -0.70,25.20 2.24,11.89 3.55,6.45
		             3.55,6.45 -7.45,6.45 -7.45,6.45 Z
		           M -8.00,4.82
		           C -8.00,4.82 -8.00,-6.91 -8.00,-6.91
		             -8.00,-6.91 5.64,-7.00 5.64,-7.00
		             5.64,-7.00 5.73,-4.00 5.73,-4.00
		             5.73,-4.00 9.91,-7.09 9.91,-7.09
		             9.91,-7.09 9.91,4.91 9.91,4.91
		             9.91,4.91 5.91,1.82 5.91,1.82
		             5.91,1.82 5.82,5.00 5.82,5.00
		             5.82,5.00 -8.00,4.82 -8.00,4.82 Z" />
		</svg>
		`,
  });
};

export { SiteIcon };
