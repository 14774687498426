import React, { useState, createContext, useCallback } from "react";

import $ from "jquery";

export const UserGroupContext = createContext();

export const UserGroupProvider = (props) => {
  const [userGroupList, setUserGroupList] = useState(null);

  const refreshUserGroupList = useCallback(
    (lazy = false) => {
      if (!lazy || !userGroupList || userGroupList.length === 0) {
        $.get("/redeye/api/user_group/").done(function (data) {
          if (JSON.stringify(data) !== JSON.stringify(userGroupList)) {
            setUserGroupList(data);
          }
        });
      }
    },
    [userGroupList, setUserGroupList],
  );

  return (
    <UserGroupContext.Provider value={{ userGroupList, refreshUserGroupList }}>
      {props.children}
    </UserGroupContext.Provider>
  );
};
