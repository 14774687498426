import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label,
  Table,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import $ from "jquery";

const AddUserToCompany = (props) => {
  const {
    userList,
    adminUsers,
    normalUsers,
    companyList,
    toggle,
    onCancel,
    selectedCompany,
    refreshUserList,
    toggleModal,
    csrfToken,
    refreshCompanyList,
    onUserAdded, // New prop
  } = props;
  const nonSuperUsers = userList.filter(
    (user) =>
      !user.is_superuser &&
      !adminUsers.some((admin) => admin.id === user.id) &&
      !normalUsers.some((normal) => normal.id === user.id)
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedUserInTable, setSelectedUserInTable] = useState(null);
  const [sortConfig, setSortConfig] = useState(null);

  const { control, handleSubmit, formState, setValue } = useForm();

  const handleAddUserToCompanySubmit = (item) => {
    toggleModal();

    const selectedCompanyObject = companyList.find(
      (company) => company.name === selectedCompany
    );

    const user = nonSuperUsers.find(
      (nonSuperUsers) => nonSuperUsers.id === parseInt(item.user, 10)
    );

    if (user && selectedCompanyObject) {
      $.ajax({
        type: "POST",
        url: `/redeye/api/user_company/`,
        data: {
          user: user.id,
          company: selectedCompanyObject.id,
          is_companyadmin: item.is_companyadmin,
        },
        headers: { "X-CSRFToken": csrfToken },
        xhrFields: { withCredentials: true },
        dataType: "json",
        success: function (data) {
          refreshUserList();
          refreshCompanyList();
          onUserAdded(); // Notify parent component
        },
        error: function (xhr) {
          console.error("Error:", xhr.responseText);
        },
      });
    }
  };

  const handleRowClick = (user) => {
    if (selectedUserInTable && selectedUserInTable.id === user.id) {
      setSelectedUserInTable(null);
      setValue("user", "");
      setSearchTerm("");
    } else {
      setSelectedUserInTable(user);
      setValue("user", user.id);
      setSearchTerm(user.username);
    }
  };

  const requestSort = (key) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  let sortedUserList = nonSuperUsers ? [...nonSuperUsers] : [];
  if (sortConfig !== null) {
    sortedUserList.sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? 1 : -1;
      }
      return 0;
    });
  }

  const selectedCompanyObject = companyList.find(
    (company) => company.name === selectedCompany
  );

  const selectedCompanyUserSet = selectedCompanyObject
    ? (selectedCompanyObject.user_set || []).map((user) => user.id)
    : [];

  const filteredUserList =
    sortedUserList.length > 0
      ? sortedUserList.filter(
          (user) =>
            (user.username || "")
              .toLowerCase()
              .includes((searchTerm || "").toLowerCase()) &&
            !selectedCompanyUserSet.includes(user.id)
        )
      : [];
  const style = {
    myModal: {
      maxWidth: "600px",
      width: "80%",
      height: "80%",
    },
  };

  return (
    <Modal isOpen={true} toggle={toggle} style={style.myModal}>
      <ModalHeader toggle={toggle} style={{ backgroundColor: "#d3d3d3" }}>
        Add User to the Company
      </ModalHeader>
      <form
        onSubmit={handleSubmit((data) => handleAddUserToCompanySubmit(data))}
      >
        <ModalBody>
          <FormGroup>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Label for="company" style={{ width: "17%" }}>
                <b>Company : </b>
              </Label>
              <Controller
                name="company"
                control={control}
                defaultValue={selectedCompany}
                style={{ width: "200%" }}
                render={({ field }) => (
                  <Input
                    {...field}
                    type="input"
                    placeholder="Company"
                    disabled={true}
                    value={selectedCompany}
                  />
                )}
              />
            </div>
          </FormGroup>

          <FormGroup>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Label for="search" style={{ width: "20%" }}>
                <b>Search : </b>
              </Label>
              <Input
                type="text"
                style={{ width: "180%" }}
                name="search"
                id="search"
                placeholder="Search User"
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  if (e.target.value === "") setSelectedUserInTable(null);
                }}
              />
            </div>
          </FormGroup>

          <FormGroup>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Label for="is_companyadmin" style={{ width: "20%" }}>
                <b>Is Company Admin : </b>
              </Label>
              <Controller
                name="is_companyadmin"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <Input
                    {...field}
                    type="checkbox"
                    style={{ width: "20%" }}
                    id="is_companyadmin"
                  />
                )}
              />
            </div>
          </FormGroup>

          <div style={{ maxHeight: "300px", overflowY: "scroll" }}>
            <Table hover>
              <thead
                style={{
                  position: "sticky",
                  top: 0,
                  backgroundColor: "#fff",
                  borderTop: "2px solid #000",
                  borderBottom: "3px solid #000",
                  zIndex: 1,
                }}
              >
                <tr>
                  <th onClick={() => requestSort("username")}>
                    User Name{" "}
                    {sortConfig?.key === "username"
                      ? sortConfig.direction === "ascending"
                        ? "↑"
                        : "↓"
                      : ""}
                  </th>
                  <th onClick={() => requestSort("email")}>
                    Email{" "}
                    {sortConfig?.key === "email"
                      ? sortConfig.direction === "ascending"
                        ? "↑"
                        : "↓"
                      : ""}
                  </th>
                  <th>Select</th>
                </tr>
              </thead>
              <tbody>
                {filteredUserList &&
                  filteredUserList.map((user) => (
                    <tr
                      key={user.id}
                      style={
                        selectedUserInTable?.id === user.id
                          ? { backgroundColor: "lightblue" }
                          : {}
                      }
                      onClick={() => handleRowClick(user)}
                    >
                      <td>{user.username}</td>
                      <td>{user.email}</td>
                      <td>
                        <Button
                          onMouseOver={(e) =>
                            (e.target.style.backgroundColor = "#00008B")
                          }
                          onMouseOut={(e) =>
                            (e.target.style.backgroundColor =
                              selectedUserInTable?.id === user.id
                                ? "#00008B"
                                : "")
                          }
                          onClick={() => handleRowClick(user)}
                        >
                          {selectedUserInTable?.id === user.id
                            ? "Selected"
                            : "Select"}
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
          {formState.errors.user && <span>This field is required</span>}
        </ModalBody>

        <ModalFooter className="d-flex flex-row justify-content-between">
          <Button color="danger" onClick={onCancel}>
            Cancel
          </Button>
          <Button color="success" type="submit">
            Save
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default AddUserToCompany;
