import React, { useContext, useState, useEffect } from "react";
import { Button, Input } from "reactstrap";
import AddPermToGroup from "./AddPermToGroup.js";
import AddUserToGroup from "../Users/AddUserToGroup";
import ListXButton from "../Utilities/ListXButton.js";
import $ from "jquery";
import { ModalContext } from "../Contexts/ModalContext";
import { UserGroupContext } from "../Contexts/UserGroupContext";
import { UserListContext } from "../Contexts/UserListContext";
import { getCookie } from "../Utilities/CookieUtils.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers } from "@fortawesome/free-solid-svg-icons";

import { Table } from "reactstrap";

import { faFilter, faRedoAlt } from "@fortawesome/free-solid-svg-icons";
import ScrollbarStyles from "../Utilities/ScrollbarStyles.js";

const UserGroupDetails = (props) => {
  const { userList, selectedGroup } = props;
  const { modal, toggleModal, openModal, modalComponent, handleCancel } =
    useContext(ModalContext);
  const { refreshUserGroupList } = useContext(UserGroupContext);
  const { handleUpdateUserGroupMembership, refreshUserList } =
    useContext(UserListContext);

  const [groupMembersFilter, setGroupMembersFilter] = React.useState("");
  const [groupPermissionsFilter, setGroupPermissionsFilter] =
    React.useState("");

  const usersInGroup = userList.filter((user) =>
    user.groups.includes(selectedGroup.id)
  );

  const removeUserFromGroup = (user_id, group_id) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      handleUpdateUserGroupMembership(user_id, group_id, false);
    }
  };

  const updateGroupPermission = (group, data) => {
    if (
      window.confirm(
        "Are you sure you want to update this group's permissions?"
      )
    ) {
      const csrfToken = getCookie("redeyeCSRFcookie");
      $.ajax({
        type: "PUT",
        url: `/redeye/api/update_user_group_site_group_permission/${group.id}/`,
        data: data,
        headers: { "X-CSRFToken": csrfToken },
        xhrFields: { withCredentials: true },
        dataType: "json",
        success: function (data) {
          console.log("Permission updated successfully.");
          refreshUserGroupList();
        },
        error: function (xhr, textStatus, errorThrown) {
          console.log("Error updating permission:", errorThrown);
        },
      });
    }
  };

  const onPermChange = (selectedGroup, perm_id, perm) => {
    if (perm === "can_view") {
      updateGroupPermission(selectedGroup, {
        site_group: perm_id,
        can_view: true,
        can_edit: false,
      });
    } else if (perm === "can_edit") {
      updateGroupPermission(selectedGroup, {
        site_group: perm_id,
        can_edit: true,
      });
    }
  };

  if (!selectedGroup) {
    return <div>Select a user group to see the details!</div>;
  }

  const filteredUsersInGroup = usersInGroup.filter((user) =>
    `${user.first_name} ${user.last_name}`
      .toLowerCase()
      .includes(groupMembersFilter.toLowerCase())
  );

  const filteredGroupPermissions = selectedGroup.group_permissions.filter(
    (perm) =>
      perm.name.toLowerCase().includes(groupPermissionsFilter.toLowerCase())
  );

  const scrollableTableStyle = {
    maxHeight: "calc(3 * 3.5rem + 3 * 3px)",
    overflowY: "auto",
    // border: '1px solid #ccc'
  };

  const stickyHeaderStyle = {
    position: "sticky",
    top: "0",
    backgroundColor: "#f2f2f2",
    zIndex: "10",
  };

  return (
    <>
      <ScrollbarStyles />
      <div className="user-group-list">
        <div
          style={{
            marginRight: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ width: "100%" }}>
            <h2
              style={{
                backgroundColor: "lightblue",
                padding: "6px",
                borderRadius: "6px",
              }}
            >
              {selectedGroup.name}
            </h2>
            <span>
              <b>Created on: </b>
              {selectedGroup.extended_group
                ? new Date(
                    selectedGroup.extended_group.created_on
                  ).toLocaleString()
                : "Unknown"}
            </span>
            <br />
            <span>
              <b>Created by: </b>
              {selectedGroup.extended_group && selectedGroup.extended_group.user
                ? selectedGroup.extended_group.user.username
                : "Unknown"}
            </span>
          </div>
          <div
            style={{
              padding: "6px",
              border: "2px solid #000",
              marginLeft: "5px",
              marginTop: "5px",
            }}
          >
            <FontAwesomeIcon icon={faUsers} size="7x" />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "darkgray",
            padding: "4px",
            borderRadius: "6px",
            marginTop: "5px",
          }}
        >
          <h5
            style={{
              fontSize: "17px",
              margin: "0 10px 0 0",
            }}
          >
            <u>Group Members</u>
          </h5>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              width: "85.7%",
            }}
          >
            <FontAwesomeIcon
              icon={faFilter}
              size="lg"
              style={{ marginRight: "10px" }}
            />
            <Input
              type="text"
              value={groupMembersFilter}
              onChange={(e) => setGroupMembersFilter(e.target.value)}
              placeholder="Search..."
              style={{
                height: "30px",
                fontSize: "14px",
                width: "20%",
                backgroundColor: "lightgrey",
              }}
            />
          </div>
        </div>

        <p>
          A user can be assigned to a user group and will inherent access to
          view and update any site objects that group can view or edit.
        </p>
        <div className="scrollable-table" style={scrollableTableStyle}>
          <Table>
            <thead style={stickyHeaderStyle}>
              <tr>
                <th>User Name</th>
                <th>Job Title</th>
                <th>Division</th>
                <th>Field Office</th>
                <th>Remove</th>
              </tr>
            </thead>
            <tbody>
              {filteredUsersInGroup.length > 0 ? (
                filteredUsersInGroup.map((user) => {
                  // console.log(user.extended_user);
                  return (
                    <tr key={user.id}>
                      <td>
                        {user && user.first_name && user.last_name
                          ? `${user.first_name} ${user.last_name}`
                          : user && user.username
                          ? user.username
                          : "Unknown User"}
                      </td>
                      <td>
                        {user.extended_user
                          ? user.extended_user.job_title
                          : "-"}
                      </td>
                      <td>
                        {user.extended_user ? user.extended_user.division : "-"}
                      </td>
                      <td>
                        {user.extended_user
                          ? user.extended_user.field_office
                          : "-"}
                      </td>
                      <td>
                        <ListXButton
                          onClick={() =>
                            removeUserFromGroup(user.id, selectedGroup.id)
                          }
                        />
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={5}>No User associated</td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>
      <Button color="primary" onClick={() => openModal("AddUserToGroup")}>
        Add Users
      </Button>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          backgroundColor: "darkgray",
          padding: "4px",
          borderRadius: "6px",
          marginTop: "5px",
        }}
      >
        <h5
          style={{
            fontSize: "17px",
            margin: "0 10px 0 0",
          }}
        >
          <u>Group Permissions</u>
        </h5>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            width: "83.7%",
          }}
        >
          <FontAwesomeIcon
            icon={faFilter}
            size="lg"
            style={{ marginRight: "10px" }}
          />
          <Input
            type="text"
            value={groupPermissionsFilter}
            onChange={(e) => setGroupPermissionsFilter(e.target.value)}
            placeholder="Search..."
            style={{
              height: "30px",
              fontSize: "14px",
              width: "20%",
              backgroundColor: "lightgrey",
            }}
          />
        </div>
      </div>

      <p>
        Members of a user group will inherit access to view and update the site
        objects this group has access to view and edit.
      </p>
      {selectedGroup &&
      selectedGroup.group_permissions &&
      selectedGroup.group_permissions.length > 0 ? (
        <div className="scrollable-table" style={scrollableTableStyle}>
          <Table>
            <thead style={stickyHeaderStyle}>
              <tr>
                <th>Name</th>
                <th>Permission</th>
                <th>Description</th>
                <th>Remove</th>
              </tr>
            </thead>
            <tbody>
              {filteredGroupPermissions.length > 0 ? (
                filteredGroupPermissions.map((perm) => (
                  <tr key={perm.id}>
                    <td>{perm.name}</td>
                    {perm.can_view || perm.can_edit ? (
                      <td>
                        <Input
                          type="select"
                          defaultValue={perm.can_edit ? "can_edit" : "can_view"}
                          onChange={(e) =>
                            onPermChange(selectedGroup, perm.id, e.target.value)
                          }
                        >
                          <option key={"can_view"} value={"can_view"}>
                            Can view
                          </option>
                          <option key={"can_edit"} value={"can_edit"}>
                            Can view and edit
                          </option>
                        </Input>
                      </td>
                    ) : (
                      <td>-</td>
                    )}
                    <td>{perm.description}</td>
                    <td>
                      {perm.can_view || perm.can_edit ? (
                        <ListXButton
                          onClick={() =>
                            updateGroupPermission(selectedGroup, {
                              site_group: perm.id,
                              can_view: false,
                              can_edit: false,
                            })
                          }
                        />
                      ) : null}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={4}>No permissions found based on the filter</td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      ) : (
        <p>Group has no additional permissions</p>
      )}
      <Button color="primary" onClick={() => openModal("AddPermToGroup")}>
        Add Perm To Group
      </Button>
      {modal && modalComponent === "AddUserToGroup" ? (
        <AddUserToGroup
          selectedUser={null}
          selectedGroup={selectedGroup}
          onCancel={handleCancel}
          toggle={toggleModal}
        />
      ) : null}
      {modal && modalComponent === "AddPermToGroup" ? (
        <AddPermToGroup
          groupInfo={selectedGroup}
          updateGroupPermission={updateGroupPermission}
          toggle={toggleModal}
          onCancel={handleCancel}
        />
      ) : null}
    </>
  );
};

export default UserGroupDetails;
