import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Input,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";

const DetachEndpointModal = (props) => {
  const { initialItem, toggle, onSave } = props;

  const [confirm, setConfirm] = useState(false);

  // const { control, handleSubmit, watch } = useForm();

  const { control, handleSubmit } = useForm();

  const onFormSubmit = (data) => {
    if (data.confirm === "detach") {
      onSave(initialItem.latest_endpoint);
    }
  };

  // 	useEffect(() => {
  //     const subscription = watch(handleSubmit(onFormSubmit));
  //     return () => subscription.unsubscribe();
  // 	}, [handleSubmit, watch]);

  return (
    <Modal isOpen={true} toggle={toggle}>
      <ModalHeader toggle={toggle} style={{ backgroundColor: "#d3d3d3" }}>
        Detach endpoint
      </ModalHeader>
      <ModalBody className="d-flex flex-column">
        <div>
          If this site's endpoint needs to be replaced, it must first be
          detached. Detaching an endpoint is an <b>irreversible</b> operation
          and a new endpoint must be registered with the site in order to resume
          operation.
        </div>
        {confirm ? (
          <form onSubmit={handleSubmit((data) => onFormSubmit(data))}>
            <FormGroup>
              <Controller
                name="confirm"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input {...field} type="text" placeholder="type 'detach'" />
                )}
              />
            </FormGroup>
          </form>
        ) : (
          <Button color="danger" onClick={() => setConfirm(true)}>
            Detach
          </Button>
        )}
      </ModalBody>
    </Modal>
  );
};

export default DetachEndpointModal;
