export function formatLatLon(latitude, longitude) {
  const lat_dir = latitude > 0 ? "N" : "S";
  const lon_dir = longitude > 0 ? "E" : "W";
  return `(${formatDecimalDegree(latitude)} ${lat_dir}, ${formatDecimalDegree(
    longitude,
  )} ${lon_dir})`;
}

export function formatDecimalDegree(decimal) {
  const degrees = Math.abs(decimal | 0); // truncate to get degrees
  const fractional = Math.abs(decimal) - degrees;
  const min = (fractional * 60) | 0;
  const sec = (fractional * 3600 - min * 60) | 0;
  return `${degrees}° ${min}' ${sec}"`;
}

export function formatOperationLogLevel(level) {
  switch (level) {
    case "D":
      return "Debug";
    case "I":
      return "Success";
    case "W":
      return "Warning";
    case "E":
      return "Error";
    case "F":
      return "Fatal error";
    default:
      return null;
  }
}
