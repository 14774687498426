import React from "react";
import { Button } from "reactstrap";

function DownloadCSVButton({ endpointId }) {
  return (
    <a href={`/redeye/api/download_csv/${endpointId}/`} download>
      <Button>Download CSV Files</Button>
    </a>
  );
}

export default DownloadCSVButton;
