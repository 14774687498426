import React, { useEffect, useState, useContext } from "react";
import SiteGroupModal from "./SiteGroupModal.js";
import SiteGroups from "./SiteGroups.js";
import $ from "jquery";
import { Default } from "../../MediaQueries.js";
import { ModalContext } from "../Contexts/ModalContext";
import { SiteContext } from "../Contexts/SiteContext";
import { SiteGroupContext } from "../Contexts/SiteGroupContext";
import { getCookie } from "../Utilities/CookieUtils.js";

const SiteGroupManagement = (props) => {
  const [csrfToken] = useState(getCookie("redeyeCSRFcookie"));
  const { modal, toggleModal, openModal, modalComponent } =
    useContext(ModalContext);
  const { refreshList } = useContext(SiteContext);
  const { siteGroupList, refreshGroupList } = useContext(SiteGroupContext);
  
  useEffect(() => {
    document.title = "Logical Endpoints";
    refreshList(true);
    refreshGroupList();
  }, [refreshList, refreshGroupList]);

  const handleSiteGroupSubmit = (item) => {
    toggleModal();
    let url = "/redeye/api/site_group/";
    let type = "POST";

    $.ajax({
      type: type,
      url: url,
      data: item,
      headers: { "X-CSRFToken": csrfToken },
      xhrFields: { withCredentials: true },
      dataType: "json",
      success: function (data) {
        refreshGroupList();
      },
    });
  };

  const handleDelete = (item) => {
    toggleModal();
    $.ajax({
      type: "DELETE",
      url: `/redeye/api/site_group/${item.id}/`,
      data: item,
      headers: { "X-CSRFToken": csrfToken },
      xhrFields: { withCredentials: true },
      dataType: "json",
      success: function (data) {
        refreshGroupList();
      },
    });
  };

  return (
    <main>
      <Default>
        <div className="container-fluid">
          <div className="row">
            <SiteGroups siteGroupList={siteGroupList} />
            <div style={{ width: "10rem", marginTop: "-2rem" }}>
              <button
                className="btn btn-primary"
                onClick={() => {
                  openModal("SiteGroupModal");
                }}
                style={{ width: "10rem", transform: "translateY(-2rem)" }}
              >
                Create Site Group
              </button>
            </div>
          </div>
        </div>
      </Default>
      {modal && modalComponent === "SiteGroupModal" ? (
        <SiteGroupModal
          toggle={toggleModal}
          onSave={handleSiteGroupSubmit}
          onDelete={handleDelete}
        />
      ) : null}
    </main>
  );
};

export default SiteGroupManagement;
