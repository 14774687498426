import React from "react";

const ScrollbarStyles = () => (
  <style>
    {`
      .scrollable-table::-webkit-scrollbar {
          width: 6px;
      }

      .scrollable-table::-webkit-scrollbar-track {
          background: #f2f2f2;
      }

      .scrollable-table::-webkit-scrollbar-thumb {
          background: #888;
      }

      .scrollable-table::-webkit-scrollbar-thumb:hover {
          background: #555;
      }

      .scrollable-table {
          scrollbar-width: thin;
          scrollbar-color: #888 #f2f2f2;
      }

            .scrollable-table::-webkit-scrollbar {
          width: 6px;
      }

      .scrollable-table::-webkit-scrollbar-track {
          background: #f2f2f2;
      }

      .scrollable-table::-webkit-scrollbar-thumb {
          background: #888;
      }

      .scrollable-table::-webkit-scrollbar-thumb:hover {
          background: #555;
      }

      .scrollable-table {
          scrollbar-width: thin;
          scrollbar-color: #888 #f2f2f2;
      }
      
    `}
  </style>
);

export default ScrollbarStyles;
