import React, { useState, useEffect, useContext } from "react";
import { Default } from "../../MediaQueries.js";
import { Form, FormGroup, Input, Label } from "reactstrap";
import SiteGroupDetails from "./SiteGroupDetails.js";
import { CompanyContext } from "../Contexts/CompanyContext.js";
import { PreSiteGroupContext } from "../Contexts/PreSiteGroupContext";
import StyledTableHeader from "../Utilities/StyledTableHeader.js";
import $ from "jquery";

const SiteGroups = (props) => {
  const { siteGroupList } = props;
  const [selectedSearchField, setSelectedSearchField] = useState("name");
  const [companyData, setCompanyData] = useState({});
  const { userCompany } = useContext(CompanyContext);

  const {
    sort,
    setSort,
    filterValue,
    setFilterValue,
    siteGroupInfo,
    setsiteGroupInfo,
    selectedGroup,
    setSelectedGroup,
  } = useContext(PreSiteGroupContext);

  useEffect(() => {
    if (userCompany && userCompany.length) {
      const companyMap = {};
      userCompany.forEach((company) => {
        companyMap[company.id] = company.name;
      });
      setCompanyData(companyMap);
    }
  }, [userCompany]);

  if (!siteGroupList) {
    return null;
  }

  const handleGroupClick = (group) => {
    setSelectedGroup(group);
  };

  const handleGroupDelete = () => {
    setSelectedGroup(null); // Clear selected group
    setsiteGroupInfo(null); // Clear site group info
  };

  const siteGroupListStyle = {
    maxHeight: "80vh",
    overflow: "auto",
    display: "inline-block",
    width: "71vh",
  };

  const rightHandStyle = {
    backgroundColor: "rgb(242, 242, 242)",
    borderLeft: "2px solid #000",
    paddingLeft: "10px",
    marginTop: "0.3rem",
    height: "79vh",
    left: "2%",
    overflow: "auto",
  };

  const tableBodyWrapperStyle = {
    maxHeight: "29rem",
    overflowY: "scroll",
    scrollbarWidth: "thin",
    display: "block",
    maxWidth: "45rem",
  };

  const scrollbarStyle = {
    scrollbarWidth: "thin",
    scrollbarColor: "#5683af #f5f5f5",
  };

  const searchFields = [
    { label: "Name", value: "name" },
    { label: "Number of Sites", value: "num_site" },
    { label: "Company", value: "company" },
  ];

  const filteredSiteGroups = siteGroupList
    .filter(
      (item) =>
        item[selectedSearchField] &&
        String(item[selectedSearchField])
          .toLowerCase()
          .includes(filterValue.toLowerCase())
    )
    .sort((a, b) => {
      const valueA = a[sort.property];
      const valueB = b[sort.property];
      if (sort.direction === "asc") {
        return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
      }
      return valueA > valueB ? -1 : valueA < valueB ? 1 : 0;
    });

  const columns = [
    { key: "name", header: "Name", width: "40%" },
    { key: "num_site", header: "Number of sites", width: "30%" },
    { key: "company", header: "Company", width: "30%" },
  ];

  return (
    <div className="row">
      <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
        <Form className="mb-3 w-100">
          <FormGroup style={{ display: "flex", alignItems: "center" }}>
            <Label
              for="searchTerm"
              style={{
                marginRight: "0.5rem",
                fontFamily: "'Orbitron', sans-serif",
                fontWeight: "bold",
                fontSize: "1rem",
                letterSpacing: "0.1rem",
                color: "#1a3c71",
              }}
            >
              Search:
            </Label>
            <Input
              type="text"
              name="searchTerm"
              id="searchTerm"
              placeholder="Search..."
              value={filterValue}
              onChange={(e) => setFilterValue(e.target.value)}
              style={{ backgroundColor: "#f2f2f2" }}
            />
            <Input
              type="select"
              name="searchField"
              id="searchField"
              value={selectedSearchField}
              onChange={(e) => setSelectedSearchField(e.target.value)}
              style={{ marginLeft: "1rem", backgroundColor: "#f2f2f2" }}
            >
              {searchFields.map((field) => (
                <option key={field.value} value={field.value}>
                  {field.label}
                </option>
              ))}
            </Input>
          </FormGroup>
        </Form>
        <div className="w-100 overflow-auto" style={{ maxHeight: "80vh" }}>
          <StyledTableHeader columns={columns} sort={sort} setSort={setSort} />
          <div
            className="table-responsive"
            style={{ maxHeight: "29rem", overflowY: "scroll" }}
          >
            <table className="table">
              <tbody className="overflow-auto">
                {filteredSiteGroups.length ? (
                  <>
                    {filteredSiteGroups.map((item) => (
                      <tr
                        key={item.id}
                        onClick={() => {
                          setsiteGroupInfo(item);
                          handleGroupClick(item);
                        }}
                        className="box"
                        style={{
                          backgroundColor:
                            siteGroupInfo && siteGroupInfo.id === item.id
                              ? "#6c757d"
                              : "inherit",
                          color:
                            siteGroupInfo && siteGroupInfo.id === item.id
                              ? "white"
                              : "inherit",
                        }}
                      >
                        {columns.map((column) => (
                          <td
                            style={{
                              width: column.width,
                            }}
                            key={column.key}
                          >
                            {column.key === "company"
                              ? companyData[item.company]
                              : item[column.key]}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </>
                ) : (
                  <tr>
                    <td
                      colSpan={columns.length}
                      style={{ textAlign: "center" }}
                    >
                      Site Group Not found!
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div
        className="col-lg-8 col-md-6 col-sm-12 position-relative"
        style={rightHandStyle}
      >
        {siteGroupInfo && (
          <Default>
            <SiteGroupDetails
              selectedGroup={selectedGroup}
              onGroupDelete={handleGroupDelete}
              siteGroupList={siteGroupList}
            />
          </Default>
        )}
        {!siteGroupInfo && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <span>Select a site group to see the details!</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default SiteGroups;
