import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCloudDownloadAlt,
  faImage,
  faFileAlt,
  faTools,
  faWrench,
} from "@fortawesome/free-solid-svg-icons";

const stickyModalHeader = {
  position: "sticky",
  top: "0",
  zIndex: "1055",
  background: "#d3d3d3",
};

const levelToColorDict = {
  D: "text-secondary",
  I: "text-success",
  W: "text-warning",
  E: "text-danger",
  F: "text-danger",
};

const processIconDict = {
  accept_logs: faFileAlt,
  accept_files: faImage,
  send_config: faCloudDownloadAlt,
};

const listStyle = {
  maxHeight: "80vh",
  overflow: "auto",
};

const style = {
  myModal: {
    maxWidth: "550px",
    width: "80%",
    height: "80%",
  },
};

const CallbackLog = (props) => {
  const { siteInfo, toggle } = props;

  const checkIcon = (status) => {
    switch (status) {
      case "I":
        return (
          <FontAwesomeIcon
            className={levelToColorDict[status]}
            icon={faCheck}
          />
        );
      case "W":
      case "D":
        return (
          <FontAwesomeIcon
            className={levelToColorDict[status]}
            icon={faCheck}
          />
        );
      case "E":
      case "F":
        return (
          <FontAwesomeIcon
            className={levelToColorDict[status]}
            icon={faCheck}
          />
        );
      default:
        return null;
    }
  };

  const getLogByProcess = (logs, process) => {
    const log = logs.find((log) => log.process === process);
    return log ? log.level : null;
  };

  const getLatestMaintenanceEnterLog = (operationLogs) => {
    const enterLogs = operationLogs.filter(
      (log) => log.process === "report_maintenance_enter",
    );
    console.log(enterLogs);
    return enterLogs.length > 0 ? enterLogs[0] : null;
  };

  const getLatestMaintenanceExitLog = (operationLogs) => {
    const exitLogs = operationLogs.filter(
      (log) => log.process === "report_maintenance_exit",
    );
    return exitLogs.length > 0 ? exitLogs[0] : null;
  };

  return (
    <Modal isOpen={true} toggle={toggle} style={style.myModal}>
      {siteInfo ? (
        <>
          <ModalHeader toggle={toggle} style={stickyModalHeader}>
            {siteInfo.name}
          </ModalHeader>
          <ModalBody className="d-flex flex-column" style={listStyle}>
            <h6>Server transaction history:</h6>
            {siteInfo.latest_endpoint != null ? (
              <>
                {siteInfo.latest_endpoint.callhomeloggroup_set &&
                siteInfo.latest_endpoint.callhomeloggroup_set.length > 0 ? (
                  <table className="table">
                    <tbody className="overflow-auto">
                      {[...siteInfo.latest_endpoint.callhomeloggroup_set]
                        .reverse()
                        .map((callHomeLogGroup) => {
                          const enterLog = getLatestMaintenanceEnterLog(
                            siteInfo.latest_endpoint.operation_logs,
                          );
                          const exitLog = getLatestMaintenanceExitLog(
                            siteInfo.latest_endpoint.operation_logs,
                          );

                          return (
                            <React.Fragment key={callHomeLogGroup.id}>
                              <tr style={{ border: "1px" }}>
                                <td style={{ width: "200px" }}>
                                  <b>Expected On: </b>
                                  {callHomeLogGroup.expected_on}
                                  <br />
                                  <br />
                                  <b>Uploaded logs </b>
                                  <FontAwesomeIcon
                                    className={
                                      levelToColorDict[
                                        getLogByProcess(
                                          callHomeLogGroup.operationlog_set,
                                          "accept_logs",
                                        )
                                      ]
                                    }
                                    icon={processIconDict["accept_logs"]}
                                  />{" "}
                                  <b>:</b>{" "}
                                  {checkIcon(
                                    getLogByProcess(
                                      callHomeLogGroup.operationlog_set,
                                      "accept_logs",
                                    ),
                                  )}
                                  <br />
                                  <b>Uploaded images </b>
                                  <FontAwesomeIcon
                                    className={
                                      levelToColorDict[
                                        getLogByProcess(
                                          callHomeLogGroup.operationlog_set,
                                          "accept_files",
                                        )
                                      ]
                                    }
                                    icon={processIconDict["accept_files"]}
                                  />{" "}
                                  <b>:</b>{" "}
                                  {checkIcon(
                                    getLogByProcess(
                                      callHomeLogGroup.operationlog_set,
                                      "accept_files",
                                    ),
                                  )}
                                  <br />
                                  <b>Downloaded config </b>
                                  <FontAwesomeIcon
                                    className={
                                      levelToColorDict[
                                        getLogByProcess(
                                          callHomeLogGroup.operationlog_set,
                                          "send_config",
                                        )
                                      ]
                                    }
                                    icon={processIconDict["send_config"]}
                                  />{" "}
                                  <b>:</b>{" "}
                                  {checkIcon(
                                    getLogByProcess(
                                      callHomeLogGroup.operationlog_set,
                                      "send_config",
                                    ),
                                  )}
                                  <br />
                                  <b>Maintenance Enter: </b>{" "}
                                  {enterLog ? enterLog.created_on : "None"}
                                  <br />
                                  <b>Maintenance Exit: </b>{" "}
                                  {exitLog ? exitLog.created_on : "None"}
                                  <br />
                                </td>
                                <td>
                                  <b>Performed On: </b>
                                  {callHomeLogGroup.performed_on}
                                </td>
                                <td>
                                  <b>Number of Callback Intervals Missed: </b>
                                  {callHomeLogGroup.num_missed}
                                </td>
                              </tr>
                            </React.Fragment>
                          );
                        })}
                    </tbody>
                  </table>
                ) : (
                  <p>No Call Home Log Groups found</p>
                )}
              </>
            ) : (
              <p>No endpoint connected</p>
            )}
          </ModalBody>
        </>
      ) : null}
    </Modal>
  );
};

export default CallbackLog;
