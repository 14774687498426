import { useMediaQuery } from "react-responsive";

export function useIsDesktop() {
  return useMediaQuery({ minWidth: 1224 });
}

export function useIsMedium2() {
  return useMediaQuery({ minWidth: 991, maxWidth: 1223 });
}

export function useIsMedium3() {
  return useMediaQuery({ minWidth: 991, maxWidth: 1362 });
}

export function useIsTablet() {
  return useMediaQuery({ minWidth: 768, maxWidth: 991 });
}

export function useIsMobile() {
  return useMediaQuery({ maxWidth: 767 });
}

export function useIsPortrait() {
  return useMediaQuery({ orientation: "portrait" });
}

export function useIsMedium() {
  return useMediaQuery({ minWidth: 768, maxWidth: 1024 });
}

export function useIsLarge() {
  return useMediaQuery({ minWidth: 820, maxWidth: 1180 });
}

export function useIsSpecialLarge() {
  return useMediaQuery({ minWidth: 912, maxWidth: 1368 });
}

export function useIs1024x600() {
  return useMediaQuery({
    minWidth: 1024,
    maxWidth: 1024,
    minHeight: 600,
    maxHeight: 600,
  });
}

export function useIs360x406() {
  return useMediaQuery({
    minWidth: 740,
    maxWidth: 748,
    minHeight: 397,
    maxHeight: 399,
  });
}

export function useIsBetween1081and991() {
  return useMediaQuery({ minWidth: 991, maxWidth: 1081 });
}

export function useIsBetween767and576() {
  return useMediaQuery({ minWidth: 576, maxWidth: 767 }); //630
}

export function useIsLandscape() {
  return useMediaQuery({ orientation: "landscape" });
}

//628 576

export function useIsMidSize() {
  return useMediaQuery({ minWidth: 768, maxWidth: 1020 });
}

export function useIsSmallPortrait() {
  const isMobile = useIsMobile();
  const isPortrait = useIsPortrait();
  return isMobile && isPortrait;
}

export function useIsDefault() {
  const isMobile = useIsMobile();
  const isPortrait = useIsPortrait();
  return !isMobile || !isPortrait;
}

export const Desktop = ({ children }) => {
  return useIsDesktop() ? children : null;
};

export const Tablet = ({ children }) => {
  return useIsTablet() ? children : null;
};

export const Mobile = ({ children }) => {
  return useIsSmallPortrait() ? children : null;
};

export const Default = ({ children }) => {
  return useIsDefault() ? children : null;
};

export const Special1024x600 = ({ children }) => {
  return useIs1024x600() ? children : null;
};

export const SpecialLarge = ({ children }) => {
  return useIsSpecialLarge() ? children : null;
};

export const Meduim2 = ({ children }) => {
  return useIsMedium2() ? children : null;
};
