import React from "react";

import LoginPageContent from "./LoginPageContent";
import LoginForm from "./LoginForm";

import RedeyeLogo from "../../images/RedeyeLogo.png";

const Login = ({ onLogin }) => {
  return (
    <div className="flex flex-col min-h-screen bg-slate-100 items-center">
      <header className="flex items-center justify-between bg-primaryContrast w-full">
        <div>
          <img src={RedeyeLogo} alt="Redeye logo" className="h-24" />
        </div>
        <div className="text-right pr-5">
          <h1 className="text-2xl font-semibold text-primaryColor">
            HydroCams Manager
          </h1>
        </div>
      </header>
      <main className="flex flex-col-reverse md:flex-row w-full p-5 max-w-screen-xl">
        <LoginPageContent />
        <LoginForm onLogin={onLogin} />
      </main>
    </div>
  );
};

export default Login;
