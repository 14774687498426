import { createContext, useContext, useEffect, useState } from "react";
import { Toast, ToastHeader, ToastBody } from "reactstrap";

// create context
const ToastContext = createContext(undefined);

// wrap context provider to add functionality
export const ToastContextProvider = ({ children }) => {
  const [toasts, setToasts] = useState({});

  const clearToast = (key) => {
    if (!toasts[key]) {
      return;
    }
    const newToasts = { ...toasts };
    delete newToasts[key];
    setToasts(newToasts);
  };

  // add toast, create key based on current time
  const showToast = (newToastText) => {
    // Also log toasts to console for debugging if its a big error
    console.log(newToastText);

    let formattedToast = [];
    if (newToastText.status) {
      formattedToast.push(newToastText.status);
    }

    if (newToastText.statusText) {
      formattedToast.push(newToastText.statusText);
    }

    if (typeof newToastText == "string") {
      formattedToast.push(newToastText);
    }

    const newToasts = { ...toasts };

    const timeKey = +new Date(); // generates a timestamp
    newToasts[timeKey] = String(formattedToast.join(" ")).trim();

    setToasts(newToasts);
  };

  // runs every time toasts rerenders
  useEffect(() => {
    if (toasts.legth > 0 || true) {
      // Timeout removes first item in toasts after 5 seconds
      const timeoutId = setTimeout(() => {
        // Get first key and remove it
        const key = Object.keys(toasts).shift();
        clearToast(key);
      }, 3000);

      return () => clearTimeout(timeoutId);
    }
  }, [toasts]);

  return (
    <ToastContext.Provider value={{ showToast }}>
      <div className="toast-container">
        {Object.keys(toasts).map((key) => (
          <div key={key}>
            <Toast
              onClick={() => {
                clearToast(key);
              }}
            >
              <ToastHeader toggle={() => {}}>Notification</ToastHeader>
              <ToastBody>
                <pre>{toasts[key]}</pre>
              </ToastBody>
            </Toast>
          </div>
        ))}
      </div>
      {children}
    </ToastContext.Provider>
  );
};

// Extra warning if toast is not used correctly
export const useToastContext = () => {
  const context = useContext(ToastContext);

  if (!context) {
    throw new Error(
      "useToastContext have to be used within ToastContextProvider",
    );
  }

  return context;
};
