import React, { useState, useEffect, useContext } from "react";
import UserManagement from "../Users/UserManagement.js";
import UserGroupManagement from "../UserGroups/UserGroupManagement.js";
import { UserListContext } from "../Contexts/UserListContext";
import { useSearchParams, useNavigate } from "react-router-dom";
import $ from "jquery";

const UsersAndUserGroupsManagement = (props) => {
  const { user } = props;
  const { currentUser } = useContext(UserListContext);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const activeView = searchParams.get("tab") || "users";

  const [isSuperuser, setIsSuperuser] = useState(false);
  /*
  Hiding Usergroups 

  useEffect(() => {
    setIsSuperuser(currentUser?.is_superuser);
  }, []);
  */
  const buttonStyle = {
    fontSize: "1.2rem",
    fontWeight: "bold",
    textTransform: "uppercase",
    letterSpacing: "1px",
    padding: "0.3rem 0.8rem",
    border: "none",
    backgroundColor: "transparent",
    cursor: "pointer",
    outline: "none",
    color: "#5683af",
  };

  const inactiveButtonStyle = {
    ...buttonStyle,
    color: "#ccc",
    borderBottom: "2px solid #ccc",
  };

  const activeButtonStyle = {
    ...buttonStyle,
    borderBottom: "2px solid #5683af",
  };

  const separatorStyle = {
    display: "inline-block",
    fontSize: "1.2rem",
    fontWeight: "bold",
    margin: "0 0.5rem",
  };

  return (
    <div className="UsersAndUserGroupsManagement">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12" style={{ textAlign: "center" }}>
            <button
              style={
                activeView === "users" ? activeButtonStyle : inactiveButtonStyle
              }
              onClick={() => navigate("/users?tab=users")}
              data-testid="users-button"
            >
              Users
            </button>
            {isSuperuser && (
              <>
                <span style={separatorStyle}>|</span>
                <button
                  style={
                    activeView === "userGroups"
                      ? activeButtonStyle
                      : inactiveButtonStyle
                  }
                  onClick={() => navigate("/users?tab=userGroups")}
                  data-testid="user-groups-button"
                >
                  User Groups
                </button>
              </>
            )}
          </div>
        </div>
        {activeView === "users" ? (
          <div data-testid="user-management">
            <UserManagement user={user} />
          </div>
        ) : (
          isSuperuser && (
            <div data-testid="user-group-management">
              <UserGroupManagement />
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default UsersAndUserGroupsManagement;
