import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import $ from "jquery";

import "./App.css";
import "./index.css";

import Management from "./components/Management.js";
import ContextProvider from "./components/ContextProvider.js";
import Login from "./components/Login/Login";

const App = () => {
  // state to maintain the user authentication status
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem("isAuthenticated") == "true"
  );

  useEffect(() => {
    $.get("/redeye/api/check_user_authentication_status/", (data) => {
      if (data.username) {
        setIsAuthenticated(true);
        localStorage.setItem("isAuthenticated", "true");
      } else {
        setIsAuthenticated(false);
        localStorage.setItem("isAuthenticated", "false");
      }
    });
  }, []);

  const loginHandler = () => {
    setIsAuthenticated(true);
    localStorage.setItem("isAuthenticated", "true");
    window.location.reload();
  };

  const logoutHandler = () => {
    $.get("/redeye/api/logout/")
      .done(function () {
        localStorage.setItem("isAuthenticated", "false");
        localStorage.removeItem("user-json");
        window.location = "/";
      })
      .fail(function (jqXHR, textStatus, errorThrown) {
        // if the status is 401, user is no longer authenticated
        // reload the app to show login screen
        if (jqXHR.status === 401) {
          window.location.reload();
        }
      });
  };

  return (
    <Router>
      <Routes>
        <Route
          path="*"
          element={
            isAuthenticated ? (
              <ContextProvider>
                <Management logoutHandler={logoutHandler} />
              </ContextProvider>
            ) : (
              <Login onLogin={loginHandler} />
            )
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
