import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label,
  Table,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { ModalContext } from "../Contexts/ModalContext";
import { SiteGroupContext } from "../Contexts/SiteGroupContext";

const AddPermToGroup = (props) => {
  const { groupInfo, updateGroupPermission, toggle, onCancel } = props;

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedGroupInTable, setSelectedGroupInTable] = useState(null);
  const [sortConfig, setSortConfig] = useState(null);

  const { control, handleSubmit, formState, setValue } = useForm();

  const { toggleModal } = useContext(ModalContext);
  const { siteGroupList, refreshGroupList } = useContext(SiteGroupContext);

  useEffect(() => {
    refreshGroupList();
  }, [refreshGroupList]);

  const onFormSubmit = (data) => {
    let parsedData = {
      site_group: data.group,
    };
    if (data.perm === "can_view") {
      parsedData.can_view = true;
    } else if (data.perm === "can_edit") {
      parsedData.can_edit = true;
    }
    updateGroupPermission(groupInfo, parsedData);
    toggleModal();
  };

  const handleRowClick = (group) => {
    if (selectedGroupInTable && selectedGroupInTable.id === group.id) {
      setSelectedGroupInTable(null);
      setValue("group", "");
      setSearchTerm("");
    } else {
      setSelectedGroupInTable(group);
      setValue("group", group.id);
      setSearchTerm(group.name);
    }
  };

  const requestSort = (key) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  useEffect(() => {
    refreshGroupList();
  }, [refreshGroupList]);

  const groupWithPermissionsSet = new Set(
    groupInfo.group_permissions.map((group) => group.id),
  );
  const siteGroupListFiltered = Array.isArray(siteGroupList)
    ? siteGroupList.filter((group) => !groupWithPermissionsSet.has(group.id))
    : [];
  let sortedGroupList = Array.isArray(siteGroupListFiltered)
    ? [...siteGroupListFiltered]
    : [];

  if (sortConfig !== null) {
    sortedGroupList.sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? 1 : -1;
      }
      return 0;
    });
  }

  const filteredGroupList =
    sortedGroupList.length > 0
      ? sortedGroupList.filter((group) =>
          group.name.toLowerCase().includes(searchTerm.toLowerCase()),
        )
      : [];

  return (
    <Modal isOpen={true} toggle={toggle}>
      <ModalHeader toggle={toggle} style={{ backgroundColor: "#d3d3d3" }}>
        Add permission to {groupInfo.name}
      </ModalHeader>
      <form onSubmit={handleSubmit((data) => onFormSubmit(data))}>
        <ModalBody>
          <FormGroup>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Label for="search" style={{ width: "20%" }}>
                <b>Search : </b>
              </Label>
              <Input
                style={{ width: "150%" }}
                type="text"
                name="search"
                id="search"
                placeholder="Search Site Group"
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  if (e.target.value === "") setSelectedGroupInTable(null);
                }}
              />
            </div>
          </FormGroup>

          <div style={{ maxHeight: "300px", overflowY: "scroll" }}>
            <Table hover>
              <thead
                style={{
                  position: "sticky",
                  top: 0,
                  backgroundColor: "#fff",
                  borderTop: "2px solid #000",
                  borderBottom: "3px solid #000",
                  zIndex: 1,
                }}
              >
                <tr>
                  <th onClick={() => requestSort("name")}>
                    Site Group{" "}
                    {sortConfig?.key === "name"
                      ? sortConfig.direction === "ascending"
                        ? "↑"
                        : "↓"
                      : ""}
                  </th>
                  <th>Select</th>
                </tr>
              </thead>
              <tbody>
                {filteredGroupList &&
                  filteredGroupList.map((group) => (
                    <tr
                      key={group.id}
                      style={
                        selectedGroupInTable?.id === group.id
                          ? { backgroundColor: "lightblue" }
                          : {}
                      }
                    >
                      <td>{group.name}</td>
                      <td>
                        <Button
                          onMouseOver={(e) =>
                            (e.target.style.backgroundColor = "#00008B")
                          }
                          onMouseOut={(e) =>
                            (e.target.style.backgroundColor =
                              selectedGroupInTable?.id === group.id
                                ? "#00008B"
                                : "")
                          }
                          onClick={() => handleRowClick(group)}
                        >
                          {selectedGroupInTable?.id === group.id
                            ? "Selected"
                            : "Select"}
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
          {formState.errors.group && <span>This field is required</span>}

          <FormGroup>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Label for="perm" style={{ width: "25%" }}>
                <b>Permission : </b>
              </Label>
              <Controller
                name="perm"
                control={control}
                defaultValue="can_view"
                rules={{ required: true }}
                render={({ field }) => (
                  <Input {...field} type="select" placeholder="Site Group">
                    <option value="can_view">Group can view Site Group</option>
                    <option value="can_edit">
                      Group can view and edit Site Group
                    </option>
                  </Input>
                )}
              />
            </div>
          </FormGroup>
        </ModalBody>

        <ModalFooter className="d-flex flex-row justify-content-between">
          <Button color="danger" onClick={onCancel}>
            Cancel
          </Button>
          <Button color="success" type="submit">
            Save
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default AddPermToGroup;
