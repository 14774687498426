import React from "react";

import { Modal, ModalHeader, ModalBody } from "reactstrap";

import ColoredCircle from "../Utilities/ColoredCircle.js";

const stickyModalHeader = {
  position: "sticky",
  top: "0",
  background: "inherit",
  zIndex: "1055",
};

const levelToColorDict = {
  D: "bg-secondary",
  I: "bg-success",
  W: "bg-warning",
  E: "bg-danger",
  F: "bg-danger",
};

const listStyle = {
  maxHeight: "80vh",
  overflow: "auto",
};

const SiteLog = (props) => {
  const { siteInfo, toggle } = props;

  return (
    <Modal isOpen={true} toggle={toggle}>
      {siteInfo ? (
        <>
          <ModalHeader toggle={toggle} style={stickyModalHeader}>
            {siteInfo.name}
          </ModalHeader>
          <ModalBody className="d-flex flex-column" style={listStyle}>
            {siteInfo.latest_endpoint != null ? (
              <>
                {siteInfo.latest_endpoint.operationlog_set &&
                siteInfo.latest_endpoint.operationlog_set.length > 0 ? (
                  <table className="table">
                    <tbody className="overflow-auto">
                      {siteInfo.latest_endpoint.operationlog_set.map((log) => (
                        <tr key={log.id}>
                          <td>{log.created_on}</td>
                          <td>
                            {log.process} - {log.message}
                          </td>
                          <td>
                            <ColoredCircle
                              className={levelToColorDict[log.level]}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <p>No logs of communication with endpoint</p>
                )}
              </>
            ) : (
              <p>No endpoint connected</p>
            )}
          </ModalBody>
        </>
      ) : null}
    </Modal>
  );
};

export default SiteLog;
