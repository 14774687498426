import React, { useEffect, useState, useContext } from "react";
import CompanyModal from "./CompanyModal.js";
import Companies from "./Companies.js";
import $ from "jquery";
import { Default } from "../../MediaQueries.js";
import { ModalContext } from "../Contexts/ModalContext";
import { CompanyContext } from "../Contexts/CompanyContext";
import { getCookie } from "../Utilities/CookieUtils.js";

const CompanyManagement = (props) => {
  const [csrfToken] = useState(getCookie("redeyeCSRFcookie"));
  const { modal, toggleModal, openModal, modalComponent } =
    useContext(ModalContext);
  const { companyList, refreshCompanyList } = useContext(CompanyContext);

  useEffect(() => {
    document.title = "Company Management";
    refreshCompanyList();
  }, [refreshCompanyList]);

  const handleCompanySubmit = (item) => {
    toggleModal();
    let url = "/redeye/api/company/";
    let type = "POST";

    $.ajax({
      type: type,
      url: url,
      data: item,
      headers: { "X-CSRFToken": csrfToken },
      xhrFields: { withCredentials: true },
      dataType: "json",
      success: function (data) {
        refreshCompanyList();
      },
    });
  };

  const handleDelete = (item) => {
    toggleModal();
    $.ajax({
      type: "DELETE",
      url: `/redeye/api/company/${item.id}/`,
      data: item,
      headers: { "X-CSRFToken": csrfToken },
      xhrFields: { withCredentials: true },
      dataType: "json",
      success: function (data) {
        refreshCompanyList();
      },
    });
  };
  return (
    <main>
      <Default>
        <div className="container-fluid">
          <div className="row">
            <Companies companyList={companyList} />
            <div style={{ width: "10rem", marginTop: "-2rem" }}>
              <button
                className="btn btn-primary"
                onClick={() => {
                  openModal("CompanyModal");
                }}
                style={{ width: "10rem", transform: "translateY(-2rem)" }}
              >
                Create Company
              </button>
            </div>
          </div>
        </div>
      </Default>
      {modal && modalComponent === "CompanyModal" ? (
        <CompanyModal
          toggle={toggleModal}
          onSave={handleCompanySubmit}
          onDelete={handleDelete}
        />
      ) : null}
    </main>
  );
};

export default CompanyManagement;
