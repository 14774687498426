import React, { useState } from "react";

export const PreCompanyContext = React.createContext();

export const PreCompanyProvider = ({ children }) => {
  const [sort, setSort] = useState({ property: "", direction: "asc" });
  const [filterValue, setFilterValue] = useState("");
  const [companyInfo, setCompanyInfo] = useState();
  const [selectedCompany, setSelectedCompany] = useState("");

  return (
    <PreCompanyContext.Provider
      value={{
        sort,
        setSort,
        filterValue,
        setFilterValue,
        companyInfo,
        setCompanyInfo,
        selectedCompany,
        setSelectedCompany,
      }}
    >
      {children}
    </PreCompanyContext.Provider>
  );
};
