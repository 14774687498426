import React, { useState } from "react";
import QrReader from "react-web-qr-reader";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const CamQrReader = (props) => {
  const { toggle, openModal, siteInfo } = props;

  const delay = 100;

  const previewStyle = {
    height: 300,
    width: 320,
  };

  const [webCamResult, setwebCamResult] = useState("No result");

  const handleScan = (result) => {
    if (result) {
      setwebCamResult(result.data);
      localStorage.setItem("camResult", result.data);
      openModal("SerialNumberModal", siteInfo);
    }
  };

  const handleError = (error) => {
    if (error) {
      console.log(error);
    }
  };

  return (
    <Modal isOpen={true} toggle={toggle}>
      <center>
        <ModalHeader toggle={toggle} style={{ backgroundColor: "#d3d3d3" }}>
          Hold QR Code Steady and Clear to Scan
        </ModalHeader>
      </center>
      <ModalBody>
        <div className="camStyle">
          <center>
            <QrReader
              delay={delay}
              style={previewStyle}
              onError={handleError}
              onScan={handleScan}
            />
          </center>
        </div>
        <div>
          <br></br>
          <br></br>
          <br></br>
          <p>
            <center>{webCamResult}</center>
          </p>
        </div>
      </ModalBody>
      <ModalFooter className="d-flex flex-row justify-content-between">
        <center>
          <Button
            color="success"
            onClick={() => openModal("SerialNumberModal", siteInfo)}
          >
            Go Back
          </Button>
        </center>
      </ModalFooter>
    </Modal>
  );
};

export default CamQrReader;
