import React, { useContext, useEffect } from "react";
import SiteManagement from "../Sites/SiteManagement";
import SiteGroupManagement from "../SiteGroups/SiteGroupManagement";
import { ManagementContext } from "../Contexts/ManagementContext.js";
import { useIsMobile } from "../../MediaQueries.js";
import { useSearchParams, useNavigate } from "react-router-dom";
import { SiteGroupContext } from "../Contexts/SiteGroupContext";

const SitesAndSiteGroupsManagement = () => {
  const isMobile = useIsMobile();
  const { siteGroupList, refreshGroupList } = useContext(SiteGroupContext);
  const { user } = useContext(ManagementContext);

  const [searchParams] = useSearchParams();
  const activeView = searchParams.get("tab") || "sites";

  const navigate = useNavigate();

  const buttonStyle = {
    fontSize: "1.2rem",
    fontWeight: "bold",
    textTransform: "uppercase",
    letterSpacing: "1px",
    padding: "0.3rem 0.8rem",
    border: "none",
    backgroundColor: "transparent",
    cursor: "pointer",
    outline: "none",
    color: "#5683af",
  };

  const inactiveButtonStyle = {
    ...buttonStyle,
    color: "#ccc",
    borderBottom: "2px solid #ccc",
  };

  const activeButtonStyle = {
    ...buttonStyle,
    borderBottom: "2px solid #5683af",
  };

  const separatorStyle = {
    display: "inline-block",
    fontSize: "1.2rem",
    fontWeight: "bold",
    margin: "0 0.5rem",
  };

  useEffect(() => {
    refreshGroupList();
  }, [refreshGroupList]);
  
  return (
    <div className="SitesAndSiteGroupsManagement">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12" style={{ textAlign: "center" }}>
            {/* <button
              style={
                activeView === "sites" ? activeButtonStyle : inactiveButtonStyle
              }
              onClick={() => navigate("/sites?tab=sites")}
              data-testid="site-button"
            >
              Sites
            </button>*/}

            {(siteGroupList?.length > 0 || user?.is_superuser) && (
              <button
                style={
                  activeView === "siteGroups"
                    ? activeButtonStyle
                    : inactiveButtonStyle
                }
                onClick={() => navigate("/sites?tab=siteGroups")}
                data-testid="site-groups-button"
              >
                Site Groups
              </button>
            )}
          </div>
        </div>
        {activeView === "sites" ? (
          <div data-testid="site-management">
            <SiteManagement siteGroupList={siteGroupList} user={user} />
          </div>
        ) : (
          <div data-testid="site-group-management">
            <SiteGroupManagement />
          </div>
        )}
      </div>
    </div>
  );
};

export default SitesAndSiteGroupsManagement;
