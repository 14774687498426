import React, { useEffect } from "react";

const SiteSerialNumbers = (props) => {
  const { siteInfo, fullList } = props;

  const getSerialNumberStatus = (item) => {
    if (!item.used) {
      if (item.valid) {
        return "Active";
      }
      return "Timed out";
    }
    return "Registered";
  };

  if (siteInfo?.endpoint_serial_number) {
    return (
      <>
        <div className="h5">Serial numbers</div>
        <div className="pb-3">
          Listening for endpoint with serial number{" "}
          <b>{siteInfo?.endpoint_serial_number}</b>.
        </div>
      </>
    );
  }

  if (siteInfo?.serialnumber_set?.length > 0) {
    const latest_serialnumber = siteInfo.serialnumber_set[0];

    if (!latest_serialnumber.used) {
      return (
        <>
          <div className="h5">Serial numbers</div>
          <div>
            <table className="table">
              <thead>
                <tr>
                  <th>Serial Number</th>
                  <th>Created On</th>
                  <th>Status</th>
                </tr>
              </thead>
              {fullList ? (
                <tbody className="overflow-auto">
                  {siteInfo.serialnumber_set.map((item) => (
                    <tr key={item.id}>
                      <th>{item.serial_number}</th>
                      <td>{item.created_on}</td>
                      <td>{getSerialNumberStatus(item)}</td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody>
                  <tr key={latest_serialnumber.id}>
                    <th>{latest_serialnumber.serial_number}</th>
                    <td>{latest_serialnumber.created_on}</td>
                    <td>{getSerialNumberStatus(latest_serialnumber)}</td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </>
      );
    }
    return (
      <>
        <div className="h5">Serial numbers</div>
        <div>latest serial number used</div>
      </>
    );
  }
  return (
    <>
      <div className="h5">Serial numbers</div>
      <div>no serial number submitted</div>
    </>
  );
};

export default SiteSerialNumbers;
