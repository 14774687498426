import React, { useEffect, useState } from "react";
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import { formatLatLon } from "../../Utilities.js";

const SerialNumberModal = (props) => {
  const { initialItem, toggle, onSave, onCancel, openModal, saveSite } = props;

  const { register, control, handleSubmit, formState, getValues, setValue } =
    useForm({
      defaultValues: { site: initialItem.id },
    });

  const [webCamResult, setwebCamResult] = useState("No result");

  const [confirm, setConfirm] = useState(false);
  const [newLat, setNewLat] = useState(0);
  const [newLon, setNewLon] = useState(0);

  const handleConfirm = (updateSite = false) => {
    // If the user wants to update the site's position, do so before saving the
    // form's data
    if (updateSite) {
      initialItem.latitude = newLat;
      initialItem.longitude = newLon;
      saveSite(initialItem);
    }
    setConfirm(false);
    onSave(getValues());
  };

  const getPositionSuccesss = (position) => {
    // If user's lat long does not match site's lat long, show them the confirm
    // window
    if (
      initialItem.latitude !== position.coords.latitude ||
      initialItem.longitude !== position.coords.longitude
    ) {
      setNewLat(position.coords.latitude);
      setNewLon(position.coords.longitude);
      setConfirm(true);
    } else {
      onSave(getValues());
    }
  };

  const getPositionError = (error) => {
    // Couldn't get position, just submit the form's data
    alert(
      `There was an issue retreiving your location. Since it is expected that you will be in close proximity to the endpoint when you scan and submit its serial number, the Redeye server wants to use your location to check against the site's current location. Disregard this message if you are not in close proximity to the endpoint.`
    );
    onSave(getValues());
  };
  const onFormSubmit = (data) => {
    onSave(getValues());
  };

  return (
    <Modal isOpen={true} toggle={toggle}>
      <ModalHeader toggle={toggle} style={{ backgroundColor: "#d3d3d3" }}>
        New Serial Number
      </ModalHeader>
      <form onSubmit={handleSubmit((data) => onFormSubmit(data))}>
        <input name="site" type="hidden" {...register("site")} />
        <ModalBody>
          <div>
            The serial number entered here will be valid for 10 minutes once it
            is submitted. If an unregistered endpoint with that serial number is
            turned on in that time, it will register with the server. The same
            serial number may be submitted multiple times but may only register
            one endpoint once. If that endpoint is later detached, its serial
            number may be used again.
          </div>
          <FormGroup>
            <Label for="serial_number">Serial Number</Label>
            <div className="d-flex flex-row justify-content-between">
              <div>
                <Controller
                  name="serial_number"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      value={webCamResult}
                      onChange={(e) => setwebCamResult(e.target.value)}
                      className=""
                      {...field}
                      invalid={Boolean(formState.errors.serial_number)}
                      type="text"
                      placeholder="Serial Number"
                    ></Input>
                  )}
                />
                {formState.errors.serial_number && (
                  <span>This field is required</span>
                )}
              </div>
              {/* <Button
                type="button"
                color="primary"
                onClick={() => openModal("CamQrReader", initialItem)}
              >
                Scan serial number
              </Button> */}
            </div>
          </FormGroup>
        </ModalBody>
        <ModalFooter className="d-flex flex-row justify-content-between">
          <Button color="danger" onClick={onCancel}>
            Cancel
          </Button>
          <Button color="success" type="submit">
            Save
          </Button>
        </ModalFooter>
      </form>

      {confirm ? (
        <ConfirmationModal
          handleConfirm={handleConfirm}
          handleClose={() => setConfirm(false)}
          initialItem={initialItem}
          newLat={newLat}
          newLon={newLon}
        />
      ) : null}
    </Modal>
  );
};

const ConfirmationModal = (props) => {
  const { handleConfirm, handleClose, initialItem, newLat, newLon } = props;
  return (
    <Modal isOpen={true} toggle={handleClose}>
      <ModalHeader toggle={handleClose}>Update site's position?</ModalHeader>
      <ModalBody>
        <div>
          The site {initialItem.name} is currently located at{" "}
          {formatLatLon(initialItem.latitude, initialItem.longitude)} but you
          are currently located at {formatLatLon(newLat, newLon)}. If you are
          physically close to the endpoint, it is recommended that the site's
          coordinates are updated to be more accurate.
          <br />
          You may cancel the creation of the serial number, create the serial
          number while not updating the site's location, or create the serial
          number while updating the site's location to reflect your current
          location.
        </div>
        <div className="d-flex flex-row justify-content-between">
          <Button color="danger" onClick={() => handleClose()}>
            Cancel
          </Button>
          <Button color="success" onClick={() => handleConfirm()}>
            Don't update
          </Button>
          <Button color="success" onClick={() => handleConfirm(true)}>
            Update
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default SerialNumberModal;
