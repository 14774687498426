import React, { useState, useEffect, useContext } from "react";
import UserGroupDetails from "./UserGroupDetails.js";
import { Default } from "../../MediaQueries.js";
import { Form, FormGroup, Input, Label } from "reactstrap";
import { UserListContext } from "../Contexts/UserListContext";
import { PreUserGroupContext } from "../Contexts/PreUserGroupContext";
import StyledTableHeader from "../Utilities/StyledTableHeader.js";
import ScrollbarStyles from "../Utilities/ScrollbarStyles.js";

const UserGroups = (props) => {
  const { userGroupList } = props;

  const {
    selectedUserGroupID,
    setSelectedUserGroupID,
    sort,
    setSort,
    filterValue,
    setFilterValue,
  } = useContext(PreUserGroupContext);

  const getSelectedUserGroup = () => {
    return userGroupList.find((user_group) => {
      return user_group.id === selectedUserGroupID;
    });
  };

  const { userList, refreshUserList } = useContext(UserListContext);

  useEffect(() => {
    document.title = "Logical Endpoints";
    refreshUserList();
  }, [refreshUserList]);

  const [selectedSearchField, setSelectedSearchField] = useState("name");

  let userGroupListStyle = {
    maxHeight: "65vh",
    overflow: "auto",
    display: "inline-block",
  };

  const userGroupInfo = getSelectedUserGroup();

  const sortData = userGroupList.sort((a, b) => {
    const valueA = a[sort.property];
    const valueB = b[sort.property];
    if (sort.direction === "asc") {
      return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
    }
    return valueA > valueB ? -1 : valueA < valueB ? 1 : 0;
  });

  const filteredUserGroups = sortData.filter((group) => {
    const groupName = group.name.toLowerCase();
    const filterValueLower = filterValue.toLowerCase();

    if (selectedSearchField === "name") {
      return groupName.includes(filterValueLower);
    }

    if (selectedSearchField === "users") {
      const usersInGroup = userList.filter((user) =>
        user.groups.includes(group.id),
      );
      const numberOfUsers = usersInGroup.length;
      return numberOfUsers.toString().includes(filterValueLower);
    }

    return false;
  });

  let rightHandStyle = {
    backgroundColor: "rgb(242, 242, 242)",
    borderLeft: "2px solid #000",
    marginTop: "0.3rem",
    height: "78.5vh",
    left: "0.5%",
    overflow: "auto",
  };

  // const tableHeaderStyle = {
  //   backgroundColor: "lightblue",
  //   color: "black",
  // };

  const searchFields = [
    { label: "User Group Name", value: "name" },
    { label: "Number of Users", value: "users" },
  ];

  if (userGroupList && userList) {
    return (
      <>
        <ScrollbarStyles />
        <div className="row">
          <div className="col-4">
            <Form
              style={{
                marginBottom: "1rem",
                width: "100%",
                marginTop: "0.3rem",
              }}
            >
              <FormGroup style={{ display: "flex", alignItems: "center" }}>
                <Label
                  for="searchTerm"
                  style={{
                    marginRight: "0.5rem",
                    fontFamily: "'Orbitron', sans-serif",
                    fontWeight: "bold",
                    fontSize: "1rem",
                    letterSpacing: "0.1rem",
                    color: "#1a3c71",
                  }}
                >
                  Search:
                </Label>
                <Input
                  type="text"
                  name="searchTerm"
                  id="searchTerm"
                  placeholder="Search..."
                  value={filterValue}
                  onChange={(e) => setFilterValue(e.target.value)}
                  style={{ backgroundColor: "#f2f2f2" }}
                />
                <Input
                  type="select"
                  name="searchField"
                  id="searchField"
                  value={selectedSearchField}
                  onChange={(e) => setSelectedSearchField(e.target.value)}
                  style={{ backgroundColor: "#f2f2f2", marginLeft: "1rem" }}
                >
                  {searchFields.map((field) => (
                    <option key={field.value} value={field.value}>
                      {field.label}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Form>
            <div style={{ display: "flex", flexDirection: "row", gap: "8%" }}>
              <div style={userGroupListStyle}>
                <StyledTableHeader
                  columns={[
                    { header: "User Group Name", key: "name", width: "60%" },
                    { header: "Number of Users", key: "users", width: "40%" },
                  ]}
                  sort={sort}
                  setSort={setSort}
                />
                <table className="table">
                  <tbody className="overflow-auto">
                    {filteredUserGroups.map((group) => {
                      const usersInGroup = userList.filter((user) =>
                        user.groups.includes(group.id),
                      );
                      return (
                        <tr
                          key={group.id}
                          className="box"
                          onClick={() => {
                            setSelectedUserGroupID(group.id);
                          }}
                          style={{
                            backgroundColor:
                              selectedUserGroupID === group.id
                                ? "#6c757d"
                                : "inherit",
                            color:
                              selectedUserGroupID === group.id
                                ? "white"
                                : "inherit",
                          }}
                        >
                          <td style={{ width: "60%" }}>{group.name}</td>
                          <td style={{ width: "40%" }}>
                            {usersInGroup.length}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div
            className="col-8 position-relative scrollable-table"
            style={rightHandStyle}
          >
            {userGroupInfo && (
              <Default>
                <div className="row">
                  <div className="col-30">
                    <UserGroupDetails
                      userList={userList}
                      selectedGroup={userGroupInfo}
                    />
                  </div>
                </div>
              </Default>
            )}

            {!userGroupInfo && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                {" "}
                <span>Select a user group to see the details!</span>{" "}
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
};

export default UserGroups;
