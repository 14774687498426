import React, { useContext } from "react";
import { Marker, MapContainer, TileLayer } from "react-leaflet";
import { Modal, ModalHeader } from "reactstrap";
import { SiteIcon } from "../Utilities/CustomIcon.js";
import { ModalContext } from "../Contexts/ModalContext";

const SiteOnMapModal = (props) => {
  const { siteInfo } = props;

  const mapContainerStyle = {
    width: "100%",
    height: `85vh`,
  };

  const { modal, toggleModal } = useContext(ModalContext);

  return (
    <Modal isOpen={modal} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>
        {siteInfo && siteInfo.id ? siteInfo.name : null}
      </ModalHeader>
      <MapContainer
        style={mapContainerStyle}
        center={[siteInfo.latitude, siteInfo.longitude]}
        zoom={12}
        scrollWheelZoom={true}
      >
        <TileLayer
          attribution='Kartendaten: &copy; <a href="https://openstreetmap.org/copyright">OpenStreetMap</a>-Mitwirkende, SRTM | Kartendarstellung: &copy; <a href="http://opentopomap.org">OpenTopoMap</a> <a href="https://creativecommons.org/licenses/by-sa/3.0/">(CC-BY-SA)</a>'
          url="https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png"
        />
        <Marker
          icon={SiteIcon(siteInfo)}
          key={siteInfo.id}
          data={siteInfo.id}
          position={[siteInfo.latitude, siteInfo.longitude]}
        />
      </MapContainer>
    </Modal>
  );
};
export default SiteOnMapModal;
