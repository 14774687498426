import React, { useEffect, useState, useContext } from "react";
import UserGroups from "./UserGroups";
import UserGroupModal from "./UserGroupModal";
import UserManagement from "../Users/UserManagement";
import $ from "jquery";
import { Default } from "../../MediaQueries.js";
import { ModalContext } from "../Contexts/ModalContext";
import { UserGroupContext } from "../Contexts/UserGroupContext";
import { getCookie } from "../Utilities/CookieUtils.js";

const UserGroupManagement = (props) => {
  const [csrfToken] = useState(getCookie("redeyeCSRFcookie"));

  const { modal, toggleModal, openModal, modalComponent, handleCancel } =
    useContext(ModalContext);

  const { userGroupList, refreshUserGroupList } = useContext(UserGroupContext);

  useEffect(() => {
    document.title = "Logical Endpoints";
    refreshUserGroupList();
  }, [refreshUserGroupList]);

  if (!userGroupList) {
    return null;
  }

  const handleUserGroupSubmit = (item) => {
    toggleModal();
    let url = "/redeye/api/user_group/";
    let type = "POST";

    $.ajax({
      type: type,
      url: url,
      data: item,
      headers: { "X-CSRFToken": csrfToken },
      xhrFields: { withCredentials: true },
      dataType: "json",
      success: function (data) {
        refreshUserGroupList();
      },
    });
  };

  // const handleDelete = (item) => {
  //   toggleModal();
  //   $.ajax({
  //     type: "DELETE",
  //     url: `/redeye/api/user_group/${item.id}/`,
  //     data: item,
  //     headers: {'X-CSRFToken': csrfToken},
  //     xhrFields: {withCredentials: true},
  //     dataType: 'json',
  //     success: function (data) {
  //       refreshUserGroupList();
  //     }
  //   });
  // }

  // console.log("USER GROUP", userGroupList);
  const passUserGroupList = userGroupList;
  // console.log("PASS", passUserGroupList)
  // console.log("USER GROUP PROP", props.passUserGroupList)

  return (
    <main>
      <Default>
        <div className="container-fluid">
          <div className="row">
            <div>
              {userGroupList ? (
                <UserGroups userGroupList={userGroupList} />
              ) : (
                <p>Loading User Group data...</p>
              )}
            </div>
            <div style={{ width: "20rem", transform: "translateY(-2rem)" }}>
              <button
                className="btn btn-primary"
                onClick={() => openModal("UserGroupModal")}
              >
                Create User Group
              </button>
            </div>
          </div>
        </div>
      </Default>
      {modal && modalComponent === "UserGroupModal" ? (
        <UserGroupModal
          toggle={toggleModal}
          onSave={handleUserGroupSubmit}
          onCancel={handleCancel}
        />
      ) : null}
      {modal && modalComponent === "UserManagement" ? (
        <UserManagement userGroupList={passUserGroupList} />
      ) : null}
    </main>
  );
};

export default UserGroupManagement;
