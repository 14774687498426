import React from "react";

const ColoredCircle = (props) => {
  const { color, className, children, spinner } = props;

  const coloredCircleStyle = {
    backgroundColor: color,
    display: "inline-block",
    marginLeft: "5px",
    marginRight: "5px",
    marginBottom: "-5px",
    borderRadius: "50%",
    height: "20px",
    width: "20px",
  };

  const nonSpinnerStyle = {
    borderStyle: "solid",
    borderWidth: "0.5px",
    borderColor: "black",
  };

  // Create an empty object so we're not overriding coloredCircleStyle, then
  // conditionally append the style for non spinner circles
  const styleToUse = Object.assign({}, coloredCircleStyle);
  if (!spinner) {
    Object.assign(styleToUse, nonSpinnerStyle);
  }

  const textStyle = {
    fontSize: "12px",
    marginLeft: "-21px", // TODO: Find a better way to center the 'M' inside the
    fontWeight: "bold", // circle wihtout hardcoding a pixel offset
    color: "yellow",
  };

  return color || className ? (
    <div>
      <div
        data-testid="colored-circle"
        className={
          "colored-circle " + (spinner ? "spinner-border " : null) + className
        }
        role="status"
        style={styleToUse}
      >
        <span className="sr-only">Loading...</span>
      </div>
      <span className="position-relative" style={textStyle}>
        {children}
      </span>
    </div>
  ) : null;
};

export default ColoredCircle;
