import React, { useEffect, useState, useContext } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label,
  Table,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { ModalContext } from "../Contexts/ModalContext";
import { SiteGroupContext } from "../Contexts/SiteGroupContext";

const DeleteUserModel = (props) => {
  const { userInfo, DeleteUserPost, toggle, onCancel } = props;

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedGroupInTable, setSelectedGroupInTable] = useState(null);
  const [sortConfig, setSortConfig] = useState(null);

  const { control, handleSubmit, formState, setValue } = useForm();

  const { toggleModal } = useContext(ModalContext);
  const { siteGroupList, refreshGroupList } = useContext(SiteGroupContext);

  useEffect(() => {
    refreshGroupList();
  }, [refreshGroupList]);

  const onFormSubmit = (data) => {
    DeleteUserPost(userInfo);
    console.log(userInfo);
    toggleModal();
  };

  return (
    <Modal isOpen={true} toggle={toggle}>
      <ModalHeader toggle={toggle} style={{ backgroundColor: "#d3d3d3" }}>
        Delete User
      </ModalHeader>
      <form onSubmit={handleSubmit((data) => onFormSubmit(data))}>
        <ModalBody>
          <FormGroup>
            <div>
              <Label>
                Confirm delete of user <b>"{userInfo.username}"</b>
              </Label>
            </div>
          </FormGroup>
        </ModalBody>

        <ModalFooter className="d-flex flex-row justify-content-between">
          <Button color="success" onClick={onCancel}>
            Cancel
          </Button>
          <Button color="danger" type="submit">
            Confirm Delete
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default DeleteUserModel;
