import React from "react";
import CompanyManagement from "../Company/CompanyManagement.js";
import { useSearchParams, useNavigate } from "react-router-dom";

const CompaniesManagement = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const activeView = searchParams.get("tab") || "companies";

  const buttonStyle = {
    fontSize: "1.2rem",
    fontWeight: "bold",
    textTransform: "uppercase",
    letterSpacing: "1px",
    padding: "0.3rem 0.8rem",
    border: "none",
    backgroundColor: "transparent",
    cursor: "pointer",
    outline: "none",
    color: "#5683af",
  };

  const activeButtonStyle = {
    ...buttonStyle,
    borderBottom: "2px solid #5683af",
  };

  return (
    <div className="CompaniesManagement">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12" style={{ textAlign: "center" }}>
            <button
              style={activeButtonStyle}
              onClick={() => navigate("/companies?tab=companies")}
              data-testid="companies-button"
            >
              Companies
            </button>
          </div>
        </div>
        <div data-testid="company-management">
          <CompanyManagement />
        </div>
      </div>
    </div>
  );
};

export default CompaniesManagement;
