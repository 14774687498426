import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { getCookie } from "../Utilities/CookieUtils.js";
import { ManagementContext } from "../Contexts/ManagementContext.js";
import { CompanyContext } from "../Contexts/CompanyContext";
import $ from "jquery";

const AddUserToSiteGroup = (props) => {
  const { selectedUser, toggle, onCancel, onUserAdded } = props;
  const { user } = useContext(ManagementContext);
  const { userCompany, refreshCompanyList } = useContext(CompanyContext);
  const csrfToken = getCookie("redeyeCSRFcookie");
  const { control, handleSubmit, formState, setValue } = useForm();

  const [currentUser, setCurrentUser] = useState(null);
  const [companyList, setCompanyList] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [siteGroupList, setSiteGroupList] = useState([]);
  const [selectedSiteGroup, setSelectedSiteGroup] = useState("");
  const [admin, setAdmin] = useState(false); // New state for admin field

  useEffect(() => {
    setCurrentUser(user);
    refreshCompanyList();
    fetchCompaniesForCurrentUser(user, user?.id);
  }, []);

  const fetchCompaniesForCurrentUser = (userdata, userId) => {
    let filteredCompanies = userCompany.filter(
      (record) => record.user === userId && record.is_companyadmin == true
    );
    if (userdata?.is_superuser) {
      filteredCompanies = userCompany;
    }

    const companyIds = Array.from(
      new Set(filteredCompanies.map((record) => record.company))
    );
    $.ajax(
      {
        type: "GET",
        url: `/redeye/api/company/`,
        headers: { "X-CSRFToken": csrfToken },
        xhrFields: { withCredentials: true },
        dataType: "json",
        success: function (companyData) {
          const companyMap = companyData.reduce((acc, company) => {
            if (userdata?.is_superuser || companyIds.includes(company.id)) {
              acc[company.id] = company.name;
            }
            return acc;
          }, {});
          let companyDetails = filteredCompanies.map((record) => ({
            id: record.company,
            name: companyMap[record.company] || "Unknown",
          }));
          if (userdata?.is_superuser) {
            companyDetails = Object.keys(companyMap).map((id) => ({
              id: parseInt(id, 10), // Ensure id is an integer
              name: companyMap[id],
            }));
          }
          setCompanyList(companyDetails);
        },
        error: function (xhr) {
          console.error("Error fetching company details:", xhr.responseText);
          setCompanyList([]);
        },
      },
      []
    );
  };

  useEffect(() => {
    if (selectedCompany && selectedUser) {
      // Check if user is a company admin

      const uc = userCompany.find(
        (record) =>
          record.user == selectedUser.id && record.company == selectedCompany
      );
      if (uc) {
        if (uc.is_companyadmin) {
          alert("He is Company Admin. Action restricted.");
          setSelectedCompany("");
          return;
        }
      }

      // Proceed with fetching site groups if not an admin
      fetchSiteGroupsForCompany(currentUser, selectedCompany);
    }
  }, [selectedCompany, selectedUser]);

  const fetchSiteGroupsForCompany = (userdata, companyId) => {
    $.ajax({
      type: "GET",
      url: `/redeye/api/user_company/`,
      headers: { "X-CSRFToken": csrfToken },
      xhrFields: { withCredentials: true },
      dataType: "json",
      success: function (userCompanyData) {
        let userCompanyAdmin = userCompanyData.find(
          (record) =>
            record.user == userdata.id &&
            record.company == companyId &&
            record.is_companyadmin
        );
        if (userdata?.is_superuser) {
          userCompanyAdmin = true;
        }
        if (userCompanyAdmin) {
          $.ajax({
            type: "GET",
            url: `/redeye/api/site_group/`,
            headers: { "X-CSRFToken": csrfToken },
            xhrFields: { withCredentials: true },
            dataType: "json",
            success: function (siteGroupData) {
              const filteredGroups = siteGroupData.filter(
                (group) => group.company == companyId
              );
              setSiteGroupList(filteredGroups);
            },
            error: function (xhr) {
              console.error("Error fetching site groups:", xhr.responseText);
              setSiteGroupList([]);
            },
          });
        } else {
          $.ajax({
            type: "GET",
            url: `/redeye/api/user_site_group/`,
            headers: { "X-CSRFToken": csrfToken },
            xhrFields: { withCredentials: true },
            dataType: "json",
            success: function (userSiteGroupData) {
              const filteredSiteGroups = userSiteGroupData
                .filter(
                  (record) =>
                    record.user == userdata.id &&
                    record.company == companyId &&
                    record.admin_user == true
                )
                .map((record) => record.sitegroup);

              $.ajax({
                type: "GET",
                url: `/redeye/api/site_group/`,
                headers: { "X-CSRFToken": csrfToken },
                xhrFields: { withCredentials: true },
                dataType: "json",
                success: function (siteGroupData) {
                  const finalSiteGroups = siteGroupData.filter((group) =>
                    filteredSiteGroups.includes(group.id)
                  );
                  setSiteGroupList(finalSiteGroups);
                },
                error: function (xhr) {
                  console.error(
                    "Error fetching site groups:",
                    xhr.responseText
                  );
                  setSiteGroupList([]);
                },
              });
            },
            error: function (xhr) {
              console.error(
                "Error fetching user site groups:",
                xhr.responseText
              );
              setSiteGroupList([]);
            },
          });
        }
      },
      error: function (xhr) {
        console.error("Error fetching user-company data:", xhr.responseText);
        setSiteGroupList([]);
      },
    });
  };

  const handleAddUserToSiteGroupSubmit = (data) => {
    $.ajax({
      type: "GET",
      url: `/redeye/api/user_site_group/`,
      headers: { "X-CSRFToken": csrfToken },
      xhrFields: { withCredentials: true },
      dataType: "json",
      success: function (response) {
        const existingdata = response.filter(
          (record) =>
            record.user == selectedUser.id &&
            record.sitegroup == selectedSiteGroup &&
            record.company == selectedCompany
        );
        if (existingdata.length > 0) {
          alert("User is already part of the group.");
        } else {
          $.ajax({
            type: "POST",
            url: `/redeye/api/user_site_group/`,
            data: {
              user: selectedUser.id,
              sitegroup: selectedSiteGroup,
              company: selectedCompany,
              admin_user: admin,
            },
            headers: { "X-CSRFToken": csrfToken },
            xhrFields: { withCredentials: true },
            dataType: "json",
            success: function () {
              toggle();
              onUserAdded();
            },
            error: function (xhr) {
              console.error("Error:", xhr.responseText);
            },
          });
        }
      },
      error: function (xhr) {
        console.error("Error:", xhr.responseText);
      },
    });
  };

  return (
    <Modal isOpen={true} toggle={toggle}>
      <ModalHeader toggle={toggle} style={{ backgroundColor: "#d3d3d3" }}>
        Add User to Site Group
      </ModalHeader>
      <form onSubmit={handleSubmit(handleAddUserToSiteGroupSubmit)}>
        <ModalBody>
          <FormGroup>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Label for="company" style={{ width: "17%" }}>
                <b>Company : </b>
              </Label>
              <Input
                type="select"
                name="company"
                id="company"
                value={selectedCompany}
                onChange={(e) => setSelectedCompany(e.target.value)}
              >
                <option value="">Select a company</option>
                {companyList.map((company) => (
                  <option key={company.id} value={company.id}>
                    {company.name}
                  </option>
                ))}
              </Input>
            </div>
          </FormGroup>
          <FormGroup>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Label for="siteGroup" style={{ width: "17%" }}>
                <b>SiteGroup : </b>
              </Label>
              <Input
                type="select"
                name="siteGroup"
                id="siteGroup"
                value={selectedSiteGroup}
                onChange={(e) => setSelectedSiteGroup(e.target.value)}
              >
                <option value="">Select a site group</option>
                {siteGroupList.map((siteGroup) => (
                  <option key={siteGroup.id} value={siteGroup.id}>
                    {siteGroup.name}
                  </option>
                ))}
              </Input>
            </div>
          </FormGroup>
          <FormGroup check>
            <Label check>
              <Input
                type="checkbox"
                name="admin"
                checked={admin}
                onChange={(e) => setAdmin(e.target.checked)}
              />
              Admin User
            </Label>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button type="submit" color="primary">
            Add
          </Button>
          <Button color="secondary" onClick={onCancel}>
            Cancel
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default AddUserToSiteGroup;
