import React, { useEffect, useState, createContext } from "react";
import $ from "jquery";

export const ManagementContext = createContext(null);

export const ManagementProvider = (props) => {
  const [user, setUser] = useState(() => {
    let cachedJsonUser = localStorage.getItem("user-json");
    const cachedUser = JSON.parse(cachedJsonUser);
    return cachedUser || null;
  });
  
  useEffect(() => {
    // Check if user is already set up
    if (user?.id > 1) {
      return;
    }

    // Fetch user data if not set
    $.get("/redeye/api/current_user/", {
      xhrFields: { withCredentials: true },
    }).done(function (data) {
      // Cache user data
      localStorage.setItem("user-json", JSON.stringify(data));
      setUser(data);
    });
  }, [user]);


  return (
    <ManagementContext.Provider
      value={{
        user,
      }}
    >
      {props.children}
    </ManagementContext.Provider>
  );
};
