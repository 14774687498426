import React, { useEffect, useState } from "react";
import $ from "jquery";

const LoginForm = ({ onLogin }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [csrfToken, setCsrfToken] = useState("");

  const formSubmitHandler = (event) => {
    event.preventDefault();
    $.ajax({
      url: "/redeye/api/login/",
      type: "POST",
      contentType: "application/json",
      data: JSON.stringify({ 
        username: username?.trim(),
        password: password,
      }),
      beforeSend: function (xhr) {
        xhr.setRequestHeader("X-CSRFToken", csrfToken);
      },
      success: function (response) {
        if (response.message === "login successful") {
          onLogin();
        }
      },
      error: function (xhr) {
        if (xhr.status === 400) {
          setError("Invalid credentials");
        }
      },
    });
  };

  // useEffect to get the csrf_token when the login form is rendered
  useEffect(() => {
    $.get("/redeye/api/get_csrf_token/", (data) => {
      if (data.csrfToken) {
        setCsrfToken(data.csrfToken);
      }
    });
  }, []);

  return (
    <section className="w-full md:w-1/3 md:flex-shrink-0">
      <form
        onSubmit={formSubmitHandler}
        className="bg-primaryContrast text-white text-lg font-bold p-8 rounded-lg shadow-md w-full"
      >
        <div className="mb-6">
          <p className="text-primaryColor">
            HydroCams Manager is a secure application. Please log in below to continue.
          </p>
        </div>
        <div className="mb-4">
          <label>Username</label>
          <input
            type="text"
            value={username}
            onChange={(event) => setUsername(event.target.value)}
            className="mt-1 block w-full px-3 py-2 text-slate-800 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none"
          />
        </div>
        <div className="mb-6">
          <label>Password</label>
          <input
            type="password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            className="mt-1 block w-full px-3 py-2 text-slate-800 font-normal bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none"
          />
        </div>
        {error && <p className="mb-4 text-primaryColor">{error}</p>}
        <button
          type="submit"
          className="w-full px-4 py-2 text-primaryContrast bg-primaryColor rounded hover:bg-yellow-300 active:bg-yellow-500"
        >
          Login
        </button>
      </form>
    </section>
  );
};

export default LoginForm;
