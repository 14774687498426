import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";

const UserGroupModal = (props) => {
  const [modalItem] = useState({ name: "" });

  const { toggle, onSave, onCancel } = props;

  const { register, control, handleSubmit, formState } = useForm({
    defaultValues: modalItem,
  });

  return (
    <Modal isOpen={true} toggle={toggle}>
      <ModalHeader toggle={toggle} style={{ backgroundColor: "#d3d3d3" }}>
        {modalItem && modalItem.id ? modalItem.name : "New User Group Name"}
      </ModalHeader>
      <form onSubmit={handleSubmit((data) => onSave(data))}>
        <input type="hidden" {...register("id")} />
        <ModalBody>
          <FormGroup>
            <Label for="name">
              <b>User Group Name</b>
            </Label>
            <Controller
              name="name"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  invalid={Boolean(formState.errors.name)}
                  type="text"
                  placeholder="User Group Name"
                />
              )}
            />
            {formState.errors.name && <span>This field is required</span>}
          </FormGroup>
          <FormGroup>
            <Label for="description">
              <b>Description</b>
            </Label>
            <Controller
              name="description"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  invalid={Boolean(formState.errors.description)}
                  type="textarea"
                  placeholder="Description"
                />
              )}
            />
            {formState.errors.description && (
              <span>This field is required</span>
            )}
          </FormGroup>
        </ModalBody>

        <ModalFooter className="d-flex flex-row justify-content-between">
          <Button color="danger" onClick={onCancel}>
            Cancel
          </Button>
          <Button color="success" type="submit">
            Save
          </Button>
          {modalItem && modalItem.id ? (
            <Button color="danger" onClick={""}>
              Delete
            </Button>
          ) : null}
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default UserGroupModal;
