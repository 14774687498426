import React, { useContext, useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { CompanyContext } from "../Contexts/CompanyContext";

const SiteGroupModal = (props) => {
  const { modalItem, toggle, onSave } = props;
  const { companyList } = useContext(CompanyContext);

  const { register, control, handleSubmit, setValue, formState } = useForm({
    defaultValues: modalItem || { name: "", description: "", company: "" },
  });

  useEffect(() => {
    if (companyList.length === 1) {
      setValue("company", companyList[0].id);
    }
  }, [companyList, setValue]);

  const onCancel = () => {
    toggle();
  };

  return (
    <Modal isOpen={true} toggle={toggle}>
      <ModalHeader toggle={toggle} style={{ backgroundColor: "#d3d3d3" }}>
        {modalItem && modalItem.id ? modalItem.name : "New Group Name"}
      </ModalHeader>
      <form onSubmit={handleSubmit((data) => onSave(data))}>
        <input type="hidden" {...register("id")} />
        <ModalBody>
          <FormGroup>
            <Label for="name">
              <b>Group Name</b>
            </Label>
            <Controller
              name="name"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  invalid={Boolean(formState.errors.name)}
                  type="text"
                  placeholder="Group Name"
                />
              )}
            />
            {formState.errors.name && <span>This field is required</span>}
          </FormGroup>
          <FormGroup>
            <Label for="description">
              <b>Description</b>
            </Label>
            <Controller
              name="description"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  invalid={Boolean(formState.errors.description)}
                  type="textarea"
                  placeholder="Description"
                />
              )}
            />
            {formState.errors.description && (
              <span>This field is required</span>
            )}
          </FormGroup>
          <FormGroup>
            <Label for="company">
              <b>Company</b>
            </Label>
            <Controller
              name="company"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  type="select"
                  invalid={Boolean(formState.errors.company)}
                >
                  <option value="">Select Company</option>
                  {companyList.map((company) => (
                    <option key={company.id} value={company.id}>
                      {company.name}
                    </option>
                  ))}
                </Input>
              )}
            />
            {formState.errors.company && <span>This field is required</span>}
          </FormGroup>
        </ModalBody>

        <ModalFooter className="d-flex flex-row justify-content-between">
          <Button color="danger" onClick={onCancel} type="button">
            Cancel
          </Button>
          <Button color="success" type="submit">
            Save
          </Button>
          {modalItem && modalItem.id ? (
            <Button
              color="danger"
              onClick={() => {
                /* Add delete functionality */
              }}
            >
              Delete
            </Button>
          ) : null}
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default SiteGroupModal;
