import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";

const SuperUserModal = (props) => {
  const { toggle, onSave, onCancel } = props;

  const [modalItem] = useState({
    first_name: "",
    last_name: "",
    username: "",
    email: "",
    password: "",
    is_superuser: true, // Set default value to true for superuser field
    is_staff: true,
  });

  const { register, control, handleSubmit, formState } = useForm({
    defaultValues: modalItem,
  });

  return (
    <Modal isOpen={true} toggle={toggle}>
      <ModalHeader toggle={toggle} style={{ backgroundColor: "#d3d3d3" }}>
        {modalItem && modalItem.id ? modalItem.name : "New SuperUser"}
      </ModalHeader>
      <form onSubmit={handleSubmit((data) => onSave(data))}>
        <input type="hidden" {...register("id")} />
        <ModalBody>
          <FormGroup>
            <Label for="first_name">
              <b>First Name</b>
            </Label>
            <Controller
              name="first_name"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  invalid={Boolean(formState.errors.first_name)}
                  type="text"
                  placeholder="First Name"
                />
              )}
            />
            {formState.errors.first_name && <span>This field is required</span>}
          </FormGroup>

          <FormGroup>
            <Label for="last_name">
              <b>Last Name</b>
            </Label>
            <Controller
              name="last_name"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  invalid={Boolean(formState.errors.last_name)}
                  type="text"
                  placeholder="Last Name"
                />
              )}
            />
            {formState.errors.last_name && <span>This field is required</span>}
          </FormGroup>

          <FormGroup>
            <Label for="username">
              <b>Username</b>
            </Label>
            <Controller
              name="username"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  invalid={Boolean(formState.errors.username)}
                  type="text"
                  placeholder="Username"
                />
              )}
            />
            {formState.errors.username && <span>This field is required</span>}
          </FormGroup>

          <FormGroup>
            <Label for="email">
              <b>Email</b>
            </Label>
            <Controller
              name="email"
              control={control}
              rules={{
                required: "This field is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "Invalid email address",
                },
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  invalid={Boolean(formState.errors.email)}
                  type="text"
                  placeholder="Email"
                />
              )}
            />
            {formState.errors.email && (
              <span>{formState.errors.email.message}</span>
            )}
          </FormGroup>

          <FormGroup>
            <Label for="password">
              <b>Password</b>
            </Label>
            <Controller
              name="password"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  invalid={Boolean(formState.errors.password)}
                  type="password"
                  placeholder="Password"
                />
              )}
            />
            {formState.errors.password && <span>This field is required</span>}
          </FormGroup>

          {/* Do not include the is_superuser checkbox */}
        </ModalBody>
        <ModalFooter className="d-flex flex-row justify-content-between">
          <Button color="danger" onClick={onCancel}>
            Cancel
          </Button>
          <Button color="success" type="submit">
            Save
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default SuperUserModal;
