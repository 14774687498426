import React, { useState } from "react";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
} from "recharts";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import DownloadCSVButton from "./DownloadCSVButton.js";

//Converts a UTC timestamp (stored in database as check_in time) to a local timestamp
const UTCToLocalTimestamp = (utcTimestamp) => {
  const date = new Date(utcTimestamp);
  // Get the timezone offset in minutes and adjust the date to local time
  const timezoneOffsetInMinutes = date.getTimezoneOffset();
  date.setMinutes(date.getMinutes() - timezoneOffsetInMinutes);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

const SiteDiagnostics = ({ siteInfo, csvData, toggle }) => {
  const colors = {
    "Battery Voltage": "#1E1E1E",
    "Solar Voltage": "#006400",
    Temperature: "#483D8B",
    Humidity: "#8B4513",
    "Modem Current": "#2F4F4F",
    "Camera Current": "#8B008B",
    "Solar Current": "#000080",
    "Load Current": "#4B0082",
    "HDD Space": "#2F4F4F",
    "Battery Percentage": "#2F4F4F",
    "CPU Usage": "#2F4F4F",
    "RAM Usage": "#2F4F4F",
  };

  const [selectedGraphs, setSelectedGraphs] = useState(["Battery Percentage"]); // useState(Object.keys(colors));

  //
  // All these values correspond to incoming array
  // index. NOT CSV INDEX
  //
  const HDDSpace =
    csvData?.["status.csv"]?.rows?.map((row) => ({
      x: new Date(row[0]).getTime(),
      "HDD Space": parseFloat(row[11]),
    })) || [];

  const BatteryPercentage =
    csvData?.["status.csv"]?.rows?.map((row) => ({
      x: new Date(row[0]).getTime(),
      "Battery Percentage": parseFloat(row[12]),
    })) || [];

  const CPUUsage =
    csvData?.["status.csv"]?.rows?.map((row) => ({
      x: new Date(row[0]).getTime(),
      "CPU Usage": parseFloat(row[10]),
    })) || [];

  const RAMUsage =
    csvData?.["status.csv"]?.rows?.map((row) => ({
      x: new Date(row[0]).getTime(),
      "RAM Usage": parseFloat(row[9]),
    })) || [];

  const batteryVoltageData =
    csvData?.["status.csv"]?.rows?.map((row) => ({
      x: new Date(row[0]).getTime(),
      "Battery Voltage": parseFloat(row[1]),
    })) || [];

  const solarVoltageData =
    csvData?.["status.csv"]?.rows?.map((row) => ({
      x: new Date(row[0]).getTime(),
      "Solar Voltage": parseFloat(row[2]),
    })) || [];

  const temperatureData =
    csvData?.["status.csv"]?.rows?.map((row) => ({
      x: new Date(row[0]).getTime(),
      Temperature: parseFloat(row[3]),
    })) || [];

  const humidityData =
    csvData?.["status.csv"]?.rows?.map((row) => ({
      x: new Date(row[0]).getTime(),
      Humidity: parseFloat(row[4]),
    })) || [];

  const modemCurrentData =
    csvData?.["status.csv"]?.rows?.map((row) => ({
      x: new Date(row[0]).getTime(),
      "Modem Current": parseFloat(row[5]),
    })) || [];

  const cameraCurrentData =
    csvData?.["status.csv"]?.rows?.map((row) => ({
      x: new Date(row[0]).getTime(),
      "Camera Current": parseFloat(row[6]),
    })) || [];

  const solarCurrentData =
    csvData?.["status.csv"]?.rows?.map((row) => ({
      x: new Date(row[0]).getTime(),
      "Solar Current": parseFloat(row[7]),
    })) || [];

  const loadCurrentData =
    csvData?.["status.csv"]?.rows?.map((row) => ({
      x: new Date(row[0]).getTime(),
      "Load Current": parseFloat(row[8]),
    })) || [];

  const combinedData = [];

  const combineData = (newData, key) => {
    newData.forEach((entry) => {
      const existingEntry = combinedData.find((data) => data.x === entry.x);
      if (existingEntry) {
        existingEntry[key] = entry[key];
      } else {
        combinedData.push({ ...entry });
      }
    });
  };

  combineData(batteryVoltageData, "Battery Voltage");
  combineData(solarVoltageData, "Solar Voltage");
  combineData(humidityData, "Humidity");
  combineData(modemCurrentData, "Modem Current");
  combineData(cameraCurrentData, "Camera Current");
  combineData(solarCurrentData, "Solar Current");
  combineData(loadCurrentData, "Load Current");
  combineData(RAMUsage, "RAM Usage");
  combineData(CPUUsage, "CPU Usage");
  combineData(BatteryPercentage, "Battery Percentage");
  combineData(HDDSpace, "HDD Space");
  combineData(temperatureData, "Temperature");

  const isValid = (data) => Object.keys(data).length >= 1;
  const validData = combinedData.filter(isValid);
  validData.sort((a, b) => b.x - a.x);
  const last_recent_90_validData = validData.slice(0, 90);

  const tooltipStyle = {
    padding: "10px",
    backgroundColor: "white",
    border: "1px solid black",
    borderRadius: "4px",
    fontSize: "0.8em",
  };

  const metricUnits = {
    "Battery Voltage": "V",
    "Solar Voltage": "V",
    Temperature: "°F",
    Humidity: "%",
    "Modem Current": "A",
    "Camera Current": "A",
    "Solar Current": "A",
    "Load Current": "A",
    "Battery Percentage": "%",
    "RAM Usage": "Mb",
    "CPU Usage": "%",
    "HDD Space": "Gb",
  };
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip" style={tooltipStyle}>
          <p key={"x"} className="label">
            {`${"Date"}: ${
              UTCToLocalTimestamp(payload[0].payload["x"]) || "NaN"
            } ${"s"}`}
          </p>
          {selectedGraphs.map((key) => (
            <p key={key} className="label">
              {`${key}: ${payload[0].payload[key] ?? "NaN"} ${
                metricUnits[key] || ""
              }`}
            </p>
          ))}
        </div>
      );
    }
    return null;
  };

  const renderDot = (props) => (
    <circle cx={props.cx} cy={props.cy} r={3} fill={props.stroke} />
  );

  return (
    <Modal isOpen={true} toggle={toggle} style={{ maxWidth: "80%" }}>
      <ModalHeader toggle={toggle} style={{ backgroundColor: "#d3d3d3" }}>
        Site Diagnostics
      </ModalHeader>
      <ModalBody>
        {last_recent_90_validData.length ? (
          <>
            <div style={{ display: "flex" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginRight: 5,
                }}
              >
                {Object.keys(colors).map((key) => (
                  <div
                    key={key}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: 5,
                    }}
                  >
                    <span
                      style={{
                        display: "inline-block",
                        width: 10,
                        height: 10,
                        backgroundColor: colors[key],
                        marginRight: 5,
                      }}
                    ></span>
                    <input
                      type="checkbox"
                      id={`checkbox-${key}`}
                      checked={selectedGraphs.includes(key)}
                      onChange={() => {
                        if (selectedGraphs.includes(key)) {
                          setSelectedGraphs((prev) =>
                            prev.filter((k) => k !== key)
                          );
                        } else {
                          setSelectedGraphs((prev) => [...prev, key]);
                        }
                      }}
                    />
                    <label
                      htmlFor={`checkbox-${key}`}
                      style={{ marginLeft: 5 }}
                    >
                      {key}
                    </label>
                  </div>
                ))}
                <div>
                  <DownloadCSVButton
                    endpointId={siteInfo.latest_endpoint.id}
                    style={{
                      fontSize: "0.8rem",
                      backgroundColor: "#0d6efd",
                      color: "white",
                    }}
                  />
                </div>
              </div>

              <ResponsiveContainer width="100%" height={400}>
                <LineChart data={last_recent_90_validData}>
                  <CartesianGrid stroke="#ccc" />
                  <XAxis
                    dataKey="x"
                    type="number"
                    domain={["auto", "auto"]}
                    tickFormatter={(tickItem) =>
                      new Date(tickItem).toLocaleDateString()
                    }
                  />
                  <YAxis hide={true} />
                  <Tooltip
                    content={<CustomTooltip />}
                    cursor={{ stroke: "red" }}
                  />
                  {selectedGraphs.includes("Battery Voltage") && (
                    <Line
                      dataKey="Battery Voltage"
                      stroke={colors["Battery Voltage"]}
                      strokeDasharray="3 3"
                      dot={renderDot}
                    />
                  )}
                  {selectedGraphs.includes("Solar Voltage") && (
                    <Line
                      dataKey="Solar Voltage"
                      stroke={colors["Solar Voltage"]}
                      strokeDasharray="3 3"
                      dot={renderDot}
                    />
                  )}
                  {selectedGraphs.includes("Temperature") && (
                    <Line
                      dataKey="Temperature"
                      stroke={colors["Temperature"]}
                      strokeDasharray="3 3"
                      dot={renderDot}
                    />
                  )}
                  {selectedGraphs.includes("Humidity") && (
                    <Line
                      dataKey="Humidity"
                      stroke={colors["Humidity"]}
                      strokeDasharray="3 3"
                      dot={renderDot}
                    />
                  )}
                  {selectedGraphs.includes("Modem Current") && (
                    <Line
                      dataKey="Modem Current"
                      stroke={colors["Modem Current"]}
                      strokeDasharray="3 3"
                      dot={renderDot}
                    />
                  )}
                  {selectedGraphs.includes("Camera Current") && (
                    <Line
                      dataKey="Camera Current"
                      stroke={colors["Camera Current"]}
                      strokeDasharray="3 3"
                      dot={renderDot}
                    />
                  )}
                  {selectedGraphs.includes("Solar Current") && (
                    <Line
                      dataKey="Solar Current"
                      stroke={colors["Solar Current"]}
                      strokeDasharray="3 3"
                      dot={renderDot}
                    />
                  )}
                  {selectedGraphs.includes("Load Current") && (
                    <Line
                      dataKey="Load Current"
                      stroke={colors["Load Current"]}
                      strokeDasharray="3 3"
                      dot={renderDot}
                    />
                  )}
                  {selectedGraphs.includes("HDD Space") && (
                    <Line
                      dataKey="HDD Space"
                      stroke={colors["HDD Space"]}
                      strokeDasharray="3 3"
                      dot={renderDot}
                    />
                  )}
                  {selectedGraphs.includes("Battery Percentage") && (
                    <Line
                      dataKey="Battery Percentage"
                      stroke={colors["Battery Percentage"]}
                      strokeDasharray="3 3"
                      dot={renderDot}
                    />
                  )}
                  {selectedGraphs.includes("CPU Usage") && (
                    <Line
                      dataKey="CPU Usage"
                      stroke={colors["CPU Usage"]}
                      strokeDasharray="3 3"
                      dot={renderDot}
                    />
                  )}
                  {selectedGraphs.includes("RAM Usage") && (
                    <Line
                      dataKey="RAM Usage"
                      stroke={colors["RAM Usage"]}
                      strokeDasharray="3 3"
                      dot={renderDot}
                    />
                  )}
                </LineChart>
              </ResponsiveContainer>
            </div>
          </>
        ) : (
          <div>No diagnostic data available.</div>
        )}
      </ModalBody>
    </Modal>
  );
};

export default SiteDiagnostics;
