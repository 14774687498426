import React, { useContext } from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import $ from "jquery";

import { ManagementContext } from "./Contexts/ManagementContext";
import SuperUserModal from "./Users/SuperUserModal.js";
import { getCookie } from "./Utilities/CookieUtils.js";
import { useToastContext } from "./Contexts/ToastContext.js";
import { ModalContext } from "./Contexts/ModalContext";

const UserMenu = ({
  logoutHandler,
  isCompanyAdmin,
}) => {
  const { user } = useContext(ManagementContext);
  const { modal, toggleModal, openModal, modalComponent, handleCancel } =
    useContext(ModalContext);
  const { showToast } = useToastContext();

  const handleSuperUserSubmit = (item) => {
    let url = "/redeye/api/user/";
    let type = "POST";
    $.ajax({
      type: type,
      url: url,
      data: item,
      headers: { "X-CSRFToken": getCookie("redeyeCSRFcookie") },
      xhrFields: { withCredentials: true },
      dataType: "json",
      success: function (data) {
        showToast("Success Created Super User ", data.username);
        toggleModal();
      },
      error: function (error) {
        showToast(error);
      },
    });
  };

  return (
    <>
      <div className="text-right align-items-center d-flex ml-auto">
        <div className="group relative dropdown text-white text-xl px-4 cursor-pointer font-bold capitalize">
          {user?.username}
          {user?.is_superuser && (
            <span className="text-sm pl-2 text-gray-400">Admin</span>
          )}
          <div className="group-hover:block absolute w-[400px] h-auto bg-white py-1 top-[27px] right-[10px] rounded-sm shadow-lg z-10 hidden">
            <Link
              to="/"
              className="block w-full text-left no-underline py-2 pl-4 hover:no-underline hover:bg-slate-200"
            >
              Home
            </Link>
            <div className="w-full bg-slate-100 h-[2px]"></div>

            {user?.is_superuser && (
              <Link
                to="/admin/"
                className="block w-full text-left no-underline py-2 pl-4 hover:no-underline hover:bg-slate-200"
              >
                View Database
              </Link>
            )}
            {user?.is_superuser && (
              <Link
                to="#"
                className="block w-full text-left no-underline py-2 pl-4 hover:no-underline hover:bg-slate-200"
                onClick={() => openModal("SuperUserModal")}
              >
                Create Super User
              </Link>
            )}
            <div className="w-full bg-slate-100 h-[2px]"></div>
            {user?.is_superuser && (
              <Link
                to="/companies"
                className="block w-full text-left no-underline py-2 pl-4 hover:no-underline hover:bg-slate-200"
              >
                Manage Companies
              </Link>
            )}
            {isCompanyAdmin && (
              <Link
                to="/sites?tab=siteGroups"
                className="block w-full text-left no-underline py-2 pl-4 hover:no-underline hover:bg-slate-200"
              >
                Manage Site Groups
              </Link>
            )}
            {isCompanyAdmin && (
              <Link
                to="/users"
                className="block w-full text-left no-underline py-2 pl-4 hover:no-underline hover:bg-slate-200"
              >
                Manage Users
              </Link>
            )}
            <div className="w-full bg-slate-100 h-[2px]"></div>
            <Link
              onClick={logoutHandler}
              to="#"
              className="block w-full text-left no-underline py-2 pl-4 hover:no-underline hover:bg-slate-200"
            >
              Log out
            </Link>
          </div>
        </div>
      </div>
      {modal && modalComponent === "SuperUserModal" ? (
        <SuperUserModal
          toggle={toggleModal}
          onSave={handleSuperUserSubmit}
          onCancel={handleCancel}
        />
      ) : null}
    </>
  );
};

export default UserMenu;
