import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";

const stickyTableHeader = {
  position: "sticky",
  top: "0",
};
const tableHeaderStyle = {
  backgroundColor: "lightblue",
  color: "black",
  fontSize: "1.1rem",
  padding: "0 1rem",
  height: "40px",
  borderTop: "2px solid black",
  borderBottom: "3px solid black",
  width: "71vh",
};

const StyledTableHeader = ({ columns, setSort, sort }) => {
  const handleSort = (property) => {
    if (sort.property === property) {
      setSort({
        ...sort,
        direction: sort.direction === "asc" ? "desc" : "asc",
      });
    } else {
      setSort({ property, direction: "asc" });
    }
  };

  return (
    <thead className="bg-white" style={stickyTableHeader}>
      <tr>
        {columns.map((column) => (
          <th
            style={tableHeaderStyle}
            key={column.key}
            onClick={() => handleSort(column.key)}
          >
            {column.header}
            {sort.property === column.key && (
              <span>
                {sort.direction === "asc" ? (
                  <FontAwesomeIcon icon={faSortUp} />
                ) : (
                  <FontAwesomeIcon icon={faSortDown} />
                )}
              </span>
            )}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default StyledTableHeader;
