import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselCaption,
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { differenceInMinutes, parseISO } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

const stickyModalHeader = {
  position: "sticky",
  top: "0",
  background: "inherit",
  zIndex: "1055",
};

const SiteImages = (props) => {
  const { siteInfo, toggle } = props;

  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  let modalStyle = { maxHeight: "80vh" };

  const imgStyle = {
    maxWidth: "100%",
    maxHeight: "80%",
  };

  const carouselStyle = {
    alignItems: "center",
  };

  const thumnailFooterStyle = {
    maxHeight: "10vh",
  };

  const thumbnailStyle = {
    maxWidth: "18%",
    maxHeight: "100%",
  };

  const highlightedThumbnailStyle = {
    maxWidth: "18%",
    filter: "brightness(50%)",
    maxHeight: "100%",
  };

  const onExiting = () => {
    setAnimating(true);
  };

  const onExited = () => {
    setAnimating(false);
  };

  const next = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === siteInfo.image_set.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === 0 ? siteInfo.image_set.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const findClosest = (date) => {
    const image_list = [...siteInfo.image_set];
    const result = image_list.sort((a, b) => {
      const diffA = Math.abs(differenceInMinutes(parseISO(a.created_on), date));
      const diffB = Math.abs(differenceInMinutes(parseISO(b.created_on), date));
      return diffA - diffB; // sort a before b when the difference to date is smaller
    })[0];
    const index = siteInfo.image_set.findIndex((obj) => obj === result);
    goToIndex(index);
  };

  const downloadImage = () => {
    const url = siteInfo.image_set[activeIndex].image_url;
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      siteInfo.image_set[activeIndex].image_path
        .split("\\")
        .pop()
        .split("/")
        .pop(), // Get filename from full path
    );
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  const DatePickerInput = ({ onClick }) => (
    <Button color="primary" onClick={onClick}>
      Go to date
    </Button>
  );

  return (
    <Modal isOpen={true} toggle={toggle} style={modalStyle}>
      {siteInfo ? (
        <>
          <ModalHeader
            toggle={toggle}
            style={stickyModalHeader}
            cssModule={{ "modal-title": "w-75 mb-0 text-center" }}
          >
            <div className="d-flex flex-row justify-content-between align-items-center">
              {siteInfo.name}
              {siteInfo.image_set && siteInfo.image_set.length > 0 ? (
                <>
                  <DatePicker
                    onChange={(date) => findClosest(date)}
                    customInput={<DatePickerInput />}
                  />
                  <FontAwesomeIcon
                    icon={faDownload}
                    onClick={() => downloadImage()}
                  />
                </>
              ) : null}
            </div>
          </ModalHeader>
          <ModalBody>
            <div className="d-flex flex-row justify-content-center">
              {siteInfo.image_set && siteInfo.image_set.length > 0 ? (
                <Carousel
                  activeIndex={activeIndex}
                  next={next}
                  previous={previous}
                  interval={null}
                  style={carouselStyle}
                >
                  {siteInfo.image_set.map((item) => (
                    <CarouselItem
                      onExiting={onExiting}
                      onExited={onExited}
                      key={item.id}
                    >
                      <img
                        data-testid="site-carousel-image"
                        src={item.image_url}
                        alt={item.image_name}
                        style={imgStyle}
                      />
                      <CarouselCaption captionText={item.created_on} />
                    </CarouselItem>
                  ))}
                  <CarouselControl
                    direction="prev"
                    directionText="Previous"
                    onClickHandler={previous}
                  />
                  <CarouselControl
                    direction="next"
                    directionText="Next"
                    onClickHandler={next}
                  />
                </Carousel>
              ) : (
                <span>No images uploaded by endpoint</span>
              )}
            </div>
          </ModalBody>
          {siteInfo.image_set && siteInfo.image_set.length > 0 ? (
            <ModalFooter>
              <div
                className="d-flex flex-row justify-content-between overflow-auto"
                style={thumnailFooterStyle}
              >
                {siteInfo.image_set.map((item, index) => (
                  <img
                    data-testid="site-carousel-thumbnail"
                    key={item.id}
                    src={item.image_url}
                    alt={item.image_name}
                    style={
                      index === activeIndex
                        ? highlightedThumbnailStyle
                        : thumbnailStyle
                    }
                    className="img-thumbnail"
                    onClick={() => goToIndex(index)}
                  />
                ))}
              </div>
            </ModalFooter>
          ) : null}
        </>
      ) : null}
    </Modal>
  );
};

export default SiteImages;
