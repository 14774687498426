import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useMemo,
} from "react";
import { Default } from "../../MediaQueries.js";
import { ModalContext } from "../Contexts/ModalContext";
import { SiteContext } from "../Contexts/SiteContext";
import { useParams } from "react-router";
import { Form, FormGroup, Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";
import { SiteGroupContext } from "../Contexts/SiteGroupContext";
import SiteDetailsManagement from "./SiteDetailsManagement.js";
import SiteModal from "./SiteModal.js";
import SiteStatusCircle from "./SiteStatusCircle.js";

const SiteManagement = (props) => {
  const { user } = props;
  const { modal, modalComponent, modalItem, toggleModal, openModal } =
    useContext(ModalContext);
  const {
    siteList,
    refreshList,
    siteInfo,
    refreshMaintenanceAccessibility,
    siteMaintenanceAccessibility,
    refreshSiteInfo,
    handleSiteDelete,
    handleSiteSubmit,
    handleSiteEdit,
    imageRotation,
    siteLoading,
  } = useContext(SiteContext);
  const { siteGroupList, refreshGroupList } = useContext(SiteGroupContext);
  const [sort, setSort] = useState({ property: "", direction: "asc" });
  const [sortIndicator, setSortIndicator] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedSearchField, setSelectedSearchField] = useState("name");
  const [selectedSiteId, setSelectedSiteId] = useState(null);
  const [adminUser, setAdminUser] = useState(false);

  const onSiteModalSave = (item) => {
    toggleModal();
    handleSiteSubmit(item);
  };
  const onSiteModalDelete = (item) => {
    toggleModal();
    handleSiteDelete(item);
  };
  const handleCancel = (item) => {
    toggleModal();
    item.preventDefault();
  };
  const params = useParams();

  useEffect(() => {
    document.title = "Logical Endpoints";
    refreshList();
    refreshMaintenanceAccessibility(true);
    refreshGroupList();
  }, [refreshList]);

  useEffect(() => {
    // Use current URL to open selected site
    const siteId = params?.id;
    refreshSiteInfo(siteId);
    setSelectedSiteId(siteId);
    handleSort("name"); // set a default sort
  }, []);

  const handleTableRowClick = useCallback(
    (item) => {
      // update URL
      window.history.replaceState(
        window.history.state,
        "",
        `/sites/id/${item.id}`
      );

      // Fetch site info
      refreshSiteInfo(item.id);

      const matchedSite = siteList?.find((site) => site.id === item.id);
      const isAdminUser = siteGroupList?.some(
        (siteGroup) => siteGroup.id === matchedSite?.group
      );
      setAdminUser(isAdminUser);
      setSelectedSiteId(item.id);
    },
    [siteList, refreshSiteInfo]
  );

  const filteredSiteList = useMemo(() => {
    {
      /*Search all available fields, Filters row's if atleast one of the field matches*/
    }
    const fieldsToSearch = ["name", "group_name", "endpoint_status.status"];
    const cleanTerm = searchTerm.toLowerCase();
    return siteList
      .filter((site) => {
        return fieldsToSearch.some((fieldName) => {
          let fieldValue;
          if (
            site.endpoint_status.status !== "running" &&
            site.endpoint_status.status !== "No Endpoint" &&
            String(siteMaintenanceAccessibility[site.id]).length > 10
          ) {
            site.endpoint_status.status = "Ready!";
          }
          if (fieldName.includes(".")) {
            const fieldParts = fieldName.split(".");
            fieldValue = fieldParts.reduce(
              (obj, key) => (obj && obj[key] !== undefined ? obj[key] : null),
              site
            );
          } else {
            fieldValue = site[fieldName];
          }
          return String(fieldValue).toLocaleLowerCase().includes(cleanTerm);
        });
      })
      .sort((a, b) => {
        {
          /*keep track of names to use as second params if first params got equals*/
        }
        const valueC = String(a["name"]).toLowerCase();
        const valueD = String(b["name"]).toLowerCase();
        {
          /*keeping track of param by converting them to String with lower case*/
        }
        var valueA, valueB;
        if (sort.property != "endpoint_status.status") {
          valueA = String(a[sort.property]).toLowerCase();
          valueB = String(b[sort.property]).toLowerCase();
        } else {
          valueA = String(a.endpoint_status.status).toLowerCase();
          valueB = String(b.endpoint_status.status).toLowerCase();
        }
        {
          /*if both the first params are null, then it compares second params(names)*/
        }
        if (valueA == "null" && valueB == "null") {
          if (valueC < valueD) return -1;
          if (valueD < valueC) return 1;
          return 0;
        }
        {
          /*if one of the param is null, then push the site with null down the order*/
        }
        if (valueA == "null") return 1;
        if (valueB == "null") return -1;
        {
          /*if params are not equal sort according to direction*/
        }
        if (sort.direction == "asc") {
          if (valueA < valueB) return -1;
          if (valueA > valueB) return 1;
        } else {
          if (valueA > valueB) return -1;
          if (valueA < valueB) return 1;
        }
        {
          /*if params got equal sort according to second param*/
        }
        if (valueC < valueD) return -1;
        if (valueD < valueC) return 1;
        return 0;
      });
  }, [siteList, searchTerm, selectedSearchField, sort, selectedSiteId]);

  const handleSort = (property) => {
    if (sort.property === property) {
      setSort({
        ...sort,
        direction: sort.direction === "asc" ? "desc" : "asc",
      });
    } else {
      setSort({ property, direction: "asc" });
    }
    setSortIndicator(property);
  };

  const sortToUse = sort.direction === "asc" ? faSortUp : faSortDown;

  const sortIcon = (propName, fieldName) => {
    return (
      <th
        onClick={() => handleSort(propName)}
        className="cursor-pointer hover:bg-blue-300"
      >
        {fieldName}
        {sortIndicator === propName && (
          <FontAwesomeIcon className="px-2" icon={sortToUse} />
        )}
      </th>
    );
  };

  const tableBodyWrapperStyle = {
    maxHeight: "calc(100vh - 333px)",
    overflowY: "scroll",
    scrollbarWidth: "thin",
    scrollbarColor: "#5683af #f5f5f5",
  };
  return (
    <main>
      <Default>
        <div className="container-fluid">
          <div className="row">
            <div>
              {siteList ? (
                <div className="row pt-3">
                  <div className="col-6">
                    <Form>
                      <FormGroup
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Input
                          type="text"
                          name="searchTerm"
                          id="searchTerm"
                          placeholder="Search by keyword"
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                          style={{ backgroundColor: "#f2f2f2" }}
                        />
                      </FormGroup>
                    </Form>

                    <div className="col-20 inline-block w-full">
                      {filteredSiteList.length ? (
                        <div className="flex flex-col">
                          <div style={{ ...tableBodyWrapperStyle }}>
                            <table className="table">
                              <thead className="bg-blue-200 sticky z-10 top-0">
                                <tr className="bg-blue-200">
                                  {sortIcon("name", "Name")}
                                  {sortIcon("group_name", "Group")}
                                  {sortIcon("endpoint_status.status", "Status")}
                                </tr>
                              </thead>
                              <tbody>
                                {filteredSiteList.map((item) => {
                                  if (!item.archived)
                                    return (
                                      <tr
                                        key={item.id}
                                        onClick={() => {
                                          handleTableRowClick(item);
                                        }}
                                        className="box"
                                        role="button"
                                        style={
                                          item.id == selectedSiteId
                                            ? {
                                                backgroundColor: "#6c757d",
                                                color: "white",
                                              }
                                            : {}
                                        }
                                      >
                                        <th>{item.name}</th>
                                        <td>{item.group_name}</td>
                                        <td>
                                          <SiteStatusCircle
                                            site={item}
                                            siteMaintenanceAccessibility={
                                              siteMaintenanceAccessibility
                                            }
                                          />
                                        </td>
                                      </tr>
                                    );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      ) : (
                        <p>Site Not found!</p>
                      )}
                    </div>
                  </div>

                  <SiteDetailsManagement
                    adminUser={adminUser}
                    siteInfo={siteInfo}
                    siteList={siteList}
                    siteMaintenanceAccessibility={siteMaintenanceAccessibility}
                    refreshSiteInfo={refreshSiteInfo}
                    handleSiteDelete={handleSiteDelete}
                    handleSiteSubmit={handleSiteSubmit}
                    handleSiteEdit={handleSiteEdit}
                    imageRotation={imageRotation}
                    siteLoading={siteLoading}
                    user={user}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
        {(siteGroupList.length > 0 || user?.is_superuser) && (
          <button
            className="btn btn-primary absolute bottom-[20px] left-[20px] shadow-lg"
            onClick={() => openModal("SiteModal")}
          >
            Create Site
          </button>
        )}
        {modal && modalComponent === "SiteModal" ? (
          <SiteModal
            initialItem={modalItem}
            toggle={toggleModal}
            onSave={onSiteModalSave}
            onDelete={onSiteModalDelete}
            onCancel={handleCancel}
          />
        ) : null}
      </Default>
    </main>
  );
};

export default SiteManagement;
